import React, { useRef } from 'react';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

import { UIIconButton } from 'finbox-ui-kit';
import { generatePassword } from '@/utils/generate-password';

import './generate-password.style.scss';


export const GeneratePassword = ({ onGenerate }) => {
    const inputRef = useRef<HTMLInputElement>();
    const [ password, setPassword ] = React.useState('');

    const handlerClick = (e) => {
        e.preventDefault();
        const generatedPassword = generatePassword();
        setPassword(generatedPassword);
        onGenerate(generatedPassword);
    };

    const handlerFocusInput = (e) => {
        inputRef.current?.setSelectionRange(0, e.target.value.length);
    };

    const handlerClickCopy = () => {
        copy(password);
        toast.info('Пароль скопирован');
    };

    return (
        <div className='generate-password'>
            <a onClick={handlerClick}>Сгенерировать пароль</a> <br/>
            {password && (
                <>
                    <input ref={inputRef} type='text' value={password} onFocus={handlerFocusInput} readOnly/>
                    <UIIconButton
                        icon='copy'
                        onClick={handlerClickCopy}
                    />
                </>
            )}
        </div>
    );
};
