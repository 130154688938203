import React, { ReactNode, useCallback } from 'react';
import { useEventListener } from 'finbox-ui-kit/utils/hooks/use-event-listener';
import { uid } from '../utils/uid';

type TFUIModalsContext = {
    register: (modalId: string, closeHandler: () => void, hideScroll: boolean) => number
    unregister: (modalId: string) => void
    getModalId: () => string
};


const FUIModalsContext = React.createContext<TFUIModalsContext>({
    getModalId: () => null,
    register: () => null,
    unregister: () => null,
});

export function useFUIModalsContext() {
    return React.useContext(FUIModalsContext);
}


const modals = new Set();
const modalsProps = {};

type TFUIModalsProps = {
    children?: ReactNode
};
export const FUIModalsProvider = ({ children }: TFUIModalsProps) => {
    const handlerKeydown = useCallback((e) => {
        if (e.key === 'Escape') {
            if (modals.size) {
                const upperWindowID = Array.from(modals).pop() as string;
                modalsProps[upperWindowID]();
            }
        }
    }, []);

    useEventListener('keydown', handlerKeydown);

    const register = useCallback(function register(modalId: string, modalProps: () => void, hideScroll: boolean) {
        if (hideScroll && !modals.size) {
            document.body.style.overflow = 'hidden';
        }
        modals.add(modalId);
        modalsProps[modalId] = modalProps;
        return modals.size;
    }, []);

    const unregister = useCallback(function unregister(modalId: string) {
        modals.delete(modalId);
        delete modalsProps[modalId];
        if (!modals.size) {
            document.body.style.overflow = null;
        }
    }, []);

    const getModalId = useCallback(function getModalId() {
        return uid();
    }, []);

    return (
        <FUIModalsContext.Provider value={ {
            getModalId,
            register,
            unregister,
        } }>
            { children }
        </FUIModalsContext.Provider>
    );
};
