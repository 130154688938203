import React from 'react';
import styles from './page-not-found.module.scss';

export default function PageNotFound() {
    return (
        <div className={styles.pageNotFound}>
            <div className='text-center mb1 pl3 pr3'>
                <div className='color-red' style={ { fontSize: 80, lineHeight: '1' } }>404</div>
                страница не найдена
            </div>
        </div>
    );
}