import { useCallback, useEffect, useState, experimental_useEffectEvent as useEffectEvent, ReactNode, useRef } from 'react';
import { toast } from 'react-toastify';
import axios, { AxiosRequestConfig } from 'axios';
import { axiosPrivate } from '@/libs/api-client/axiosPrivate';
import { axiosPublic } from '@/libs/api-client/axiosPublic';
import { AuthFailedException } from '@/libs/api-client/auth-failed.exception';
import { THttpClientMethod, TRequestAttachment } from '@/libs/api-client/types';


type useApiClientProps = AxiosRequestConfig & {
    method?: THttpClientMethod;
    url?: string;
    successMessage?: ReactNode;
    initialFetch?: boolean;
    initialData?: any;
    showErrorToast?: boolean;
    useToken?: boolean;
    attachments?: TRequestAttachment[];
}

type useApiClientReturn<TData = any> = {
    fetch: (data?: any, options?: Partial<useApiClientProps>) => Promise<TData>;
    loading: boolean;
    data: TData | null;
    abort: () => void;
    reset: () => void;
    isFetched: boolean;
}

export function useApiClient<TData = any>({
    url,
    method,
    successMessage,
    initialFetch,
    initialData = null,
    showErrorToast = true,
    useToken = true,
}: useApiClientProps = {}): useApiClientReturn<TData> {
    const ref = useRef({
        abortController: null,
    });
    const [ loading, setLoading ] = useState(false);
    const [ isFetched, setIsFetched ] = useState(false);
    const [ data, setData ] = useState<TData>(initialData);

    useEffect(() => () => {
        ref.current.abortController && ref.current.abortController();
    }, []);

    const fetch = useCallback(async (data?: any, options?: Partial<useApiClientProps>) => {
        setLoading(true);
        const client = useToken ? axiosPrivate : axiosPublic;
        const _method = options?.method || method || 'get';
        let _requestData = data;
        let _isFormData = false;
        if (options?.attachments && options.attachments.length) {
            _isFormData = true;
            const formData = new FormData();
            if (data) {
                formData.append("data", JSON.stringify(data));
            }
            if (options.attachments && options.attachments.length > 0) {
                for (const attachment of options.attachments) {
                    const uint8array = new TextEncoder().encode(attachment[1].name);
                    const str = new TextDecoder('utf8').decode(uint8array);
                    const blob = attachment[1].slice(0, attachment[1].size, attachment[1].type);
                    const f = new File([ blob ], encodeURIComponent(str));
                    formData.append(attachment[0], f);
                }
            }
            _requestData = formData;
        }

        try {
            const response = await client.request({
                headers: {
                    ...(options?.headers && {}),
                    Accept: 'application/json',
                    ..._isFormData
                        ? { 'Content-Type': 'multipart/form-data; charset=utf-8' }
                        : { 'Content-Type': 'application/json' },
                },
                method: _method,
                url,
                ...(data && [ 'post', 'put', 'patch' ].includes(_method)) && { data: _requestData },
                ...(data && [ 'get', 'delete', undefined ].includes(_method)) && { params: data },
                ...options && options,
                cancelToken: new axios.CancelToken(function executor(canceler) {
                    // Исполнительная функция (или executor function) получает функцию отмены в качестве параметра
                    ref.current.abortController = canceler;
                }),
            });

            setData(response?.data);
            if (successMessage) {
                toast.success(successMessage);
            }
            setIsFetched(true);
            return response?.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
                return;
            }
            if (showErrorToast && !(error instanceof AuthFailedException)) {
                toast.error(error.message);
            }
            throw error;
        } finally {
            setLoading(false);
        }
    }, [ method, showErrorToast, successMessage, url, useToken ]);

    const initialFetchEvent = useEffectEvent(() => {
        fetch(initialData);
    });

    useEffect(() => {
        if (initialFetch) {
            initialFetchEvent();
        }
    }, [ fetch, initialFetch ]);

    const abort = useCallback(() => {
        ref.current.abortController && ref.current.abortController();
    }, []);

    const reset = useCallback(() => {
        setData(initialData);
    }, [ initialData ]);

    return {
        fetch,
        loading,
        data,
        abort,
        reset,
        isFetched,
    };
}