import yup from '@/utils/yup-config';
import { MProofOfIncome, MProofOfJob } from '@/components/leads/components/credit-from/consts';

export const financesScheme = yup.object({
    wage: yup.number().required(),
    primeTimeJobWage: yup.number(),
    otherIncome: yup.number(),
    averageMonthlyIncome: yup.number().required(),
    proofOfIncome: yup.string().oneOf(MProofOfIncome).required(),
    proofOfJob: yup.string().oneOf(MProofOfJob).required(),
});