import { TInitials } from 'finbox-ui-kit/types';

export const formatInitials = (initials: TInitials, short = false): string | null => !initials ? null : [
    initials.surname,
    initials.name,
    !short ? initials.patronymic : null,
].filter((i) => i).join(' ');


export const formatInitialsShort = (initials: TInitials): string | null => !initials ? null : [
    initials.surname,
    initials.name ? `${initials.name[0]}.` : null,
    initials.patronymic ? `${initials.patronymic[0]}.` : null,
].filter((i) => i).join(' ');
