import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import InputMask from 'react-input-mask';

import { UITooltip } from '../tooltip';
import { TUIInputProps } from '../input/input';
import { UIInfoIcon } from '../info-icon';
import { getRefTarget } from '../../utils/ref-getter';
import { UIIcon } from '../icon';
import { extractString, MakeUISyntheticChangeEvent, refGetter } from '../../utils';
import { useDebounce } from '../../utils/hooks';
import { UILoader } from '../loader';
import './ui-input.style.scss';


export const UIInput: React.FC<TUIInputProps> = React.forwardRef<HTMLInputElement, TUIInputProps>(function UIInput(
    {
        type = 'text',
        id,
        name,
        value,
        mask,
        error,
        placeholder,
        inputMode,
        label,

        disabled,
        required,
        autoComplete,
        autoFocus,
        readOnly,
        clearable,
        loading,
        size,
        icon,
        className,
        noBorder,
        noMargin,
        postfix,
        renderValueModifier,
        float,
        rightIcon,

        onChange,
        onBlur,
        onKeyDown,
        onPaste,
        onFocus,
        onClick,
        onMouseDown,

        suggestionsAddon,
        inputElement,
        disablePlaceholder,
        info,
    },
    ref,
) {
    const inputRef = useRef();

    const { debounce } = useDebounce({ timeout: 100 });
    const [ isFocused, setIsFocused ] = useState(false);

    const handlerOnFocus = useCallback((e: any) => {
        if (!readOnly) {
            setIsFocused(true);
        }
        if (onFocus) {
            onFocus(e, {
                name,
                value: e.target.value,
            });
        }
    }, [ readOnly, name, onFocus ]);

    const handlerBlur = useCallback((e: any) => {
        debounce(() => {
            setIsFocused(false);
        });
        if (onBlur) {
            onBlur(e, {
                name,
                value: e.target.value,
            });
        }
    }, [ debounce, name, onBlur ]);

    const handlerChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (type === 'number') {
            const digitsRegex = float ? /^([0-9]+)[.,]?([0-9]+)?$/ : /^[0-9]+$/;
            const value: any = e.target.value?.match(/[0-9.,]/g)?.join('')?.replace(',', '.');

            if (e.target.value && !digitsRegex.test(value)) {
                return;
            }
            let _value;
            if (!float) {
                _value = value ? parseInt(value, 10) : null;
            } else {
                _value = value;
            }
            onChange(
                MakeUISyntheticChangeEvent(getRefTarget(inputRef, ref), _value),
                {
                    name,
                    value: _value,
                },
            );
            return;
        }
        onChange(
            e,
            {
                name,
                value: e.target.value,
            },
        );
    }, [ type, onChange, name, float, ref ]);

    useEffect(() => {
        if (disabled) {
            setIsFocused(false);
        }
    }, [ disabled ]);

    const handlerClearClick = useCallback(() => {
        let _value = '';
        setIsFocused(false);
        if (type === 'number') {
            _value = null;
        }
        onChange(
            MakeUISyntheticChangeEvent(inputRef.current, _value),
            {
                name,
                value: _value,
            },
        );
    }, [ name, onChange, type ]);

    const isEmpty = value === undefined || value === null || value === '';

    const renderValue = renderValueModifier && !isEmpty ? renderValueModifier(value) : value;

    const inputProps = {
        ref: refGetter(inputRef, ref),
        className: 'FUI-input-input',
        type: type === 'number' ? 'text' : type,
        id: id || name,
        name,
        value: isEmpty ? '' : renderValue,
        onChange: handlerChange,
        onFocus: handlerOnFocus,
        onBlur: handlerBlur,
        onKeyDown,
        onPaste,
        onClick,
        onMouseDown,
        placeholder: (isFocused || !label) ? placeholder : null,
        autoComplete,
        required,
        disabled,
        inputMode,
        readOnly,
        autoFocus,
    };

    const InputComponent = inputElement ? inputElement(inputProps) : (
        <input { ...inputProps } />
    );

    return (
        <div
            className={ cn('FUI-input', size, className, {
                '-focused': isFocused,
                '-error': error,
                '-disabled': disabled,
                '-no-border': noBorder,
                '-no-margin': noMargin,
                '-no-label': !label,
                '-not-empty': !isEmpty,
                '-without-label': !label,
                '-icon': icon,
            }) }
            title={ extractString(label) }
        >
            <div className='FUI-input-wrapper'>
                { icon && (
                    <UIIcon
                        className='FUI-input-icon'
                        name={ icon }
                    />
                ) }
                { loading && (
                    <UILoader
                        className='FUI-input-loader'
                        size='tiny'
                        inverted
                    />
                ) }
                { !mask && (InputComponent) }
                { mask && (
                    <InputMask
                        className='FUI-input-input'
                        id={ id || name }
                        type={ type === 'number' ? 'text' : type }
                        name={ name }
                        mask={ mask }
                        // @ts-ignore
                        maskChar={ null }
                        value={ isEmpty ? '' : renderValue }
                        onChange={ handlerChange }
                        onFocus={ handlerOnFocus }
                        onBlur={ handlerBlur }
                        onKeyDown={ onKeyDown }
                        onPaste={ onPaste }
                        onClick={ onClick }
                        placeholder={ isFocused ? placeholder : null }
                        autoComplete={ autoComplete }
                        required={ required }
                        disabled={ disabled }
                        inputMode={ inputMode }
                        readOnly={ readOnly }
                        autoFocus={ autoFocus }
                    >
                        {
                            // @ts-ignore
                            (inputProps: any) => <input ref={ refGetter(inputRef, ref) } { ...inputProps } disabled={ disabled }/>
                        }
                    </InputMask>
                ) }
                { label && (
                    <label
                        className='FUI-input-label'
                        htmlFor={ id || name }
                    >
                        <span className='FUI-input-label-text'>{ label }</span>
                        { required ? (<span className='color-red'>&nbsp;*</span>) : null }
                    </label>
                ) }

                { (postfix && !disablePlaceholder && (!isEmpty || isFocused)) && (
                    <div className='FUI-input-placeholder'>
                        <span className='FUI-input-digits'>{ renderValue }</span>&nbsp;
                        <span className='FUI-input-postfix'>{ postfix }</span>
                    </div>
                ) }

                { !loading && rightIcon && (
                    <UIIcon
                        className={ cn(
                            'FUI-input-rightIcon',
                            { '-with-info': !!info },
                        ) }
                        name={ rightIcon }
                        size='normal'
                    />
                ) }

                { (!isEmpty && !loading && clearable && !disabled) && (
                    <button
                        type='button'
                        className={ cn('FUI-input-clear', { '-with-info': !!info }) }
                        onClick={ handlerClearClick }
                        tabIndex={ -1 }
                    >
                        <UIIcon
                            name='xmark'
                        />
                    </button>
                ) }
                { (!loading && info) && (
                    <UITooltip
                        trigger={ (ref) => (
                            <UIInfoIcon
                                ref={ ref }
                                className='FUI-input-info'
                            />
                        ) }
                        content={ info }
                    />
                ) }
            </div>
            { suggestionsAddon || null }
            { error && (
                <div className='FUI-input-error'>{ error }</div>
            ) }

        </div>
    );
});
