import { jsonParser } from './jsonParser';


export const CACHE_PREFIX = 'crm_';

interface IStorageItem {
    expireDate: string;
    data: string;
    flag: string;
}

/**
 * Cache manager
 * uses localStorage
 * @singleton
 */
export const Storage = {

    /**
     * save resource in local storage
     * create unlimited cache if {lifeTime} is missing
     * @param {string} name - unique name of resource
     * @param {*} data - resource
     * @param {?number} [lifeTime] - per milliseconds
     * @param {?string} [flag] - resources group name
     */
    setItem(name: string, data, lifeTime: number = null, flag: string = null) {
        const expireDate = lifeTime ? new Date(Date.now() + lifeTime) : null;
        localStorage.setItem(CACHE_PREFIX + name, JSON.stringify({
            expireDate,
            flag,
            data,
        }));
    },

    /**
     * get resource by name
     * @param {string} name - unique name of resource
     * @param {string} defaultValue - unique name of resource
     * @return {*} resource
     */
    getItem(name: string, defaultValue?: any): any | null {
        const item: IStorageItem = <IStorageItem>jsonParser(localStorage.getItem(CACHE_PREFIX + name));
        if (!(item instanceof Object)) {
            return defaultValue || null;
        }
        if (!item.expireDate) {
            return item.data || defaultValue;
        }
        const expireDate = new Date(item.expireDate);
        const now = new Date();
        if (expireDate > now) {
            return item.data || defaultValue;
        }
        return defaultValue || null;
    },

    /**
     * get resources by flag
     * @param {string} flag - resources group name
     * @return {Array<*>} resources
     */
    getByFlag(flag) {
        return Object.keys(localStorage)
            .filter((key) => key.startsWith(CACHE_PREFIX))
            .map((key) => <IStorageItem>jsonParser(localStorage.getItem(key)))
            .filter((resource) => resource instanceof Object && resource.flag === flag)
            .map((resource) => resource.data);
    },

    /**
     * remove resource by name
     * @param {string} name - unique name of resource
     */
    removeItem(name) {
        localStorage.removeItem(CACHE_PREFIX + name);
    },

    removeByScope(scope) {
        return Object.keys(localStorage)
            .filter((key) => key.startsWith(`${CACHE_PREFIX}.${scope}`))
            .forEach((key) => localStorage.removeItem(key));
    },

    /**
     * remove all cached resources
     */
    removeAll() {
        Object.keys(localStorage).forEach((key) => {
            if (key.startsWith(CACHE_PREFIX)) {
                localStorage.removeItem(key);
            }
        });
    },

    /**
     * get expire date of resource from local storage
     * @param {string} name - unique name of resource
     * @return {?Date}
     */
    getExpireDate(name) {
        const item = <IStorageItem>jsonParser(localStorage.getItem(CACHE_PREFIX + name));
        if (!(item && item.data && item.expireDate)) {
            return null;
        }
        return new Date(item.expireDate);
    },

    /**
     * subscribe on changes of resource in local storage
     * @param {string} name - unique name of resource
     * @param {function(StorageEvent)} listener
     */
    subscribe(name, listener) {
        window.addEventListener('storage', (event) => {
            if (event.key === CACHE_PREFIX + name) {
                listener(event);
            }
        });
    },

    clearExpired() {
        Object.keys(localStorage)
            .forEach((name) => {
                const item = <IStorageItem>jsonParser(localStorage.getItem(name));
                if (item && name.startsWith(CACHE_PREFIX) && item.expireDate !== null) {
                    const expireDate = new Date(item.expireDate);
                    const now = new Date();
                    if (expireDate < now) {
                        localStorage.removeItem(name);
                    }
                }
            });
    },

    /**
     * Clear app data from localStorage
     */
    clear() {
        Object.keys(localStorage)
            .forEach((name) => {
                const item = <IStorageItem>jsonParser(localStorage.getItem(name));
                if (item && name.startsWith(CACHE_PREFIX)) {
                    localStorage.removeItem(name);
                }
            });
    },
};

export default Storage;
