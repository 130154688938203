import { TUser } from '@/types/manager';
import { ILeadItem } from '@/types/lead';
import { TCreditor } from '@/types/creaditors';
import { TTelegramReportItem } from '@/types/telegram-report';
import { IPotentialCreditor } from '@/components/potential-creditor/types';

export type THolderType = 'legal_entity' | 'individual' | 'another' | 'public_formation';
export type THolderData = {
    name: string
    inn?: string
};

export type ReportMeta =  {
    hasRestrictions: boolean
    mortgageFound: boolean
    restrictionDescription?: string
    restrictionDate?: string
    holderType?: THolderType
    holderData?: THolderData
};


export enum EReportStatus {
    NEW = 'new',
    VALIDATION = 'validation',
    VALIDATION_ERROR = 'validationError',
    AWAITING_PAYMENT = 'awaitingPayment',
    PAID = 'paid',
    PROCESSING = 'processing',
    PROCESSED = 'processed',
    REJECTED = 'rejected',
    NO_INFORMATION = 'noinformation',
    SUSPENDED = 'suspended',
    TIMEOUTED = 'timeouted',
    RENEWAL_PROCESSING = 'renewalProcessing',
    RETURNED = 'returned',
    SENT = 'sent',
    ERROR = 'error',
}

export interface IReport {
    id: number;
    reportId: string;
    cadastralNumber: string;
    createdAt: string;
    updatedAt: string;
    status: EReportStatus;
    comment: string;
    userId: number;
    processed: boolean;
    address: string;
    user: TUser;
    client: ILeadItem;
    autoGenerated: boolean;
    checkListsGenerated: boolean;
    tgOrder: TTelegramReportItem;
    report: {
        reportData: {
            status: string
        }
        reportMeta: ReportMeta,
    };

    existsCreditor?: TCreditor
    existsPotentialCreditor?: IPotentialCreditor
}

export const MReportStatus = new Map<EReportStatus, string>([
    [ EReportStatus.NEW,  'Создана' ],
    [ EReportStatus.VALIDATION,  'На проверке' ],
    [ EReportStatus.VALIDATION_ERROR,  'Ошибка проверки' ],
    [ EReportStatus.AWAITING_PAYMENT,  'Ожидает оплаты' ],
    [ EReportStatus.PAID,  'Оплачена' ],
    [ EReportStatus.PROCESSING,  'В работе' ],
    [ EReportStatus.PROCESSED,  'Готова' ],
    [ EReportStatus.REJECTED,  'Отказано' ],
    [ EReportStatus.NO_INFORMATION,  'Отсутствует информация' ],
    [ EReportStatus.SUSPENDED,  'Приостановлена' ],
    [ EReportStatus.TIMEOUTED,  'Истекло время запроса' ],
    [ EReportStatus.RENEWAL_PROCESSING,  'В работе' ],
    [ EReportStatus.RETURNED,  'Возвращена' ],
    [ EReportStatus.SENT,  'Отправлена' ],
    [ EReportStatus.ERROR,  'Ошибка' ],
]);

export const MReportRestrictionHolder = new Map<string, string>([
    [ 'individual',  'Физ. лицо' ],
    [ 'legal_entity',  'Юр. лицо' ],
]);
