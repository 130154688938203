import React from 'react';
import { UIButton } from 'finbox-ui-kit';
import { TPageButton } from 'finbox-ui-kit/components/page/page';

type TPageButtonsProps = {
    buttons: TPageButton[];
}
export const PageButtons = ({ buttons }: TPageButtonsProps) => (
    <div className='ui-page-buttons'>
        { buttons.map((button, index) => {
            if (typeof button === 'object' && 'text' in button) {
                return (
                    <UIButton
                        key={ index }
                        content={ button.text }
                        onClick={ button.onClick }
                        color={ button.color }
                        icon={ button.icon }
                        disabled={ Boolean(button.disabled) }
                    />
                );
            }
            return button;
        }) }
    </div>
)