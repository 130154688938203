import { io } from 'socket.io-client';

export const socket = io('/sockets', {
    path: '/sockets',
    transports: [ 'websocket' ],
    autoConnect: false,
    reconnection: false,
    reconnectionDelay: 3000,
    reconnectionAttempts: 20,
});
