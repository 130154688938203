import { atom } from 'recoil';
import { TUser } from '@/types/manager';


export const AuthState = atom<boolean>({
    key: 'authState',
    default: false,
});


export const AuthUser = atom<TUser | null>({
    key: 'authUser',
    default: null,
});

