import React, { useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';

import { UIIcon, UIPopup } from 'finbox-ui-kit';
import { useToggle } from 'finbox-ui-kit/utils/hooks';
import { TUISize } from 'finbox-ui-kit/types/common';
import styles from './style.module.scss';


type TUIInfoProps = {
    children?: React.ReactNode,
    content?: React.ReactNode,
    className?: string,
    opened?: boolean,
    inverted?: boolean,
    showOnHover?: boolean,
    size?: TUISize
}
export const UIInfo: React.FC<TUIInfoProps> = ({
    opened,
    content,
    className,
    inverted,
    children,
    showOnHover,
    size,
}) => {
    const ref = useRef<HTMLDivElement>();
    const { on, toggle } = useToggle();

    const handlerCLickOpen = useCallback(() => {
        toggle(true);
    }, [ toggle ]);

    useEffect(() => {
        if (opened) {
            setTimeout(handlerCLickOpen, 200);
        }
    }, [ opened, handlerCLickOpen ]);

    const handlerClickOutside = useCallback(() => {
        toggle(false);
    }, [ toggle ]);

    const handlerMouseEnter = useCallback(() => {
        if (showOnHover) {
            toggle(true);
        }
    }, [ showOnHover, toggle ]);

    const handlerMouseLeave = useCallback(() => {
        if (showOnHover) {
            toggle(false);
        }
    }, [ showOnHover, toggle ]);

    return (
        <div
            className={ cn(styles.FUIInfo, className) }
            onMouseLeave={ handlerMouseLeave }
        >
            <div
                ref={ ref }
                className={ styles.FUIInfoIcon }
                onClick={ handlerCLickOpen }
                onMouseEnter={ handlerMouseEnter }
            >
                <UIIcon
                    name='info'
                    size='small'
                />
            </div>
            {on && (
                <UIPopup
                    targetRef={ ref }
                    // strategy='fixed'
                    // portal='root'
                    position='bottom center'
                    open={ on }
                    onClickOutside={ handlerClickOutside }
                    minWidth={ 0 }
                >
                    <div className={ cn(styles.FUIInfoPopup, { [styles.Inverted]: inverted, [`-${ size }`]: size }) }>
                        { content || children }
                    </div>
                </UIPopup>
            )}
            {/*<UIInfoPopup*/ }
            {/*    onClickOutside={ handlerClickOutside }*/ }
            {/*    onMouseLeave={ handlerMouseEnter }*/ }
            {/*    content={ content || children }*/ }
            {/*    isOpened={ on }*/ }
            {/*    rect={ref.current?.getBoundingClientRect()}*/ }
            {/*/>*/ }
        </div>
    );
};
