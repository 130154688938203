import { TUser } from '@/types/manager';

export type TRosreestrAccount = {
    id: string
    login: string
    active: boolean
    alive: boolean
    isRoot: boolean
    forCheck: boolean
    createdAt: string
    updatedAt: string
    password?: string
    twoFactorAuthEnabled?: boolean

    user: TUser
}

export type TRosreestrAccountBalance = {
    count: number
    mnemo: string
    name: string
    ordinal: number
    totalCount: number
}

export enum RosreestrCheckListStatus {
    NEW = 'new',
    WAIT = 'wait',
    IN_PROGRESS = 'in_progress',
    PAUSED = 'paused',
    STOPPED = 'stopped',
    FINISHED = 'finished',
}

export type TRosreestrCheckList = {
    id: string
    name: string
    createdAt: string
    updatedAt: string
    status: RosreestrCheckListStatus
    count: number
    total: number
    processed: number
    progress: number
    errored: number
}

export type TRosreestrCheckListItem = {
    id: string
    cadNumber: string
    status: string
    checked: boolean
    createdAt: string
    updatedAt: string
}