import { useCallback, useEffect } from 'react'

export const useBeforeUnload = (value: any) => {
    const handleBeforeunload = useCallback((e) => {
        let returnValue
        if (typeof value === 'function') {
            returnValue = value(e)
        } else {
            returnValue = value
        }
        if (returnValue) {
            e.preventDefault()
            e.returnValue = returnValue
        }
        return returnValue
    }, [ value ]);

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeunload)
        return () => window.removeEventListener('beforeunload', handleBeforeunload)
    }, [ handleBeforeunload ])
}
