import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, FieldError } from 'react-hook-form';
import get from 'lodash/get';
import { API_URLS, MClientAddressBasis } from '@/consts';
import { TPassport } from '@/components/leads/components/credit-from/types';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { InputSuggestions } from '@/common/ui/input-suggestions';
import { Input } from '@/common/ui/input';
import { Grid } from '@/common/ui/grid';
import { Checkbox } from '@/common/ui/checkbox';
import { Dropdown } from '@/common/ui/dropdown';
import { InputAddress } from '@/common/ui/input-address';

type PassportFormType = any & {
    [x: string]: TPassport
}

type PassportFormProps = {
    path: string
    withoutSnils?: boolean
    withoutAddress?: boolean
    disabled?: boolean
}

export function PassportForm({ path, withoutSnils, withoutAddress, disabled }: PassportFormProps) {
    const {
        control,
        formState: { errors },
        watch,
        setValue,
        clearErrors,
    } = useFormContext<PassportFormType>();

    const addressEqualRegistration = watch(`${ path }.addressEqualRegistration`);
    const departmentCode = watch(`${ path }.departmentCode`);
    const registrationAddress = watch(`${ path }.registrationAddress`);

    const [ departmentSuggestions, setDepartmentSuggestions ] = useState([]);

    const { loading: isLoading, fetch } = useApiClient({
        url: API_URLS.DICTIONARIES.DADATA.PASSPORT,
    });

    useEffect(() => {
        if (departmentCode && /^[0-9]{3}-[0-9]{3}$/.test(departmentCode)) {
            fetch({
                query: departmentCode,
            }).then((response) => {
                setDepartmentSuggestions(response.suggestions.map((i) => ({
                    text: i.value,
                    value: i.value,
                })));
            });
        }
    }, [ fetch, departmentCode ]);

    useEffect(() => {
        if (addressEqualRegistration) {
            setValue(
                `${ path }.address`,
                registrationAddress,
            );
            clearErrors(`${ path }.address`);
        }
    }, [ addressEqualRegistration, path, clearErrors, registrationAddress, setValue ]);

    return (
        <Grid stackable>
            <Grid.Row columns={ 3 }>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={ `${ path }.serialAndNumber` }
                        render={ ({ field }) => (
                            <Input
                                { ...field }
                                type='text'
                                label='Серия и номер'
                                mask='9999 999999'
                                error={ (get(errors, `${ path }.serialAndNumber`) as FieldError)?.message }
                                disabled={ disabled }
                                autoComplete='off'
                                required
                            />
                        ) }
                    />
                </Grid.Col>
            </Grid.Row>

            <Grid.Row columns={ 3 }>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={ `${ path }.birthday` }
                        render={ ({ field }) => (
                            <Input
                                { ...field }
                                type='text'
                                label='Дата рождения'
                                mask='99.99.9999'
                                error={ (get(errors, `${ path }.birthday`) as FieldError)?.message }
                                disabled={ disabled }
                                placeholder='дд.мм.гггг'
                                autoComplete='off'
                                required
                                clearable
                            />
                        ) }
                    />
                </Grid.Col>
                <Grid.Col colspan={ 2 }>
                    <Controller
                        control={ control }
                        name={ `${ path }.birthplace` }
                        render={ ({ field }) => (
                            <Input
                                { ...field }
                                type='text'
                                label='Место рождения'
                                error={ (get(errors, `${ path }.birthplace`) as FieldError)?.message }
                                disabled={ disabled }
                                autoComplete='off'
                                required
                                clearable
                            />
                        ) }
                    />
                </Grid.Col>
            </Grid.Row>
            <Grid.Row columns={ 3 }>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={ `${ path }.departmentCode` }
                        render={ ({ field }) => (
                            <Input
                                { ...field }
                                type='text'
                                label='Код подразделения'
                                mask='999-999'
                                error={ (get(errors, `${ path }.departmentCode`) as FieldError)?.message }
                                disabled={ disabled }
                                placeholder='000-000'
                                autoComplete='off'
                                required
                                clearable
                            />
                        ) }
                    />
                </Grid.Col>
                <Grid.Col colspan={ 2 }>
                    <Controller
                        control={ control }
                        name={ `${ path }.department` }
                        render={ ({ field }) => (
                            <InputSuggestions
                                { ...field }
                                type='text'
                                label='Кем выдан'
                                name='passport.department'
                                suggestions={ departmentSuggestions }
                                error={ (get(errors, `${ path }.department`) as FieldError)?.message }
                                disabled={ disabled }
                                loading={ isLoading }
                                autoComplete='off'
                                required
                                clearable
                            />
                        ) }
                    />
                </Grid.Col>

                <Grid.Col>
                    <Controller
                        control={ control }
                        name={ `${ path }.dateOfIssue` }
                        render={ ({ field }) => (
                            <Input
                                { ...field }
                                type='text'
                                label='Дата выдачи'
                                mask='99.99.9999'
                                error={ (get(errors, `${ path }.dateOfIssue`) as FieldError)?.message }
                                disabled={ disabled }
                                placeholder='дд.мм.гггг'
                                autoComplete='off'
                                required
                                clearable
                            />
                        ) }
                    />
                </Grid.Col>
            </Grid.Row>
            { !withoutAddress && (
                <Grid.Row columns={ 3 }>
                    <Grid.Col colspan={ 2 }>
                        <Controller
                            control={ control }
                            name={ `${ path }.registrationAddress` }
                            render={ ({ field }) => (
                                <InputAddress
                                    { ...field }
                                    label='Адрес прописки'
                                    autoComplete='off'
                                    error={ (get(errors, `${ path }.registrationAddress`) as FieldError)?.message }
                                    disabled={ disabled }
                                    required
                                    clearable
                                />
                            ) }
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Controller
                            control={ control }
                            name={ `${ path }.registrationDate` }
                            render={ ({ field }) => (
                                <Input
                                    { ...field }
                                    type='text'
                                    label='Дата регистрации'
                                    mask='99.99.9999'
                                    error={ (get(errors, `${ path }.registrationDate`) as FieldError)?.message }
                                    disabled={ disabled }
                                    placeholder='дд.мм.гггг'
                                    autoComplete='off'
                                    required
                                    clearable
                                />
                            ) }
                        />
                    </Grid.Col>
                    <Grid.Col fluid>
                        <Controller
                            control={ control }
                            name={ `${ path }.addressEqualRegistration` }
                            render={ ({ field }) => (
                                <Checkbox
                                    label='Совпадает с адресом прописки'
                                    checked={ field.value }
                                    onChange={ (e, { checked }) => {
                                        field.onChange(checked);
                                    } }
                                    disabled={ disabled }
                                />
                            ) }
                        />
                    </Grid.Col>
                    <Grid.Col colspan={ 2 }>
                        <Controller
                            control={ control }
                            name={ `${ path }.address` }
                            render={ ({ field }) => (
                                <InputAddress
                                    { ...field }
                                    label='Адрес проживания'
                                    name='address'
                                    disabled={ addressEqualRegistration || disabled }
                                    error={ (get(errors, `${ path }.address`) as FieldError)?.message }
                                    autoComplete='off'
                                    required
                                    clearable
                                />
                            ) }
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Controller
                            control={ control }
                            name={ `${ path }.addressBasis` }
                            render={ ({ field }) => (
                                <Dropdown
                                    { ...field }
                                    label='Основание проживания'
                                    options={ Array.from(MClientAddressBasis).map(([ value, text ]) => ({
                                        text,
                                        value,
                                    })) }
                                    error={ (get(errors, `${ path }.addressBasis`) as FieldError)?.message }
                                    disabled={ disabled }
                                    required
                                />
                            ) }
                        />
                    </Grid.Col>
                </Grid.Row>
            ) }
            { !withoutSnils && (
                <Grid.Row columns={ 3 }>
                    <Grid.Col>
                        <Controller
                            control={ control }
                            name={ `${ path }.snils` }
                            render={ ({ field }) => (
                                <Input
                                    { ...field }
                                    type='text'
                                    label='СНИЛС'
                                    mask='999-999-999 99'
                                    error={ (get(errors, `${ path }.snils`) as FieldError)?.message }
                                    disabled={ disabled }
                                    placeholder='000-000-000 00'
                                    clearable
                                />
                            ) }
                        />
                    </Grid.Col>
                </Grid.Row>
            ) }
        </Grid>
    );
}
