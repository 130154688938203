import React, { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { UIButton, UIGrid, UIOverlayModal, UIParam } from 'finbox-ui-kit';
import { FORMAT } from 'finbox-ui-kit/consts';
import { formatPrice, transformUrlParams } from '@/utils';
import { API_URLS } from '@/consts';
import { ObjectType, RealtyType } from '@/components/leads/components/credit-from/consts';
import { CadastralReportsList } from '@/common/cadastral-number/cadastral-reports-list';
import { useApiClient } from '@/libs/api-client/use-api-client';

export const CadastralNumberDetail = ({ open, cadastralNumber, clientId, onClose, index }: any) => {
    const [ data, setData ] = useState({
        count: 0,
        elements: [],
    });

    const { loading: isLoading, fetch } = useApiClient({
        url: transformUrlParams(API_URLS.EGRN.CADASTRAL_NUMBER_DETAIL, { cadastralNumber }),
    });

    useEffect(() => {
        if (open) {
            fetch(
                null,
                {
                    url: transformUrlParams(API_URLS.EGRN.CADASTRAL_NUMBER_DETAIL, { cadastralNumber }),
                },
            ).then((response) => setData(response));
        }
    }, [ open, fetch, cadastralNumber ]);

    const handlerClickClose = useCallback(() => {
        setData({
            count: 0,
            elements: [],
        });
        onClose();
    }, [ onClose ]);

    if (!data.elements) {
        return null;
    }
    const [ info ] = data.elements;

    return (
        <UIOverlayModal
            open={open}
            onClose={handlerClickClose}
            index={index}
        >
            <UIOverlayModal.Header>Информация по объекту {cadastralNumber}</UIOverlayModal.Header>
            <UIOverlayModal.Body className='relative'>
                <UIGrid stackable>
                    <UIGrid.Row columns={3}>
                        <UIGrid.Col>
                            <b>Общая информация</b>
                            <UIParam
                                label='Кадастровый номер'
                                value={info?.cadNumber}
                                loading={isLoading}
                            />
                            <UIParam
                                label='Статус объекта'
                                value={info?.status === '1' ? 'Актуально' : 'Не актуально'}
                                loading={isLoading}
                            />
                            <UIParam
                                label='Дата постановки на кадастровый учет'
                                value={info?.regDate ? DateTime.fromMillis(info.regDate).toFormat(FORMAT.DATE) : '-'}
                                loading={isLoading}
                            />
                            <UIParam
                                label='Дата обновления информации'
                                value={info?.infoUpdateDate ? DateTime.fromMillis(info.infoUpdateDate).toFormat(FORMAT.DATE) : '-'}
                                loading={isLoading}
                            />
                        </UIGrid.Col>
                        <UIGrid.Col>
                            <b>Характеристика объекта</b>
                            <UIParam
                                label='Тип объекта'
                                value={ObjectType[info?.objType]}
                                loading={isLoading}
                            />
                            <UIParam
                                label='Этаж'
                                value={info?.levelFloor}
                                loading={isLoading}
                            />
                            <UIParam
                                label='Площадь, кв.м'
                                value={info?.area}
                                loading={isLoading}
                            />
                            <UIParam
                                label='Кадастровая стоимость'
                                value={formatPrice(info?.cadCost)}
                                loading={isLoading}
                            />
                            <UIParam
                                label='Дата определения стоимости'
                                value={info?.cadCostDeterminationDate ? DateTime.fromMillis(info.cadCostDeterminationDate).toFormat(FORMAT.DATE) : '-'}
                                loading={isLoading}
                            />
                            <UIParam
                                label='Дата внесения стоимости'
                                value={info?.cadCostRegistrationDate ? DateTime.fromMillis(info.cadCostRegistrationDate).toFormat(FORMAT.DATE) : '-'}
                                loading={isLoading}
                            />
                            <UIParam
                                label='Назначение'
                                value={RealtyType.get(info?.purpose)}
                                loading={isLoading}
                            />
                        </UIGrid.Col>
                        <UIGrid.Col>
                            <b>Права и ограничения</b>
                            <UIParam
                                label='Назначение'
                                value={(info?.rights || []).length > 0 ? 'Зарегистрированы' : 'Не зарегистрированы'}
                                loading={isLoading}
                            />
                            {(info?.rights || []).map((right) => (
                                <div key={right.rightNumber}>
                                    <UIParam
                                        label='Право'
                                        value={
                                            [
                                                `${right.rightNumber} (${right.rightTypeDesc})`,
                                                right.rightRegDate ? ` от ${DateTime.fromMillis(right.rightRegDate).toFormat(FORMAT.DATE)}` : '',
                                            ].join(' ')
                                        }
                                        loading={isLoading}
                                    />
                                </div>
                            ))}
                            {(info?.encumbrances || []).map((encumb) => (
                                <div key={encumb.rightNumber} className='color-red'>
                                    <UIParam
                                        label='Ограничение'
                                        value={[
                                            encumb.encumbranceNumber,
                                            encumb.typeDesc ? `(${encumb.typeDesc})` : '',
                                            encumb.startDate ? `от ${DateTime.fromMillis(encumb.startDate).toFormat(FORMAT.DATE)}` : '',
                                        ].join(' ')}
                                        loading={isLoading}
                                    />
                                </div>
                            ))}
                            <div><b>Устаревшие номера</b></div>
                            {(info?.oldNumbers || []).map((number) => (
                                <div key={number.numValue}>
                                    <UIParam
                                        label={number.numType}
                                        value={number.numValue}
                                        loading={isLoading}
                                    />
                                </div>
                            ))}
                        </UIGrid.Col>
                    </UIGrid.Row>
                </UIGrid>
                {clientId && (
                    <div className='mt2'>
                        <CadastralReportsList
                            cadastralNumber={cadastralNumber}
                            clientId={clientId}
                        />
                    </div>
                )}
            </UIOverlayModal.Body>
            <UIOverlayModal.Footer>
                <UIButton
                    type='button'
                    content='Закрыть'
                    onClick={handlerClickClose}
                />
            </UIOverlayModal.Footer>
        </UIOverlayModal>
    );
};
