import React from 'react';
import styled from 'styled-components';
import { em } from 'finbox-ui-kit/utils/styled';


const CommentsCommentStyled = styled.div`
  margin-bottom: 1em;
`;

const CommentsCommentMetaStyled = styled.span`
  font-size: ${ em(12) };
  display: inline-block;
  color: var(--FUI-color-grey);
  margin-left: .5em;
`;

const CommentsCommentTextStyled = styled.p`
  white-space: pre-wrap;
`;

export function CommentsComment({ title, text, datetime }: any) {
    return (
        <CommentsCommentStyled>
            <b>{ title }</b>
            <CommentsCommentMetaStyled>{ datetime }</CommentsCommentMetaStyled>
            <CommentsCommentTextStyled>{ text }</CommentsCommentTextStyled>
        </CommentsCommentStyled>
    );
}