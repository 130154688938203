import axios from "axios";
import { API_BASE_URL_V2, VERSION } from '@/consts';
import { getFingerprint } from '@/utils/fingerprint';
import { NeedUpgradeException } from '@/libs/api-client/need-upgrade.exception';

const axiosPublic = axios.create({
    baseURL: API_BASE_URL_V2,
    headers: {
        "Content-Type": "application/json",
        'X-version': VERSION,
    },
});

let fingerprint: string;

axiosPublic.interceptors.request.use(
    async (config) => {
        fingerprint ??= await getFingerprint();
        config.headers = {
            ...config.headers,
            'X-sec': fingerprint,
        } as any;
        return config;
    },
    (error) => Promise.reject(error),
);

axiosPublic.interceptors.response.use(
    async (config) => config,
    (error) => {
        if (error?.response?.status === 418) {
            return Promise.reject(new NeedUpgradeException())
        }
        return Promise.reject(error);
    },
);

export { axiosPublic };