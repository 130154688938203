import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { GeneratePassword } from '@/common/generate-password';
import { Input } from '@/common/ui/input';
import { passwordScheme } from './password.scheme';

type TForm = {
    password: string;
    rePassword: string;
}

type ChangePasswordFormProps = {
    loading: boolean;
    onSubmit: (password: string) => void
};

export function ChangePasswordForm({ loading, onSubmit }: ChangePasswordFormProps): React.ReactElement {
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm<TForm>({
        resolver: passwordScheme,
    });

    const handlerSubmit = async (fields: TForm) => {
        onSubmit(fields.password);
    };

    const handlerGeneratedPassword = (password: string) => {
        clearErrors();
        setValue('password', password);
        setValue('rePassword', password);
    };


    return (
        <form id='change-password-form' onSubmit={ handleSubmit(handlerSubmit) } noValidate>
            <Controller
                control={ control }
                name='password'
                render={ ({ field }) => (
                    <Input
                        { ...field }
                        className='mb1'
                        type='password'
                        label='Пароль'
                        error={ errors.password?.message }
                        disabled={ loading }
                    />
                ) }
            />
            <Controller
                control={ control }
                name='rePassword'
                render={ ({ field }) => (
                    <Input
                        { ...field }
                        className='mb1'
                        type='password'
                        label='Повторите пароль'
                        error={ errors.rePassword?.message }
                        disabled={ loading }
                    />
                ) }
            />
            <GeneratePassword onGenerate={ handlerGeneratedPassword }/>
        </form>
    );
}