import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import { useNavigate } from 'react-router';
import { UIButton, UIIconButton, UIParam } from 'finbox-ui-kit';
import { UIIcon } from 'finbox-ui-kit/components/icon';
import { decl, formatInitials } from 'finbox-ui-kit/utils';
import { Timer } from '@/common/time-tracker/timer';
import { formatNumber } from '@/utils';
import { Nullable } from '@/types/_helpers';
import { ILeadItem } from '@/types/lead';
import { Phone } from '@/common';

import './incoming-call.style.scss';
import { useClientTasksContext } from '@/context/client-tasks.context';
import { useClientContext } from '@/context/client.context';
import { useSocketsContext } from '@/libs/sockets/sockets.context';
import { ClientSource, SOCKET_EVENT } from '@/consts';

const audio = new Audio('/assets/sounds/incoming_call_notify.mp3');

type TEventData = {
    number: string,
    clients: Nullable<ILeadItem[]>,
    callId: number,
}
const IncomingCall = () => {
    const navigate = useNavigate();
    const { subscribe, unsubscribe } = useSocketsContext();
    const [ showed, setShowed ] = useState(false);
    const [ minimized, setMinimized ] = useState(false);

    const [ data, setData ] = useState<TEventData>(null);
    const [ isAnswered, setIsAnswered ] = useState(false);
    const [ isFinished, setIsFinished ] = useState(false);
    const [ isShowedAllClients, setIsShowedAllClients ] = useState(false);

    const { addTask } = useClientTasksContext();
    const { createClient } = useClientContext();

    const handlerEventIncomingCall = useCallback(async (data: TEventData) => {
        setData(data);
        setShowed(true);
        setMinimized(false);
        setIsAnswered(false);
        setIsFinished(false);
        setIsShowedAllClients(false);
        await audio.play();
    }, []);

    const handlerEventAnsweredCall = useCallback(() => {
        setIsAnswered(true);
    }, []);

    const handlerEventFinishedCall = useCallback(() => {
        if (isAnswered) {
            setTimeout(() => {
                setShowed(false);
            }, 3000);
        } else {
            setMinimized(true);
        }
        setIsFinished(true);
    }, [ isAnswered ]);

    useEffect(() => {
        subscribe(SOCKET_EVENT.INCOMING_CALL, handlerEventIncomingCall);
        subscribe(SOCKET_EVENT.ANSWERED_CALL, handlerEventAnsweredCall);
        subscribe(SOCKET_EVENT.END_CALL, handlerEventFinishedCall);
        return () => {
            unsubscribe(SOCKET_EVENT.INCOMING_CALL, handlerEventIncomingCall);
            unsubscribe(SOCKET_EVENT.ANSWERED_CALL, handlerEventAnsweredCall);
            unsubscribe(SOCKET_EVENT.END_CALL, handlerEventFinishedCall);
        };
    }, [ handlerEventAnsweredCall, handlerEventFinishedCall, handlerEventIncomingCall, subscribe, unsubscribe ]);

    const handlerClickMinimize = useCallback(() => {
        setMinimized(true);
    }, []);

    const handlerClickMaximize = useCallback(() => {
        setMinimized(false);
    }, []);

    const handlerClickClose = useCallback(() => {
        setShowed(false);
    }, []);

    const handlerClickShowAllClients = useCallback(() => {
        setIsShowedAllClients(true);
    }, []);

    const handlerCLickClient = useCallback((client: ILeadItem) => () => {
        if (client.deleted) {
            navigate(`/garbage/?id=${ client.id }`);
        } else if (client.contracted) {
            navigate(`/contracts/?leadId=${ client.id }`);
        } else {
            navigate(`/leads/${ client.id }/`);
        }
        setMinimized(true);
    }, [ navigate ]);

    const handlerCLickCreate = useCallback(() => {
        createClient({
            source: ClientSource.CALL,
            phone: data.number,
            cid: data.callId,
        });
        setMinimized(true);
    }, [ createClient, data?.callId, data?.number ]);

    const formattedNumber = formatNumber(data?.number);

    const showedClient = data?.clients?.find(((i) => !i.deleted));
    const otherClients = data?.clients?.filter((i) => i.id !== showedClient?.id);

    const handlerClickAddTask = useCallback(() => {
        addTask(showedClient.id, () => {
            setMinimized(false);
        });
        setMinimized(true);
    }, [ addTask, showedClient?.id ]);

    return (
        <div className={ cn('incoming-call', { '-showed': showed, '-minimized': minimized }) }>
            <div className='incoming-call-header'>
                <UIIcon name='phone-volume' type='light'/>
                <div className='incoming-call-header-text'>
                    <div className='incoming-call-header-text-title'>Входящий звонок</div>
                    <div className='incoming-call-header-text-phone'>
                        { data?.clients ? formatInitials(showedClient, true) : formattedNumber }
                    </div>
                </div>
                <div className='incoming-call-header-timer'>
                    { isAnswered && !isFinished && (
                        <Timer isActive={ !isFinished }/>
                    ) }
                    { !isAnswered && isFinished && (
                        <span className='color-red'>Пропущен</span>
                    ) }
                    { isAnswered && isFinished && (
                        <span className='color-green'>Завершён</span>
                    ) }
                </div>
                { !minimized && (
                    <UIIconButton icon='window-minimize' onClick={ handlerClickMinimize } iconType='light'/>
                ) }
                { minimized && (
                    <UIIconButton icon='window-maximize' onClick={ handlerClickMaximize } iconType='light'/>
                ) }
                <UIIconButton icon='xmark' onClick={ handlerClickClose }/>
            </div>
            <div className='incoming-call-body'>
                <div className='incoming-call-body-wrapper'>
                    <UIParam label='Номер'>
                        <Phone number={ data?.number }/>
                    </UIParam>
                    <UIParam label='Клиент'>
                        { showedClient ? (
                            <UIButton className='link-button color-blue' onClick={ handlerCLickClient(showedClient) }>
                                { data.clients.length > 1 && '1. ' }
                                { formatInitials(showedClient, true) }{ ' ' }
                                ({ formatInitials(showedClient.currentManager, true) })
                                { ' - ' }
                                { showedClient.deleted && 'Удалён' }
                                { showedClient.contracted && 'Сделка' }
                                { !showedClient.deleted && !showedClient.contracted && 'Активен' }
                            </UIButton>
                        ) : (<span className='color-grayDark fz12'>Не найден</span>) }
                        { data?.clients?.length > 1 && !isShowedAllClients && (
                            <div>
                                <UIButton
                                    className='link-button'
                                    onClick={ handlerClickShowAllClients }
                                >
                                    и еще { otherClients.length - 1 } { decl(otherClients.length - 1, [ 'клиент', 'клиента', 'клиентов' ]) }
                                </UIButton>
                            </div>
                        ) }
                        { data?.clients?.length > 1 && isShowedAllClients && otherClients.map((client, index) => (
                            <div key={ client.id }>
                                <UIButton
                                    className='link-button color-blue'
                                    onClick={ handlerCLickClient(client) }
                                >
                                    { index + 2 }.&nbsp;
                                    { formatInitials(client, true) }{ ' ' }
                                    ({ formatInitials(client.currentManager, true) })
                                    { ' - ' }
                                    { client.deleted && 'Удалён' }
                                    { client.contracted && 'Сделка' }
                                    { !client.deleted && !client.contracted && 'Активен' }
                                </UIButton>
                            </div>
                        )) }
                    </UIParam>
                    <UIButton.Group>
                        { !data?.clients?.some((i) => !i.deleted) && (
                            <UIButton
                                icon='user-plus'
                                onClick={ handlerCLickCreate }
                            >
                                Создать клиента
                            </UIButton>
                        ) }
                        { data?.clients?.some((i) => !i.deleted) && (
                            <UIButton
                                icon='user'
                                onClick={ handlerCLickClient(data.clients[0]) }
                            >
                                Открыть клиента
                            </UIButton>
                        ) }
                        { showedClient && (
                            <UIButton
                                icon='calendar-plus'
                                onClick={ handlerClickAddTask }
                            >
                                Добавить задачу
                            </UIButton>
                        ) }
                    </UIButton.Group>
                </div>
            </div>
        </div>
    );
};

export default IncomingCall;
