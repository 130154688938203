import React from 'react';
import { DateTime } from 'luxon';
import cn from 'classnames';
import styles from './date-picker-calendar.module.scss';


export type TCalendarDayRenderProps = {
    selected: boolean
    hasBefore: boolean
    hasAfter: boolean
    disabled?: boolean
}

type DayPickerProps = {
    day: DateTime;
    onClick: (date: DateTime) => void;
    renderProps: TCalendarDayRenderProps;
};

export function DatePickerCalendarDay({ day, onClick, renderProps }: DayPickerProps) {
    function handlerClick() {
        if (!renderProps.disabled) {
            onClick(day);
        }
    }

    return (
        <button
            type='button'
            className={ cn(
                styles.dayPickerCalendarDaysDay, {
                    [styles.dayPickerCalendarDaysDayCurrent]: day.hasSame(DateTime.now(), 'day'),
                    [styles.dayPickerCalendarDaysDaySelected]: renderProps.selected,
                    [styles.dayPickerCalendarDaysDayDisabled]: renderProps.disabled,
                    [styles.dayPickerCalendarDaysDayHasAfter]: renderProps.selected && renderProps.hasAfter,
                    [styles.dayPickerCalendarDaysDayHasBefore]: renderProps.selected && renderProps.hasBefore,
                },
            ) }
            onClick={ handlerClick }
            tabIndex={-1}
        >
            <span>{ day.day }</span>
        </button>
    );
}