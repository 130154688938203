import React from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { UIGrid } from 'finbox-ui-kit';
import { useToggle } from 'finbox-ui-kit/utils/hooks';
import { createArray } from 'finbox-ui-kit/utils';
import { Icon } from '@/common/ui/icon';
import { Dropdown } from '@/common/ui/dropdown';
import styles from './date-picker-controls.module.scss';

type DatePickerControlsProps = {
    current: DateTime;
    onChange: (date: DateTime) => void;
    className?: string;
    hideEdit?: boolean;
    hideNext?: boolean;
    hidePrev?: boolean;
};

export function DatePickerControls({ current, onChange, className, hideEdit, hideNext, hidePrev }: DatePickerControlsProps): React.ReactElement {
    const { on: showedMonthYearEdit, toggle: toggleMonthYearEdit } = useToggle();

    function handlerClickMonthNav(count: number) {
        return function nav() {
            onChange(current.plus({ month: count }));
        };
    }

    function handlerChangeMonth(month: number) {
        onChange(current.set({ month }));
    }

    function handlerChangeYear(year: number) {
        onChange(current.set({ year }));
    }

    return (
        <div className={ cn(styles.datePickerControls, className) }>
            <div className={ styles.datePickerControlsMonth }>
                <button
                    type='button'
                    className={ cn(styles.datePickerControlsNav, { [styles.datePickerControlsNavHidden]: hidePrev }) }
                    onClick={ handlerClickMonthNav(-1) }
                    disabled={ hidePrev }
                >
                    <Icon name='chevron-left'/>
                </button>

                <div className={ styles.datePickerControlsTitle }>
                    { current.setLocale('ru').toFormat('LLLL yyyy') }

                    { !hideEdit && (
                        <button
                            type='button'
                            className={ styles.datePickerControlsEdit }
                            onClick={ () => toggleMonthYearEdit() }
                        >
                            <Icon name='pen' type='light'/>
                        </button>
                    ) }
                </div>

                <button
                    type='button'
                    className={ cn(styles.datePickerControlsNav, { [styles.datePickerControlsNavHidden]: hideNext }) }
                    onClick={ handlerClickMonthNav(1) }
                    disabled={ hideNext }
                >
                    <Icon name='chevron-right'/>
                </button>
            </div>
            { showedMonthYearEdit && (
                <UIGrid className='mb_5'>
                    <UIGrid.Row columns={ 2 } gap={ .5 }>
                        <Dropdown
                            name='month'
                            value={ current.month }
                            onChange={ handlerChangeMonth }
                            label='Месяц'
                            options={ createArray(12).map((i) => ({
                                text: DateTime.now().startOf('year').plus({ month: i }).setLocale('ru').toFormat('LLLL'),
                                value: i + 1,
                            })) }
                            size='small'
                        />
                        <Dropdown
                            name='year'
                            value={ current.year }
                            onChange={ handlerChangeYear }
                            label='Год'
                            options={ createArray(10).map((i) => ({
                                text: DateTime.now().plus({ year: -5 + i }).setLocale('ru').toFormat('yyyy'),
                                value: Number(DateTime.now().plus({ year: -5 + i }).setLocale('ru').toFormat('yyyy')),
                            })) }
                            size='small'
                        />
                    </UIGrid.Row>
                </UIGrid>
            ) }
        </div>
    );
}