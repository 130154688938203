import React from 'react';
import { UIBlock } from 'finbox-ui-kit';
import { formatPrice } from '@/utils';
import { EActiveType, MCurrencyName, MCurrencyPrefix } from '@/consts';
import { UIInput } from '@/common/finbox-ui-kit/src/components/input';
import { Grid } from '@/common/ui/grid';
import { Dropdown } from '@/common/ui/dropdown';
import { Input } from '@/common/ui/input';

export const BankDepositActiveForm = ({ fields, onChange, onRemove }) => (
    <UIBlock
        title='Вклад'
        buttons={ [ { icon: 'xmark', onClick: onRemove } ] }
        className={ `active-${ EActiveType.BANK_DEPOSIT }` }
    >
        <Grid className='--no-margin-inputs'>
            <Grid.Row columns={ 3 }>
                <Grid.Col>
                    <UIInput
                        type='text'
                        label='Название банка'
                        name='actives.bankDeposit.bankName'
                        value={ fields.bankName || '' }
                        onChange={ onChange }
                    />
                </Grid.Col>
                <Grid.Col>
                    <Dropdown
                        label='Валюта'
                        name='actives.bankDeposit.currency'
                        value={ fields.currency }
                        onChange={ onChange }
                        options={ Array.from(MCurrencyName).map(([ value, name ]) => ({
                            text: name,
                            value: value,
                        })) }
                    />
                </Grid.Col>
                <Grid.Col>
                    <Input
                        type='number'
                        label='Сумма вклада'
                        name='actives.bankDeposit.amount'
                        value={ fields.amount || '' }
                        onChange={ onChange }
                        postfix={ MCurrencyPrefix.get(fields.currency) }
                        renderValue={ (value) => formatPrice(value, false) }
                    />
                </Grid.Col>
            </Grid.Row>
        </Grid>
    </UIBlock>
);
