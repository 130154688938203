import React from 'react';
import { UIButton, Modal } from 'finbox-ui-kit';

type TNoOrdersPopupProps = {
    open: boolean,
    onClose: () => void,
}
export const NoOrdersPopup: React.FC<TNoOrdersPopupProps> = ({ open, onClose }) => (
    <Modal open={open} onClose={onClose} size='small'>
        <Modal.Header>Нет подходящих заявок</Modal.Header>
        <Modal.Content>
            Для создания документа требуется минимум одна заявка кредитору с одним из статусов:<br/><br/>
            <b>&laquo;Положительное решение&raquo;</b><br/>
            <b>&laquo;Клиент думает над условиями&raquo;</b><br/>
            <b>&laquo;Договор комиссии на подписи&raquo;</b><br/>
            <b>&laquo;Регистрация сделки&raquo;</b><br/>

        </Modal.Content>
        <Modal.Actions className='text-left'>
            <UIButton
                content='Закрыть'
                onClick={onClose}
            />
        </Modal.Actions>
    </Modal>
);
