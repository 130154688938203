import { DropdownControlled } from '@/common/ui/dropdown/dropdown-controlled';
import { TDropdownComplex } from '@/common/ui/dropdown/dropdown.types';
import { Dropdown } from './dropdown';


(Dropdown as TDropdownComplex).Controlled = DropdownControlled;
const DropdownComplex: TDropdownComplex = Dropdown as TDropdownComplex;

export {
    DropdownComplex as Dropdown,
    DropdownControlled,
};