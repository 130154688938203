import { flip } from './flip';
import { TPositionCalculationResult, TUIPopupPosition } from './popup';

export function calculateFixedPosition(
    position: TUIPopupPosition,
    targetRect: DOMRect,
    popupRect: DOMRect,
    offset = 0,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    redirect = false,
): TPositionCalculationResult {
    const left = targetRect.left - popupRect.width;
    const top = targetRect.top - popupRect.height;
    const bottom = targetRect.top + targetRect.height + popupRect.height;

    const positionCalculations: Record<TUIPopupPosition, () => TPositionCalculationResult> = {
        'bottom left': () => {
            const right = targetRect.left + popupRect.width;
            const initialPos = 'bottom left';
            let posY = 'bottom';
            let posX = 'left';

            if (right > window.innerWidth && flip.isCanFlipLeft(targetRect.left, popupRect.width)) {
                posX = 'right';
            }

            if (bottom > window.innerHeight && flip.isCanFlipTop(targetRect.top + targetRect.height, popupRect.height)) {
                posY = 'top';
            }
            if (`${posY} ${posX}` !== initialPos) {
                return calculateFixedPosition(`${posY} ${posX}` as TUIPopupPosition, targetRect, popupRect, offset)
            }

            return [
                targetRect.bottom + offset,
                targetRect.left,
                'auto',
                initialPos,
            ];
        },
        'bottom right': () => {
            const initialPos = 'bottom right';
            let posY = 'bottom';
            let posX = 'right';

            if (targetRect.left + popupRect.width > window.innerWidth && flip.isCanFlipRight(targetRect.left, popupRect.width)) {
                posX = 'left';
            }

            if (bottom > window.innerHeight && flip.isCanFlipTop(targetRect.top, popupRect.height)) {
                posY = 'top';
            }
            if (`${posY} ${posX}` !== initialPos) {
                return calculateFixedPosition(`${posY} ${posX}` as TUIPopupPosition, targetRect, popupRect, offset)
            }
            return [
                targetRect.bottom + offset,
                targetRect.left - popupRect.width + targetRect.width,
                'auto',
                initialPos,
            ];
        },
        'bottom center': () => {
            const right = targetRect.left + popupRect.width;
            const initialPos = 'bottom center';
            let posY = 'bottom';
            let posX = 'center';

            if (targetRect.left - popupRect.width + targetRect.width < 0 && flip.isCanFlipRight(targetRect.left, popupRect.width)) {
                posX = 'left';
            }
            if (right > window.innerWidth && flip.isCanFlipLeft(targetRect.left, popupRect.width)) {
                posX = 'right';
            }
            if (bottom > window.innerHeight && flip.isCanFlipTop(targetRect.top, popupRect.height)) {
                posY = 'top';
            }
            if (`${posY} ${posX}` !== initialPos) {
                return calculateFixedPosition(`${posY} ${posX}` as TUIPopupPosition, targetRect, popupRect, offset)
            }
            return [
                targetRect.bottom + offset,
                targetRect.left + (targetRect.width - popupRect.width) / 2,
                'auto',
                initialPos,
            ];
        },
        'top left': () => {
            const initialPos = 'top left';
            let posY = 'top';
            let posX = 'left';

            if (targetRect.left + popupRect.width > window.innerWidth && flip.isCanFlipLeft(targetRect.left, popupRect.width)) {
                posX = 'right';
            }

            if (top < 0 && flip.isCanFlipBottom(targetRect.top + targetRect.height, popupRect.height)) {
                posY = 'bottom';
            }
            if (`${posY} ${posX}` !== initialPos) {
                return calculateFixedPosition(`${posY} ${posX}` as TUIPopupPosition, targetRect, popupRect, offset)
            }

            return [
                targetRect.top - popupRect.height - offset,
                targetRect.left,
                'auto',
                initialPos,
            ];
        },
        'top right': () => {
            const initialPos = 'top left';
            let posY = 'top';
            let posX = 'left';

            if (left < 0 && flip.isCanFlipRight(targetRect.left, popupRect.width)) {
                posX = 'right';
            }
            if (top < 0 && flip.isCanFlipBottom(targetRect.top + targetRect.height, popupRect.height)) {
                posY = 'bottom';
            }
            if (`${posY} ${posX}` !== initialPos) {
                return calculateFixedPosition(`${posY} ${posX}` as TUIPopupPosition, targetRect, popupRect, offset)
            }
            return [
                targetRect.top - popupRect.height - offset,
                targetRect.left - popupRect.width + targetRect.width,
                'auto',
                initialPos,
            ];
        },
        'top center': () => {
            const right = targetRect.left + popupRect.width;
            const initialPos = 'top center';
            let posY = 'top';
            let posX = 'center';

            if (targetRect.left - popupRect.width + targetRect.width < 0 && flip.isCanFlipRight(targetRect.left, popupRect.width)) {
                posX = 'left';
            }
            if (right > window.innerWidth && flip.isCanFlipLeft(targetRect.left, popupRect.width)) {
                posX = 'right';
            }
            if (top < 0 && flip.isCanFlipBottom(targetRect.top + targetRect.height, popupRect.height)) {
                posY = 'bottom';
            }
            if (`${posY} ${posX}` !== initialPos) {
                return calculateFixedPosition(`${posY} ${posX}` as TUIPopupPosition, targetRect, popupRect, offset)
            }
            return [
                targetRect.top - popupRect.height - offset,
                targetRect.left + (targetRect.width - popupRect.width) / 2,
                'auto',
                initialPos,
            ];

            if (top < 0 && flip.isCanFlipBottom(targetRect.top + targetRect.height, popupRect.height)) {
                return calculateFixedPosition('bottom center', targetRect, popupRect, offset, true);
            }
            return [
                targetRect.top - popupRect.height - offset,
                targetRect.left + (targetRect.width - popupRect.width) / 2,
                'auto',
                'top center',
            ];
        },
        'right top': () => {
            const initialPos = 'right top';
            let posY = 'top';
            let posX = 'right';

            if (targetRect.left + targetRect.width + popupRect.width > window.innerWidth && flip.isCanFlipLeft(targetRect.left, popupRect.width)) {
                posX = 'left';
            }
            if (targetRect.top + popupRect.height > window.innerHeight && flip.isCanFlipBottom(targetRect.top, popupRect.height)) {
                posY = 'bottom';
            }
            if (`${posX} ${posY}` !== initialPos) {
                return calculateFixedPosition(`${posX} ${posY}` as TUIPopupPosition, targetRect, popupRect, offset)
            }
            return [
                targetRect.top,
                targetRect.left + targetRect.width + offset,
                'auto',
                initialPos,
            ];
        },
        'right bottom': () => {
            const right = targetRect.right + popupRect.width;
            if (top < 0) {
                return calculateFixedPosition(right > window.innerWidth ? 'left top' : 'right top', targetRect, popupRect, offset);
            }
            if (right > window.innerWidth) {
                return calculateFixedPosition('left bottom', targetRect, popupRect, offset);
            }
            return [ -popupRect.height + targetRect.height, targetRect.width + offset, 'auto', 'right bottom' ];
        },
        'left top': () => {
            const initialPos = 'left top';
            let posY = 'top';
            let posX = 'left';

            if (targetRect.left - popupRect.width < 0 && flip.isCanFlipRight(targetRect.left + targetRect.width, popupRect.width)) {
                posX = 'right';
            }
            if (targetRect.top + popupRect.height > window.innerHeight && flip.isCanFlipTop(targetRect.bottom, popupRect.height)) {
                posY = 'bottom';
            }
            if (`${posX} ${posY}` !== initialPos) {
                return calculateFixedPosition(`${posX} ${posY}` as TUIPopupPosition, targetRect, popupRect, offset)
            }
            return [
                targetRect.top,
                targetRect.left - popupRect.width - offset,
                'auto',
                initialPos,
            ];
        },
        'left bottom': () => {
            if (top < 0) {
                return calculateFixedPosition(left < 0 ? 'right top' : 'left top', targetRect, popupRect, offset);
            }
            if (left < 0) {
                return calculateFixedPosition('right bottom', targetRect, popupRect, offset);
            }
            return [ -popupRect.height + targetRect.height, -popupRect.width - offset, 'auto', 'left bottom' ];
        },
    };

    if (position in positionCalculations) {
        return positionCalculations[position]();
    }

    return positionCalculations['bottom left']();
}