import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useDownloadFile } from '@/utils/hooks/use-download-file';
import { API_ROSREESTR_REPORTS } from '@/components/rosreestr-reports/_consts';
import { useApiClient } from '@/libs/api-client/use-api-client';


type TUseCadastralReportsReturn = {
    download: (reportId: number, cadastralNumber: string) => Promise<void>;
    isLoadingDownload: boolean;

    preview: (reportId: number, cadastralNumber: string) => Promise<void>;
    setProcessed: (reportId: number, cadastralNumber: string, processed: boolean) => Promise<void>;
    isLoading: boolean;
}

export const useCadastralNumberReport = (): TUseCadastralReportsReturn => {
    const { download: downloadFile, isLoading: isLoadingDownload } = useDownloadFile();
    const { fetch, loading: isLoading } = useApiClient({
        url: API_ROSREESTR_REPORTS,
    });

    const download = useCallback(async (reportId: number, cadastralNumber: string) => {
        await downloadFile(
            `${ API_ROSREESTR_REPORTS }${ cadastralNumber }/${ reportId }/download`,
            false,
            false,
            false,
        );
    }, [ downloadFile ]);

    const preview = useCallback(async (reportId: number, cadastralNumber: string) => {
        await downloadFile(
            `${ API_ROSREESTR_REPORTS }${ cadastralNumber }/${ reportId }/preview`,
            true,
            false,
            false,
        );
    }, [ downloadFile ]);

    const setProcessed = useCallback(async (reportId: number, cadastralNumber: string, processed: boolean) => {
        await fetch(null, {
            url: `${ API_ROSREESTR_REPORTS }${ cadastralNumber }/${ reportId }/processed`,
            method: processed ? 'post' : 'delete',
        });
        toast.success('Успешно');
    }, [ fetch ]);

    return {
        download,
        isLoadingDownload,
        preview,
        setProcessed,
        isLoading,
    };
};
