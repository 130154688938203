import React, { useCallback, useEffect } from 'react';
import { UIButton, UILoadingOverlay, Modal } from 'finbox-ui-kit';
import { useModal } from 'finbox-ui-kit/utils/hooks';
import { CadastralNumberDetail } from '@/common/cadastral-number/cadastral-number-detail';
import { formatAddress } from '@/utils';
import { List } from '@/common/list';


export const CadastralNumberRequestList = ({ address, data, onClick, onClose, loading = false, clientId = null }) => {
    const { show, hide, props } = useModal({ onClose });
    const { show: showDetail, props: propsDetail } = useModal<{cadastralNumber: string}>();

    useEffect(() => {
        if (data && data.count > 1) {
            show();
        }
    }, [ show, data ]);
    const cancelClickHandler = useCallback(() => {
        hide();
        setTimeout(() => {
            onClose();
        }, 200);
    }, [ hide, onClose ]);

    const itemClickHandler = useCallback((cn: string) => () => {
        hide();
        setTimeout(() => {
            onClick(cn);
        }, 200);
    }, [ hide, onClick ]);

    const showDetailClickHandler = useCallback((cadastralNumber: string) => () => {
        showDetail({ cadastralNumber });
    }, [ showDetail ]);

    return (
        <>
            <Modal { ...props } scrollable showCloseButton>
                <Modal.Header>
                    Найдено несколько записей ЕГРН <br/>
                    <small className='color-grayDarken'>{ formatAddress(address) }</small>
                </Modal.Header>
                <Modal.Content className='relative'>
                    <UILoadingOverlay active={ loading }/>
                    <List>
                        { (data?.data || []).map((item, index) => (
                            <List.Item
                                key={ index }
                                className='cursor-pointer'
                            >
                                <UIButton
                                    className='float-right'
                                    icon='magnifying-glass'
                                    size='small'
                                    onClick={ showDetailClickHandler(item.cadnum) }
                                />
                                <div onClick={ itemClickHandler(item.cadnum) }>
                                    <div className='fz11 color-grayDarken'>
                                        { item.full_name }
                                    </div>
                                    { item.cadnum }
                                </div>
                            </List.Item>
                        )) }
                    </List>
                </Modal.Content>
                <Modal.Actions>
                    <UIButton
                        content='Отмена'
                        onClick={ cancelClickHandler }
                        disabled={ loading }
                    />
                </Modal.Actions>
            </Modal>
            <CadastralNumberDetail
                {...propsDetail}
                clientId={ clientId }
            />
        </>
    );
};
