export enum ERequisitesVariant {
    LEGAL = 'legal',
    TBANK = 'tbank',
    MTS = 'mts',
    INDIVIDUAL = 'individual',
}

type TOrganizationRequisites = {
    name: string;
    inn: string;
    ogrn: string;
    kpp?: string;
    account: string;
    corAccount: string;
    bankName: string;
    bankBIK: string;
    bankINN: string;
    bankKPP: string;
    address: string;
}

export const requisites = new Map<ERequisitesVariant, TOrganizationRequisites>([
    [ ERequisitesVariant.LEGAL, {
        name: 'ООО "Фонд Финансирования"',
        inn: '2308254964',
        kpp: '230801001',
        ogrn: '1182375024952',
        account: '40701810430000004389',
        corAccount: '30101810100000000602',
        bankName: 'КРАСНОДАРСКОЕ ОТДЕЛЕНИЕ N8619 ПАО СБЕРБАНК',
        bankBIK: '040349602',
        bankINN: '7707083893',
        bankKPP: '231043001',
        address: '350063, Краснодарский край, г. Краснодар, ул. Кубанская Набережная, д. 37/2, офис 46',
    } ],
    [ ERequisitesVariant.TBANK, {
        name: 'ООО "Фонд Финансирования"',
        inn: '2308254964',
        kpp: '230801001',
        ogrn: '1182375024952',
        account: '40702810010001648745',
        corAccount: '30101810145250000974',
        bankName: 'АО «ТБанк»',
        bankBIK: '044525974',
        bankINN: '7710140679',
        bankKPP: '771301001',
        address: '350063, Краснодарский край, г. Краснодар, ул. Кубанская Набережная, д. 37/2, офис 46',
    } ],
    [ ERequisitesVariant.MTS, {
        name: 'ИП Фельк Руслан Вячеславович',
        inn: '230501749701',
        ogrn: '319237500398451',
        account: '40802810000002064512',
        corAccount: '30101810600000000232',
        bankName: 'ПАО "МТС-БАНК"',
        bankBIK: '044525232',
        bankINN: '7702045051',
        bankKPP: '772501001',
        address: '350063, Краснодарский край, г. Краснодар, ул. Кубанская Набережная, д. 37/2, офис 42',
    } ],
    [ ERequisitesVariant.INDIVIDUAL, {
        name: 'Индивидуальный предприниматель Фельк Руслан Вячеславович',
        inn: '230501749701',
        ogrn: '319237500398451',
        account: '40802810603300000265',
        corAccount: '30101810145250000411',
        bankName: 'ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ" БАНКА ВТБ (ПАО)',
        bankBIK: '044525411',
        bankINN: '7702070139',
        bankKPP: '770943002',
        address: '350063, Краснодарский край, г. Краснодар, ул. Кубанская Набережная, д. 37/2, офис 42',
    } ],
]);
