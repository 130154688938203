import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { BroadcastChannel } from 'broadcast-channel';
import { API_URLS, SOCKET_EVENT } from '@/consts';
import { TNotification, TNotificationId } from '@/common/user-notifications/notifications.types';
import { useSocketsContext } from '@/libs/sockets/sockets.context';
import { useApiClient } from '@/libs/api-client/use-api-client';

const infoSound = new Audio('/assets/sounds/mixkit-correct-answer-tone-2870.wav');
const warningSound = new Audio('/assets/sounds/warning.mp3');
const bc = new BroadcastChannel('notifications');


const soundsMap = {
    'warning': warningSound,
}

interface INotificationsContext {
    notifications: TNotification[];
    count: number;
    removeNotification: (id: TNotificationId) => Promise<void>;
    clearNotifications: () => Promise<void>;
    handleClickNotification: (notification: TNotification) => Promise<void>;
    isLoading: boolean;
    isLoadingRemove: TNotificationId;
    isLoadingClear: boolean;
}


const NotificationsContext = React.createContext<INotificationsContext>({
    notifications: [],
    count: 0,
    removeNotification: () => null,
    clearNotifications: () => null,
    handleClickNotification: () => null,
    isLoading: false,
    isLoadingRemove: null,
    isLoadingClear: false,
});

export function useNotificationsContext() {
    return React.useContext(NotificationsContext);
}

export const NotificationsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [ notifications, setNotifications ] = React.useState<TNotification[]>([]);
    const [ count, setCount ] = React.useState(0);
    const [ isLoadingRemove, setIsLoadingRemove ] = React.useState(null);
    const { subscribe, unsubscribe } = useSocketsContext();
    const navigate = useNavigate();

    const { data, loading: isLoading } = useApiClient({
        url: API_URLS.NOTIFICATIONS,
        initialFetch: true,
    });

    const { fetch: fetchRemove } = useApiClient({
        method: 'delete',
        url: API_URLS.NOTIFICATIONS,
    });

    const { fetch: fetchClear, loading: isLoadingClear } = useApiClient({
        method: 'delete',
        url: API_URLS.NOTIFICATIONS,
    });

    useEffect(() => {
        if (data) {
            setNotifications(data.data);
            setCount(data.count);
        }
    }, [ data ]);

    const removeNotification = useCallback(async function removeNotification(id: TNotificationId) {
        setIsLoadingRemove(id);
        try {
            await fetchRemove(null, {
                url: `${ API_URLS.NOTIFICATIONS }${ id }/`,
            });
        } finally {
            setIsLoadingRemove(null);
        }

        setNotifications((current) => current.filter((notification) => notification.id !== id));
        setCount((prev) => prev - 1);

        await bc.postMessage({
            type: 'NOTIFICATION_REMOVED',
            notificationId: id,
        });
    }, [ fetchRemove ]);

    async function clearNotifications() {
        await fetchClear();
        setNotifications([]);
        setCount(0);
        await bc.postMessage({
            type: 'NOTIFICATIONS_CLEARED',
        });
    }

    const handleClickNotification = useCallback(async (notification: TNotification) => {
        if (!notification.link) {
            return;
        }
        if (notification.target === 'blank') {
            window.open(notification.link);
        } else {
            navigate(notification.link);
        }
        await removeNotification(notification.id);
    }, [ navigate, removeNotification ]);

    const handlerNewNotification = useCallback(async (data: TNotification) => {
        setNotifications((current) => [
            data,
            ...current,
        ]);
        setCount((current) => current + 1);

        toast((
            <div>
                <b>{ data.title }</b> <br/>
                { data.body }
            </div>
        ), {
            type: data.level,
            onClick: () => handleClickNotification(data),
            closeOnClick: !!data.link,
        });
        if (data.playSound) {
            await (soundsMap[data.level] || infoSound).play();
        }
    }, [ handleClickNotification ]);

    useEffect(() => {
        subscribe(SOCKET_EVENT.NOTIFICATION, handlerNewNotification);
        return () => {
            unsubscribe(SOCKET_EVENT.NOTIFICATION, handlerNewNotification);
        };
    }, [ handlerNewNotification, subscribe, unsubscribe ]);

    const handlerBCEvent = useCallback(({ type, notificationId }) => {
        if (type === 'NOTIFICATIONS_CLEARED') {
            setNotifications([]);
            setCount(0);
            return;
        }

        setNotifications((current) => current.filter((i) => i.id !== notificationId));
        setCount((current) => current - 1);
    }, []);

    useEffect(() => {
        // TODO: переделать но сокеты в связке с беком
        bc.addEventListener("message", handlerBCEvent);
        return () => {
            bc.removeEventListener("message", handlerBCEvent);
        };
    }, [ handlerBCEvent ]);

    return (
        <NotificationsContext.Provider value={ {
            notifications,
            count,
            removeNotification,
            clearNotifications,
            isLoading,
            isLoadingRemove,
            isLoadingClear,
            handleClickNotification,
        } }>
            { children }
        </NotificationsContext.Provider>
    );
};
