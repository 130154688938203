import yup from '@/utils/yup-config';

export const employeeScheme = yup.object({
    startDate: yup.string().required(),
    seniority: yup.number().required(),
    position: yup.string().nullable().test(
        'test-employee-position',
        'Некорректно заполнено поле',
        (value, { from }) => {
            const [ , workAndEducationValues ] = from;
            if (![
                null,
                undefined,
                'Самозанятый',
                'Пенсионер',
                'Нетрудоустроенный',
                'Неофициальный бизнес',
            ].includes(workAndEducationValues.value.employmentType)) {
                return !!value;
            }
            return true;
        },
    ),
});