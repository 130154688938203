import React, { ChangeEventHandler, useRef } from 'react';
import cn from 'classnames';
import { UIIcon, UIInfo } from 'finbox-ui-kit';
import { TUIEventHandler } from 'finbox-ui-kit/components/input/input';
import { getRefTarget, MakeUISyntheticChangeEvent } from 'finbox-ui-kit/utils';
import styles from './checkbox.module.scss';


type CheckboxProps = {
    label?: string
    id?: string
    name?: string
    error?: string
    className?: string
    checked?: boolean
    disabled?: boolean
    required?: boolean
    inline?: boolean
    value?: any
    onChange?: TUIEventHandler<any>
    data?: any
    info?: React.ReactNode
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(function UICheckbox({
    label,
    checked,
    value,
    className,
    name,
    id,
    onChange,
    disabled,
    error,
    inline,
    data,
    required,
    info,
}, ref) {
    const inputRef = useRef<HTMLInputElement>();
    const handlerChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        onChange(
            MakeUISyntheticChangeEvent(getRefTarget(inputRef, ref), value),
            {
                name,
                value,
                checked: e.target.checked,
                data,
            },
        )
    }

    return (
        <div className={ cn(styles.checkbox, className, {
            [styles.checkboxChecked]: checked,
            [styles.checkboxDisabled]: disabled,
            [styles.checkboxInline]: inline,
            [styles.checkboxNoLabel]: !label,
        }) }>
            <label htmlFor={ id || name }>
                <input
                    ref={ ref }
                    type='checkbox'
                    checked={ checked }
                    value={ value }
                    id={ id || name }
                    name={ name || id }
                    onChange={ handlerChange }
                    disabled={disabled}
                />
                <div className={ styles.checkboxInput }>
                    <UIIcon
                        name='check'
                        color='black'
                        className={styles.checkboxCheckmark}
                    />
                </div>
                { label }
                { info && (
                    <UIInfo
                        className='ml-5'
                        content={ info }
                        showOnHover
                    />
                )}
                { required ? (<span className='color-red'>&nbsp;*</span>) : null }
            </label>
            { error && (
                <div className={styles.checkboxError}>{ error }</div>
            ) }
        </div>
    );
});
