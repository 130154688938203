import { css } from 'styled-components';

export const em = (size: number, baseFontSize = 16) => `${ size / baseFontSize }em`;
export const rem = (size: number, baseFontSize = 16) => `${ size / baseFontSize }rem`;


type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'max';
export const breakpoints: Record<Breakpoint, number> = {
    // Extra small screen / phone
    xs: 0,
    // Small screen / phone
    sm: 544,
    // Medium screen / tablet
    md: 768,
    // Large screen / desktop
    lg: 992,
    // Extra large screen / wide desktop
    xl: 1200,
    // Extra large screen / wide desktop
    xxl: 1366,
    // Extra large screen / wide desktop
    max: 1920,
};

export const mixinMediaBreakpoint = {
    down: (breakpoint: Breakpoint, content: any) => css`
      @media (max-width: ${ breakpoints[breakpoint] }px) {
        ${ content }
      }
    `,
    up: (breakpoint: Breakpoint, content: any) => css`
      @media (min-width: ${ breakpoints[breakpoint] }px) {
        ${ content }
      }
    `,
    between: (breakpointUp: Breakpoint, breakpointDown: Breakpoint, content: any) => css`
      @media (min-width: ${ breakpoints[breakpointUp] }px and ${ breakpoints[breakpointDown] }px) {
        ${ content }
      }
    `,
};
