import React from 'react';
import cn from 'classnames';
import { UIIcon } from 'finbox-ui-kit';
import styles from './style.module.scss';

type UIInfoIconProps = {
    className?: string
}
export const UIInfoIcon = React.forwardRef<any, UIInfoIconProps>(function UIInfoIcon({ className }, ref) {
    return (
        <UIIcon
            ref={ ref }
            className={ cn(styles.FUIInfoIcon, className) }
            name='info'
            size='small'
        />
    );
});