import React, { useEffect, useState } from 'react';
import { UIButton, UIIcon, Modal, UITextarea } from 'finbox-ui-kit';
import { TUIIconBrandName, TUIIconName } from 'finbox-ui-kit/components/icon/icon';
import { TUIColor } from 'finbox-ui-kit/types/common';
import { Scheme, SchemeScheme } from '@/utils/scheme';
import { formatNumber } from '@/utils';
import { CHANNEL_ICON } from '@/consts';
import { useDictionary } from '@/utils/hooks/use-dictionary';
import { Dropdown } from '@/common/ui/dropdown';

type TMessageDialogProps = {
    isOpen: boolean;
    number: string | number;
    icon?: TUIIconName | TUIIconBrandName;
    iconColor?: TUIColor;
    onClose: () => void;
    onSubmit: (text) => Promise<boolean>;
    text?: string;
}

const SCHEME: SchemeScheme = {
    text: {
        type: Scheme.SCHEME_RULE_TYPES.STRING,
        required: true,
    },
};


const MessageDialog = ({
    isOpen = false, number, onClose, icon = 'envelope', iconColor, onSubmit,
    text: initText,
}: TMessageDialogProps) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ text, setText ] = useState(initText || '');
    const [ template, setTemplate ] = useState(null);
    const [ errors, serErrors ] = useState(null);

    const [ templates ] = useDictionary('smsTemplates');
    useEffect(() => {
        if (isOpen) {
            setText(initText || '');
            serErrors(null);
            setTemplate(null);
        }
    }, [ isOpen, initText ]);

    const submitHandler = async () => {
        const { isValid, errors: validationErrors } = Scheme.validate(
            SCHEME,
            { text },
        );

        if (!isValid) {
            serErrors(validationErrors);
            return;
        }

        setIsLoading(true);
        try {
            await onSubmit(text);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            return;
        } finally {
            setIsLoading(false);
        }
        onClose();
    };

    const textChangeHandler = (e, { value }) => {
        setText(value);
        setTemplate(null);
    };

    const templateChangeHandler = (value: number) => {
        const tpl = templates.find((i) => i.id === value);
        setTemplate(value);
        setText(tpl.text);
    };

    return (
        <Modal open={ isOpen } onClose={ onClose } size='small' style={ { zIndex: 9999 } }>
            <Modal.Header>
                <UIIcon
                    name={ icon as TUIIconName }
                    color={ iconColor }
                    type={ icon !== CHANNEL_ICON.SMS ? 'brands' : 'regular' }
                    size='big'
                />&nbsp;
                Сообщение абоненту <span className='nowrap'>{ formatNumber(number) }</span>
            </Modal.Header>
            <Modal.Content>
                <form>
                    <Dropdown
                        className='mb1'
                        label='Шаблон'
                        name='template'
                        onChange={ templateChangeHandler }
                        value={ template }
                        options={ (templates || []).map((i, key) => ({
                            key,
                            value: i.id,
                            text: i.name,
                        })) }
                        clearable
                    />
                    <UITextarea
                        label='Текст'
                        name='text'
                        value={ text }
                        onChange={ textChangeHandler }
                        error={ errors?.text }
                        rows={ 15 }
                        noMargin
                    />
                </form>
            </Modal.Content>
            <Modal.Actions>
                <UIButton
                    content='Отправить'
                    icon='paper-plane'
                    onClick={ submitHandler }
                    disabled={ isLoading }
                    primary
                />
                <UIButton
                    content='Отмена'
                    disabled={ isLoading }
                    onClick={ onClose }
                />
            </Modal.Actions>
        </Modal>
    );
};

export {
    MessageDialog,
};
