import React, { InputHTMLAttributes } from 'react';


export const InputInput = React.forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(function InputInput(props, forwardRef) {
    return (
        <input
            ref={ forwardRef }
            { ...props }
        />
    );
})