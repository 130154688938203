import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useToggle } from 'finbox-ui-kit/utils/hooks';
import { TColor } from '@/types/common';
import { OptionsListOption } from '@/common/ui/options-list/options-list-option';
import styles from './options-list.module.scss';

const scrollToSelectedItem = () => {
    setTimeout(() => {
        document.querySelector('li[class*="options-list-option--selected"]')?.scrollIntoView({
            block: 'nearest',
            inline: 'nearest',
            behavior: 'smooth',
        });
    }, 50);
};

export type TOption<TData = any> = {
    text: React.ReactNode,
    value: any,
    data?: TData,
    picked?: boolean,
    selected?: boolean,
    disabled?: boolean,
    notSelectable?: boolean,
    color?: TColor,
    items?: TOption<TData>[]
}

type OptionsListProps = {
    active?: boolean;
    className?: string;
    filter?: string | number;
    filterFn?: (option: TOption) => boolean;
    options: TOption[]
    onSelect(option: TOption): void
    onEnter?: (option: TOption) => void;
    onOptionClick?: (option: TOption) => void;
    selectOnNavigation?: boolean
    sub?: boolean
    fluid?: boolean
    rtl?: boolean
};

export function OptionsList({
    className,
    active,
    options,
    onSelect,
    onEnter,
    onOptionClick,
    selectOnNavigation,
    sub,
    fluid,
    filter,
    filterFn,
    rtl,
}: OptionsListProps) {
    const [ selected, setSelected ] = useState(-1);
    const [ filteredOptions, setFilteredOptions ] = useState<TOption[]>(options);
    const { on: subOpen, toggle: toggleSubOpen } = useToggle();

    useEffect(() => {
        if (filter) {
            setFilteredOptions(
                options.filter((item) =>
                    filterFn
                        ? filterFn(item)
                        : String(item.value).startsWith(String(filter))),
            );
        } else {
            setFilteredOptions(options);
        }
    }, [ filter, filterFn, options ]);

    useEffect(() => {
        setSelected(0);
    }, [ options ]);

    const returnSelected = useCallback((index: number) => {
        const item = filteredOptions[index];
        if (item) {
            onSelect(item);
        }
    }, [ filteredOptions, onSelect ]);

    const handlerArrows = useCallback((e: KeyboardEvent) => {
        if (subOpen) {
            return;
        }
        switch (e.key) {
            case 'ArrowDown': {
                e.preventDefault();
                if (!filteredOptions.length) {
                    return;
                }
                const index = (selected + 1 >= filteredOptions.length) ? 0 : selected + 1;
                setSelected(index);
                if (selectOnNavigation) {
                    returnSelected(index);
                }
                scrollToSelectedItem();
            }
                break;
            case 'ArrowUp': {
                e.preventDefault();
                if (!filteredOptions.length) {
                    return;
                }
                const index = (selected - 1 < 0) ? filteredOptions.length - 1 : selected - 1;
                setSelected(index);
                if (selectOnNavigation) {
                    returnSelected(index);
                }
                scrollToSelectedItem();
            }
                break;
            default:
                return;
        }
    }, [ filteredOptions, returnSelected, selectOnNavigation, selected, subOpen ]);

    useEffect(() => {
        if (active) {
            window.addEventListener('keydown', handlerArrows);
            return () => {
                window.removeEventListener('keydown', handlerArrows);
            };
        }
        return () => null;
    }, [ active, handlerArrows ]);

    useEffect(() => {
        if (active) {
            setSelected(0);
        }
    }, [ active ]);

    const handlerSubOpen = useCallback((index: number) => {
        setSelected(index);
        toggleSubOpen(true);
    }, [ toggleSubOpen ]);

    const handlerSubClose = useCallback(() => {
        toggleSubOpen(false);
    }, [ toggleSubOpen ]);

    const handlerSelect = useCallback((option: TOption, index: number) => {
        setSelected(index);
        onSelect(option);
    }, [ onSelect ]);

    return (
        <ul
            role='listbox'
            className={ cn(styles.optionsList, className, {
                [styles.optionsListSub]: sub,
                [styles.optionsListFluid]: fluid,
                [styles.optionsListRtl]: rtl,
            }) }
        >
            { filteredOptions.map((option, index) => (
                <OptionsListOption
                    key={ index }
                    index={ index }
                    option={ option }
                    selected={ selected === index }
                    onSelect={ handlerSelect }
                    onClick={ onOptionClick }
                    onEnter={ onEnter }
                    sub={ !!sub }
                    onSubOpen={ handlerSubOpen }
                    onSubClose={ handlerSubClose }
                />
            )) }
        </ul>
    );
}