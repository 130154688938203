import { Joi, joiSchema } from '@/libs/joi';


export const passwordScheme = joiSchema((joi) => joi.object().keys({
    password: joi.string().min(8).regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/).required().options({
        messages: {
            'string.pattern.base': 'поле должно содержать цифры, строчные и заглавные буквы латинского алфавита',
        },
    }),
    rePassword: joi.any().valid(Joi.ref('password')).required().options({ messages: { 'any.only': 'Пароли не совпадают' } }),
}));
