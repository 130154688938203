import yup from '@/utils/yup-config';
import { addressScheme } from '@/components/leads/components/credit-from/schemas/address.scheme';
import { TPassport } from '@/components/leads/components/credit-from/types';
import { EClientAddressBasis } from '@/consts';

export const passportScheme = yup.object<TPassport>({
    birthday: yup.string().required(),
    birthplace: yup.string().required(),
    serialAndNumber: yup.string().required(),
    dateOfIssue: yup.string().required(),
    departmentCode: yup.string().required(),
    department: yup.string().required(),
    address: yup.mixed()
        .when('mixedAddress', {
            is: (value) => typeof value === 'string',
            then: () => yup.string().required(),
            otherwise: () => addressScheme.required(),
        }),
    registrationAddress: yup.mixed()
        .when('mixedAddress', {
            is: (value) => typeof value === 'string',
            then: () => yup.string().required(),
            otherwise: () => addressScheme.required(),
        }),
    registrationDate: yup.string().required(),
    addressEqualRegistration: yup.boolean().default(false),
    addressBasis: yup.string().oneOf(Object.values(EClientAddressBasis)).required(),
    snils: yup.string().snils(),
});