import React, { useRef, MouseEventHandler, useCallback } from 'react';
import cn from 'classnames';
import { UIIcon, UIPopup } from 'finbox-ui-kit';
import { useToggle } from 'finbox-ui-kit/utils/hooks';
import { TUIPopupPosition } from 'finbox-ui-kit/components/popup/popup';
import { TUIIconBrandName, TUIIconName, TUIIconType } from 'finbox-ui-kit/components/icon/icon';
import { TUIColor } from 'finbox-ui-kit/types/common';
import styles from './options-list.module.scss';

type TOptionValue = string | number | boolean;

export type TOption<Data = any, Value extends TOptionValue = TOptionValue> = {
    text: React.ReactNode
    value?: Value
    items?: TOption[]
    color?: TUIColor
    icon?: TUIIconName | TUIIconBrandName,
    iconType?: TUIIconType
    iconColor?: TUIColor
    disabled?: boolean
    data?: Data
};

export type TSelectHandler<Data = any, Value extends TOptionValue = TOptionValue> = (option: TOption<Data, Value>) => void;

type TOptionsListProps = {
    active?: boolean
    options: TOption[]
    onSelect: TSelectHandler
    itemsPosition?: TUIPopupPosition
    scrollable?: boolean
    maxHeight?: number
    popupStrategy?: 'fixed' | 'relative'
}

type TOptionProps<E extends React.ElementType = React.ElementType> = {
    as?: E
    option: TOption
    onClick: TSelectHandler
    itemsPosition?: TUIPopupPosition
    popupStrategy?: 'fixed' | 'relative'
}

function Option({
    as,
    option,
    onClick,
    itemsPosition,
    popupStrategy,
}: TOptionProps) {
    const ref = useRef<HTMLLIElement>();
    const { on: opened, toggle } = useToggle();

    const {
        text,
        items,
        icon,
        iconType,
        iconColor,
        color,
        disabled,
    } = option;

    const OptionAs = as || 'span';

    const handlerClick: MouseEventHandler<HTMLLIElement> = () => {
        if (disabled) {
            return;
        }
        if (items) {
            toggle();
        } else {
            toggle(false);
            onClick(option);
        }
    };

    const handlerClickChildren = (option: TOption) => {
        toggle(false);
        onClick(option);
    };

    const handlerClickOutside = useCallback(() => {
        toggle(false);
    }, [ toggle ]);

    return (
        <li
            ref={ ref }
            className={ cn(styles.FUIOptionsListItem, {
                [styles.Opened]: opened,
                [styles.Disabled]: disabled,
                [`-${color}`]: color,
            }) }
        >
            <OptionAs
                className={ styles.FUIOptionsListItemInner }
                onClick={ handlerClick }
            >
                { icon && (
                    <UIIcon className={ styles.FUIOptionsListItemIcon } name={ icon as any } type={ iconType } color={ iconColor }/>
                ) }
                <span className={ styles.FUIOptionsListItemText }>
                    { text }
                </span>
                { items && (
                    <UIIcon className={ styles.FUIOptionsListItemCaret } name='chevron-right' type='light' size='small'/>
                ) }
            </OptionAs>
            { items && (
                <UIPopup
                    targetRef={ ref }
                    open={ opened }
                    onClickOutside={ handlerClickOutside }
                    position={ itemsPosition }
                    strategy={ popupStrategy }
                    portal='root'
                    minWidth={ 0 }
                >
                    <ul className={ styles.FUIOptionsList }>
                        { items.map((option, index) => (
                            <Option
                                key={ index }
                                option={ option }
                                onClick={ handlerClickChildren }
                                itemsPosition={ itemsPosition }
                            />
                        )) }
                    </ul>
                </UIPopup>
            ) }
        </li>
    );
}

export function OptionsList({
    // active = true,
    options,
    onSelect,
    itemsPosition = 'right top',
    popupStrategy,
    scrollable,
    maxHeight = 300,
}: TOptionsListProps) {
    return (
        <ul className={ cn(styles.FUIOptionsList, {
            [styles.Scrollable]: scrollable,
        }) } style={ { maxHeight } }>
            { options.map((option, index) => (
                <Option
                    key={ index }
                    option={ option }
                    onClick={ onSelect }
                    itemsPosition={ itemsPosition }
                    popupStrategy={ popupStrategy }
                />
            )) }
        </ul>
    );
}