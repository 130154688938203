import { TTextareaComplex } from '@/common/ui/textarea/textarea.types';
import { Textarea } from './textarea';
import { TextareaControlled } from './textarea-controlled';


(Textarea as TTextareaComplex).Controlled = TextareaControlled;
const TextareaComplex: TTextareaComplex = Textarea as TTextareaComplex;

export {
    TextareaComplex as Textarea,
    TextareaControlled,
};