import React from 'react';
import cn from 'classnames';

import './style.scss';
import { TUIColor, TUISize } from 'finbox-ui-kit/types/common';


type TUIDividerProps = {
    content?: string;
    className?: string;
    children?: React.ReactNode
    size?: TUISize
    color?: TUIColor
}

export const UIDivider: React.FC<TUIDividerProps> = ({ content, children, className, size, color }) => (
    <div className={ cn(
        'FUI-divider',
        className,
        {
            '-no-content': !(content || children),
            [`-${size}`]: size,
            [`-${color}`]: color,
        },
    ) }>
        { content || children }
    </div>
);

