import React, { useCallback } from "react";

import cn from 'classnames';
import { UIIcon } from 'finbox-ui-kit';
import styles from './tags.module.scss';


type TUITagProps = {
    className?: string,
    content?: React.ReactNode,
    onRemove?: (value: any) => void,
    value: any,
    disabled?: boolean,
    children?: React.ReactNode
}
export function UITag({
    content,
    onRemove,
    children,
    value,
    disabled,
    className,
}: TUITagProps) {
    const handlerClickRemove = useCallback(() => {
        if (!disabled) {
            onRemove(value);
        }
    }, [ value, onRemove, disabled ]);
    return (
        <div className={cn(styles.FUITag, className, { [styles.FUITagDisabled]: disabled })}>
            { children || content }
            {onRemove && (
                <span className={styles.FUITagRemove} onClick={ handlerClickRemove }>
                    <UIIcon
                        name='xmark'
                    />
                </span>
            )}
        </div>
    );
}

type UITagsGroupProps = {
    className?: string
    children: React.ReactNode
}
function UITagsGroup({ children, className }: UITagsGroupProps) {
    return (
        <div className={cn(styles.FUITags, className)}>
            { children }
        </div>
    )
}

UITag.Group = UITagsGroup;