import React from 'react';
import cn from 'classnames';
import { TUIColor, TUISize } from 'finbox-ui-kit/types/common';
import styles from './progress.module.scss';

type ProgressProps = {
    percent: number;
    className?: string;
    color?: TUIColor;
    size?: TUISize;
    indicating?: boolean;
};

export function Progress({ percent, color, className, size, indicating }: ProgressProps) {
    return (
        <div className={ cn(styles.progress, {
            [`-${ color }`]: color,
            [`-${ size }`]: size,
            [styles.progressIndicating]: indicating,
        }, className) }>
            <div className={ styles.progressBar } style={ { '--percents': `${ Math.min(percent, 100) }%` } as any }/>
        </div>
    );
}