import { API_BASE_URL } from '@/consts';

export const API_ROSREESTR_REPORTS = `${API_BASE_URL}/rosreestr/reports/`;
export const API_ROSREESTR_INN_BLACKLIST = `${API_BASE_URL}/rosreestr/reports/inn-blacklist`;
export const API_ROSREESTR_REPORTS_COUNTERS = `${API_BASE_URL}/rosreestr/reports/counters`;
export const API_ROSREESTR_REPORTS_DOWNLOAD = `${API_BASE_URL}/rosreestr/reports/download`;
export const API_ROSREESTR_SEND_TO_BOT = `${API_BASE_URL}/rosreestr/send-to-bot`;
export const API_ROSREESTR_REQUEST_CONTACTS = `${API_BASE_URL}/rosreestr/request-contacts`;
export const API_ROSREESTR_REJECT_CONTACTS = `${API_BASE_URL}/rosreestr/reject-contacts`;
export const API_ROSREESTR_INCORRECT_CONTACTS = `${API_BASE_URL}/rosreestr/incorrect-contacts`;
export const API_ROSREESTR_FINISH = `${API_BASE_URL}/rosreestr/finish`;
export const API_ROSREESTR_RESEND = `${API_BASE_URL}/rosreestr/resend`;
export const API_ROSREESTR_CANCEL = `${API_BASE_URL}/rosreestr/cancel`;