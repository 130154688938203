import { useEffect } from 'react';

type EventHandler<E extends keyof DocumentEventMap> = (event: DocumentEventMap[E]) => void;

export function useEventListener<
    E extends keyof DocumentEventMap,
>(eventName: E, handler: EventHandler<E>) {
    useEffect(() => {
        window.addEventListener(eventName, handler);
        return () => {
            window.removeEventListener(eventName, handler);
        }
    }, [ eventName, handler ]);
}