/* eslint-disable no-console */
import React, { ReactNode, useCallback, useEffect } from 'react';
import { socket } from '@/libs/socket';
import { getJwtToken } from '@/libs/jwt-token';

const debug = false;

type TSocketsContext = {
    connect(): void;
    disconnect(): void;
    subscribe(event: string, callback: (data: any) => void): void;
    unsubscribe(event: string, callback: (data: any) => void): void;
};


const SocketsContext = React.createContext<TSocketsContext>({} as TSocketsContext);

export function useSocketsContext() {
    return React.useContext(SocketsContext);
}

type TSocketsProps = {
    children?: ReactNode
};
export const SocketsProvider = ({ children }: TSocketsProps) => {
    useEffect(() => {
        if (debug) { console.log('[Sockets] INIT SOCKETS'); }
        socket.auth = async (cb) => {
            const token = getJwtToken();
            cb({ token });
        };

        socket.on('connect', () => {
            if (debug) { console.log('[Sockets] connected'); }
        });

        socket.on('connect_error', (err) => {
            console.log('[Sockets] connect_error');
            console.log(err);
        });

        socket.on('disconnect', (err) => {
            console.log('[Sockets] disconnected');
            console.error(err);
        });

        return () => {
            socket.disconnect();
        };
    }, []);


    const connect = useCallback(() => {
        if (debug) { console.log('[Sockets] connect'); }
        socket.connect();
    }, []);

    const disconnect = useCallback(() => {
        socket.disconnect();
    }, []);

    const subscribe = useCallback((event: string, callback: (data: any) => void) => {
        if (debug) { console.log('[Sockets] subscribe', event); }
        socket.on(event, callback);
    }, []);

    const unsubscribe = useCallback((event: string, callback: (data: any) => void) => {
        if (debug) { console.log('[Sockets] unsubscribe', event); }
        socket.off(event, callback);
    }, []);

    return (
        <SocketsContext.Provider value={ {
            connect,
            disconnect,
            subscribe,
            unsubscribe,
        } }>
            { children }
        </SocketsContext.Provider>
    );
};
