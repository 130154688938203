import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { UIIcon } from 'finbox-ui-kit';
import { TUIModalComponent, UIModalActionsProps, UIModalContentProps, UIModalHeaderProps } from 'finbox-ui-kit/components/modal/modal';
import styles from './modal.module.scss';

export const UIModal: TUIModalComponent = ({
    children,
    open,
    onClose,
    className,
    size,
    scrollable,
    showCloseButton,
    closeOnDimmerClick = true,
    index = 0,
    style = {},
}) => {
    const [ showed, setShowed ] = useState(false);
    const [ visible, setVisible ] = useState(false);

    useEffect(() => {
        if (open) {
            setShowed(true);
            setTimeout(() => setVisible(true), 50);
        } else {
            setVisible(false);
            setTimeout(() => setShowed(false), 200);
        }
    }, [ open ]);

    const handlerClickClose = () => {
        if (onClose) {
            onClose();
        }
    };
    if (!showed) {
        return null;
    }
    return ReactDOM.createPortal((
        <div
            className={ cn(styles.FUIModal, {
                [styles.FUIModalOpen]: visible,
                [styles.FUIModalScrollable]: scrollable,
                [`-${ size }`]: size,
            }) }
            style={ { zIndex: 9900 + index, ...style } }
        >
            <div className={ styles.FUIModalOverlay } onClick={ closeOnDimmerClick ? handlerClickClose : undefined }/>
            <div className={ cn(styles.FUIModalContentWrapper, className) }>
                { showCloseButton && (
                    <button type='button' className={ styles.FUIModalCloseButton } onClick={ handlerClickClose }>
                        <UIIcon name='xmark' color='white' size='big'/>
                    </button>
                ) }
                { children }
            </div>
        </div>
    ), document.getElementById('overlay-modal-portal'));
};

const UIModalHeader = ({ children, className }: UIModalHeaderProps) => (
    <div className={ cn(styles.FUIModalHeader, className) }>
        { children }
    </div>
);

const UIModalContent = ({ children, className, style = {} }: UIModalContentProps) => (
    <div className={ cn(styles.FUIModalContent, className) } style={ style }>{ children }</div>
);

const UIModalActions = ({ children, className }: UIModalActionsProps) => (
    <div className={ cn(styles.FUIModalActions, className) }>{ children }</div>
);

UIModal.Header = UIModalHeader;
UIModal.Content = UIModalContent;
UIModal.Actions = UIModalActions;