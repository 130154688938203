import { flip } from './flip';
import { TPositionCalculationResult, TUIPopupPosition } from './popup';

export function calculateRelativePosition(
    position: TUIPopupPosition,
    targetRect: DOMRect,
    popupRect: DOMRect,
    offset = 0,
    redirect = false,
): TPositionCalculationResult {
    const left = targetRect.left - popupRect.width;
    const top = targetRect.top - popupRect.height;
    const bottom = targetRect.top + targetRect.height + popupRect.height;

    const positionCalculations: Record<TUIPopupPosition, () => TPositionCalculationResult> = {
        'bottom left': () => {
            const right = targetRect.left + popupRect.width;
            const initialPos = 'bottom left';
            let posY = 'bottom';
            let posX = 'left';

            if (right > window.innerWidth) {
                posX = flip.isCanFlipLeft(targetRect.left, popupRect.width) ? 'right' : 'center';
            }

            if (bottom > window.innerHeight && flip.isCanFlipTop(targetRect.top, popupRect.height)) {
                posY = 'top';
            }
            const calcPos = `${posY} ${posX}` as TUIPopupPosition;
            if (calcPos !== initialPos) {
                return calculateRelativePosition(calcPos, targetRect, popupRect)
            }
            return [ targetRect.height + offset, 0, 'auto', initialPos ];
        },
        'bottom right': () => {
            const initialPos = 'bottom right';
            const right = targetRect.left + popupRect.width;
            let posY = 'bottom';
            let posX = 'right';

            if (right > window.innerWidth && flip.isCanFlipRight(targetRect.left, popupRect.width)) {
                posX = 'left';
            }

            if (bottom > window.innerHeight && flip.isCanFlipTop(targetRect.top, popupRect.height)) {
                posY = 'top';
            }
            const calcPos = `${posY} ${posX}` as TUIPopupPosition;
            if (calcPos !== initialPos) {
                return calculateRelativePosition(calcPos as TUIPopupPosition, targetRect, popupRect)
            }
            return [ targetRect.height + offset, 'auto', 0, initialPos ];
        },
        'bottom center': () => {
            if (bottom > window.innerHeight && !redirect) {
                return calculateRelativePosition('top center', targetRect, popupRect, offset, true);
            }
            return [ targetRect.height + offset, (targetRect.width - popupRect.width) / 2, 'auto', 'bottom center' ];
        },
        'top left': () => {
            const right = targetRect.left + popupRect.width;
            if (right > window.innerWidth) {
                return calculateRelativePosition(top > 0 ? 'top right' : 'bottom right', targetRect, popupRect);
            }
            if (top < 0) {
                return calculateRelativePosition('bottom left', targetRect, popupRect);
            }
            return [ -popupRect.height - offset, 0, 'auto', 'top left' ];
        },
        'top right': () => {
            if (left < 0) {
                return calculateRelativePosition(top < 0 ? 'top left' : 'bottom left', targetRect, popupRect);
            }
            if (top < 0) {
                return calculateRelativePosition('bottom right', targetRect, popupRect);
            }
            return [ -popupRect.height - offset, 'auto', 0, 'top right' ];
        },
        'top center': () => {
            if (top < 0) {
                return calculateRelativePosition('bottom center', targetRect, popupRect, offset, true);
            }
            return [ -popupRect.height - offset, (targetRect.width - popupRect.width) / 2, 'auto', 'top center' ];
        },
        'right top': () => {
            const right = targetRect.right + popupRect.width;
            if (bottom > window.innerHeight) {
                return calculateRelativePosition(right < window.innerWidth ? 'right bottom' : 'left bottom', targetRect, popupRect);
            }
            if (right > window.innerWidth) {
                return calculateRelativePosition('left top', targetRect, popupRect);
            }
            return [ 0 - offset, targetRect.width, 'auto', 'right top' ];
        },
        'right bottom': () => {
            const right = targetRect.right + popupRect.width;
            if (top < 0) {
                return calculateRelativePosition(right > window.innerWidth ? 'left top' : 'right top', targetRect, popupRect);
            }
            if (right > window.innerWidth) {
                return calculateRelativePosition('left bottom', targetRect, popupRect);
            }
            return [ -popupRect.height + targetRect.height + offset, targetRect.width, 'auto', 'right bottom' ];
        },
        'left top': () => {
            if (bottom > window.innerHeight) {
                return calculateRelativePosition(left < 0 ? 'right bottom' : 'left bottom', targetRect, popupRect);
            }
            if (left < 0) {
                return calculateRelativePosition('right top', targetRect, popupRect);
            }
            return [ 0, -popupRect.width - offset, 'auto', 'left top' ];
        },
        'left bottom': () => {
            if (top < 0) {
                return calculateRelativePosition(left < 0 ? 'right top' : 'left top', targetRect, popupRect);
            }
            if (left < 0) {
                return calculateRelativePosition('right bottom', targetRect, popupRect);
            }
            return [ -popupRect.height + targetRect.height, -popupRect.width - offset, 'auto', 'left bottom' ];
        },
    };

    if (position in positionCalculations) {
        return positionCalculations[position]();
    }

    return positionCalculations['bottom left']();
}