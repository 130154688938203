import React, { useEffect } from 'react';
import cn from 'classnames';
import { TColor, TSize } from '@/types/common';
import { TIconBrandProps, TIconDefaultProps, IconProps } from './types';
import { useIconsManager } from './useIconsManager';
import styles from './icon.module.scss';

const colorsMap: Record<TColor, string> = {
    white: styles.iconWhite,
    red: styles.iconRed,
    orange: styles.iconOrange,
    yellow: styles.iconYellow,
    olive: styles.iconOlive,
    green: styles.iconGreen,
    teal: styles.iconTeal,
    blue: styles.iconBlue,
    violet: styles.iconViolet,
    purple: styles.iconPurple,
    pink: styles.iconPink,
    brown: styles.iconBrown,
    grey: styles.iconGrey,
    black: styles.iconBlack,
};

const sizeMap: Record<Exclude<TSize, 'normal'>, string> = {
    tiny: styles.iconTiny,
    small: styles.iconSmall,
    big: styles.iconBig,
    large: styles.iconLarge,
    huge: styles.iconHuge,
};

export const Icon = React.forwardRef<HTMLElement, IconProps>(function Icon({
    type = 'regular',
    name,
    size,
    color,
    className,
    style,
    rotate,
} , ref) {
    const { loadIcon } = useIconsManager();

    useEffect(() => {
        if (!name) {
            throw new Error('Icon name is required');
        }
        loadIcon({ name, type } as (TIconDefaultProps | TIconBrandProps));
    }, [ loadIcon, name, type ]);

    return (
        <i
            ref={ref}
            className={ cn(
                styles.icon,
                color && colorsMap[color as TColor],
                size && sizeMap[size as Exclude<TSize, 'normal'>],
                className,
                {
                    [styles.FIconRotate]: rotate,
                },
            ) }
            style={ style }
        >
            <svg>
                <use
                    href={ `#icons-${ type }-${ name }` }
                    style={ {
                        fill: 'currentColor',
                    } }
                />
            </svg>
        </i>
    );
})