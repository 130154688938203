import React, { Suspense } from 'react';
import { useMediaQuery } from 'finbox-ui-kit/utils/hooks';
import { TFUITopMenuProps } from 'finbox-ui-kit/components/top-menu/desktop/top-menu';

const UITopMenuDesktop = React.lazy(() => import('./desktop/'));
const UITopMenuMobile = React.lazy(() => import('./mobile/'));


const UITopMenu = (props: TFUITopMenuProps) => {
    const isMobile = useMediaQuery('lg');
    let component;
    if (isMobile) {
        component = (<UITopMenuMobile { ...props }/>);
    } else {
        component = (<UITopMenuDesktop { ...props } />);
    }
    return (
        <Suspense fallback={null}>
            { component }
        </Suspense>
    )
}

export {
    UITopMenu as default,
    UITopMenu,
};

