import React, { useRef } from 'react';
import { UIButton, UIPopup } from 'finbox-ui-kit';
import { useToggle } from 'finbox-ui-kit/utils/hooks';
import { MActiveType } from '@/consts';
import { OptionsList, TOption, TSelectHandler } from '@/common/options-list';


export const AddActiveButton = ({ exists, onPick }) => {
    const ref = useRef<HTMLButtonElement>();
    const { on, toggle } = useToggle();
    const handlerSelect: TSelectHandler = ({ value }) => {
        onPick(value);
        toggle(false);
    }
    const options: TOption[] = Array.from(MActiveType).filter((i) => !exists.includes(i[0])).map(([ value, text ]) => ({
        text,
        value,
    }));
    return (
        <div>
            <UIPopup.Wrapper>
                <UIButton
                    ref={ref}
                    icon='plus'
                    content='Добавить актив'
                    onClick={() => toggle()}
                />
                <UIPopup targetRef={ref} open={on} onClickOutside={() => toggle(false) } style={{ zIndex: 9 }}>
                    <OptionsList options={options} onSelect={handlerSelect} />
                </UIPopup>
            </UIPopup.Wrapper>
        </div>
    );
};