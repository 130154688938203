import React, { MouseEventHandler, useCallback } from 'react';
import cn from 'classnames';

import './style.scss';
import { TUIIconName, TUIIconType } from 'finbox-ui-kit/components/icon/icon';
import { UIIcon } from 'finbox-ui-kit/components/icon';
import { TUIColor, TUISize } from 'finbox-ui-kit/types/common';
import { UILoader } from '@/common/finbox-ui-kit';

type TUIIconButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    icon?: TUIIconName,
    iconType?: TUIIconType
    color?: TUIColor,
    size?: TUISize,
    disabled?: boolean,
    loading?: boolean,
    className?: string,
    onClick?: MouseEventHandler<HTMLButtonElement>,
    ref?: React.Ref<HTMLButtonElement>,
}
export const UIIconButton: React.FC<TUIIconButtonProps> = React.forwardRef<HTMLButtonElement, TUIIconButtonProps>(function UIIconButton(
    {
        icon,
        disabled,
        color,
        size,
        className,
        iconType,
        loading,
        onClick,
        ...props
    },
    ref,
) {
    const handlerClick = useCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
        if (!loading && !disabled) {
            onClick(e);
        }
    }, [ disabled, loading, onClick ]);

    return (
        <button
            ref={ ref }
            type='button'
            className={ cn('FUI-icon-button', className, { '-loading': loading, '-disabled': disabled }) }
            disabled={ disabled }
            onClick={ handlerClick }
            { ...props }
        >
            { loading && (
                <div className='fz9'>
                    <UILoader size='tiny' inverted/>
                </div>
            ) }
            { !loading && (
                <UIIcon
                    type={ iconType || 'solid' }
                    name={ icon }
                    color={ color }
                    size={ size }
                />
            ) }
        </button>
    );
})
