import { useCallback, useEffect, useState } from 'react';
import { IReport } from '@/common/cadastral-number/types';
import { API_ROSREESTR_REPORTS } from '@/components/rosreestr-reports/_consts';
import { useApiClient } from '@/libs/api-client/use-api-client';

type TUseCadastralReportsProps = {
    cadastralNumber?: string;
}

type TUseCadastralReportsReturn = {
    reports: IReport[];
    total: number;
    isFetched: boolean;
    isLoading: boolean;
    isLoadingMakeReport: boolean;
    makeReport: (cadastralNumber: string, clientId: number, comment: string) => Promise<void>;
    refresh: () => Promise<void>;
}

export const useCadastralNumberReports = ({ cadastralNumber }: TUseCadastralReportsProps): TUseCadastralReportsReturn => {
    const [ reports, setReports ] = useState([]);
    const [ total, setTotal ] = useState(0);

    const { data, loading: isLoadingReports, isFetched, fetch } = useApiClient({
        method: 'get',
        url: `${API_ROSREESTR_REPORTS}${cadastralNumber}/`,
        initialFetch: true,
    });

    const { loading: isLoadingMakeReport, fetch: fetchMakeReport } = useApiClient({
        method: 'post',
        url: API_ROSREESTR_REPORTS,
        successMessage: 'Выписка формируется',
    });

    useEffect(() => {
        if (data) {
            setReports(data.data);
            setTotal(data.total);
        }
    }, [ data ]);

    const makeReport = useCallback(async (cadastralNumber: string, clientId: number, comment: string) => {
        await fetchMakeReport({
            cadastralNumber,
            clientId,
            comment,
        });
    }, [ fetchMakeReport ]);


    const refresh = useCallback(async () => {
        await fetch();
    }, [ fetch ]);

    return {
        isLoading: isLoadingReports,
        isFetched,
        reports,
        total,
        isLoadingMakeReport,
        makeReport,
        refresh,
    };
}
