import React from 'react';
import cn from 'classnames';
import { TButtonsGroupProps } from './button';
import styles from './button.module.scss';

export function ButtonsGroup({ children, className }: TButtonsGroupProps) {
    return (
        <div className={cn(styles.FUIButtonsGroup, className)}>
            { children }
        </div>
    )
}