import React from 'react';
import styles from './error-text.module.scss';

type ErrorTextProps = {
    text?: string | undefined,
    children?: React.ReactNode,
};

export function ErrorText({ text, children }: ErrorTextProps) {
    return (
        <div className={ styles.errorText }>
            { text || children }
        </div>
    );
}