import yup from '@/utils/yup-config';
import { companyScheme } from '@/components/leads/components/credit-from/schemas/company.scheme';
import { employeeScheme } from '@/components/leads/components/credit-from/schemas/employee.scheme';
import { primeTimeJobCompanyScheme } from '@/components/leads/components/credit-from/schemas/prime-time-job-company.scheme';
import { TWorkAndEducationForm } from '@/components/leads/components/credit-from/types';

export const workAndEducationScheme = yup.object<TWorkAndEducationForm>({
    education: yup.string().required(),
    employmentType: yup.string().required(),
    selfEmployedType: yup.string().when('employmentType', {
        is: (v: string) => [ 'Самозанятый', 'Неофициальный бизнес' ].includes(v),
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
    }),
    selfEmployedINN: yup.string().when('employmentType', {
        is: (v: string) => v === 'Самозанятый',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
    }),
    comment: yup.string().nullable(),
    company: yup.object().when('employmentType', {
        is: (v: string) => ![ 'Самозанятый', 'Пенсионер', 'Нетрудоустроенный', 'Неофициальный бизнес' ].includes(v),
        then: () => companyScheme.required(),
        otherwise: (schema) => schema.nullable(),
    }),
    employee: yup.object().when('employmentType', {
        is: (v: string) => ![ 'Пенсионер', 'Нетрудоустроенный' ].includes(v),
        then: () => employeeScheme.required(),
        otherwise: (schema) => schema.nullable(),
    }),
    primeTimeJob: yup.boolean().when('employmentType', {
        is: (v: string) => v !== 'Нетрудоустроенный',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
    }),
    primeTimeJobCompany: yup.object().when('primeTimeJob', {
        is: true,
        then: () => primeTimeJobCompanyScheme.required(),
        otherwise: (schema) => schema.nullable(),
    }),
});