import React from 'react';
import cn from 'classnames';

import './param.style.scss';
import { TUIColor, TUISize } from 'finbox-ui-kit/types/common';
import { UIIcon } from 'finbox-ui-kit/components/icon';
import { UIPlaceholder } from '@/common/finbox-ui-kit';

type TParamProps = {
    label: React.ReactNode;
    value?: any;
    boolean?: boolean;
    loading?: boolean;
    compact?: boolean;
    className?: string;
    color?: TUIColor | '';
    center?: boolean;
    icon?: boolean;
    noMargin?: boolean;

    children?: React.ReactNode
    size?: TUISize;
}

export const UIParam = React.forwardRef<HTMLDListElement, TParamProps>(function UIParam({
    label,
    value,
    // tslint:disable-next-line:variable-name
    boolean,
    loading,
    children,
    compact,
    className,
    color,
    center,
    icon,
    size,
    noMargin,
}, ref) {
    let parsedValue;

    if (children) {
        parsedValue = children;
    } else if (boolean && typeof value === 'boolean') {
        if (icon) {
            parsedValue = <UIIcon name={ value ? 'circle-check' : 'circle-check' } color={ value ? 'green' : 'red' } size='large'/>;
        } else {
            parsedValue = value ? 'Да' : 'Нет';
        }
    } else {
        parsedValue = value || '-';
    }
    return (
        <dl ref={ ref } className={ cn('ui-param', {
            '-compact': compact,
            '-center': center,
            '-noMargin': noMargin,
            [`-${ color }`]: color,
            [`-${ size }`]: size,
        }, className) }>
            <dt>{ label }</dt>
            <dd>
                { !loading && parsedValue }
                { loading && (
                    <UIPlaceholder/>
                ) }
            </dd>
        </dl>
    );
});
