import React, { useState } from 'react';
import { UIGrid } from 'finbox-ui-kit';
import { API_URLS } from '@/consts';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { InputSuggestions } from '@/common/ui/input-suggestions';
import { Input } from '@/common/ui/input';
import { InputAddress } from '@/common/ui/input-address';


let buffer: string | number | NodeJS.Timeout;

export const CompanyForm = ({ name: rootName, fields, onChange, errors, required }) => {
    const [ companySuggestions, setCompanySuggestions ] = useState([]);

    const { loading: isLoadingOrganization, fetch: fetchOrganization } = useApiClient({
        url: API_URLS.DICTIONARIES.DADATA.ORGANIZATION,
    });

    const handlerChange = React.useCallback(async (e, { name, value, option }) => {
        if (name === 'name') {
            clearTimeout(buffer);
            if (value) {
                buffer = setTimeout(async () => {
                    const request = await fetchOrganization({
                        query: value,
                    });
                    setCompanySuggestions(request.suggestions.map((i) => ({
                        text: `${i.value} (${i.data.address.value})`,
                        value: i.value,
                        data: i,
                    })));
                }, 300);
            }
            if (option) {
                onChange(e, {
                    name: `${rootName}.inn`,
                    value: option.data.data.inn,
                });
                onChange(e, {
                    name: `${rootName}.ogrn`,
                    value: option.data.data.ogrn,
                });
                onChange(e, {
                    name: `${rootName}.address`,
                    value: option.data.data.address,
                });
            }
        }
        if (name === 'inn' && value) {
            const request = await fetchOrganization({
                query: value,
            });
            if (request.suggestions[0]) {
                onChange(e, {
                    name: `${rootName}.name`,
                    value: request.suggestions[0].data.name.short_with_opf,
                });
                onChange(e, {
                    name: `${rootName}.ogrn`,
                    value: request.suggestions[0].data.ogrn,
                });
                onChange(e, {
                    name: `${rootName}.address`,
                    value: request.suggestions[0].data.address,
                });
            }
        }
        onChange(e, {
            name: `${rootName}.${name}`,
            value: value,
        });
    }, [ fetchOrganization, onChange, rootName ]);

    return (
        <>
            <UIGrid.Row columns={4} className='pb0'>
                <UIGrid.Col colspan={2}>
                    <InputSuggestions
                        type='text'
                        label='Название организации'
                        name='name'
                        error={errors['name']}
                        value={fields.name}
                        onChange={handlerChange}
                        suggestions={companySuggestions}
                        loading={isLoadingOrganization}
                        placeholder='Начните вводить название или ИНН'
                        filtering={false}
                        autoComplete='off'
                    />
                </UIGrid.Col>
                <UIGrid.Col>
                    <Input
                        type='number'
                        label='ИНН'
                        name='inn'
                        error={errors['inn']}
                        value={fields.inn}
                        onChange={handlerChange}
                        required={required}
                    />
                </UIGrid.Col>
                <UIGrid.Col>
                    <Input
                        type='number'
                        label='ОГРН'
                        name='ogrn'
                        error={errors['ogrn']}
                        value={fields.ogrn}
                        onChange={handlerChange}
                        required={required}
                    />
                </UIGrid.Col>
            </UIGrid.Row>
            <UIGrid.Row columns={3} className='p0'>
                <UIGrid.Col colspan={2}>
                    <InputAddress
                        label='Юридический адрес'
                        name='address'
                        error={errors['address']}
                        value={fields.address}
                        onChange={handlerChange}
                        autoComplete='off'
                        required={required}
                    />
                </UIGrid.Col>
                <UIGrid.Col>
                    <Input
                        type='tel'
                        label='Рабочий телефон'
                        name='phone'
                        error={errors['phone']}
                        mask='+7 (999) 999-99-99'
                        value={fields.phone}
                        onChange={handlerChange}
                        placeholder='+7 (000) 000-00-00'
                        autoComplete='off'
                        required={required}
                    />
                </UIGrid.Col>
            </UIGrid.Row>
            <UIGrid.Row columns={3} className='pt0'>
                <UIGrid.Col>
                    <Input
                        type='text'
                        label='Вид работы по совместительству'
                        name='jobType'
                        error={errors['jobType']}
                        value={fields.jobType}
                        onChange={handlerChange}
                        required={required}
                    />
                </UIGrid.Col>
                <UIGrid.Col>
                    <Input
                        type='text'
                        label='Трудовой стаж с'
                        name='startDate'
                        error={errors['startDate']}
                        value={fields.startDate}
                        onChange={handlerChange}
                        mask='99.9999'
                        placeholder='мм.гггг'
                        autoComplete='off'
                        required={required}
                    />
                </UIGrid.Col>
            </UIGrid.Row>
        </>
    );
};

