const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const symbols = "!_";

export function generatePassword(passwordLength = 12, useSymbols = false): string {
    let password = '';

    const _chars = chars + (useSymbols ? symbols : '');

    while (!/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!_]+$/g.test(password)) {
        password = '';
        for (let i = 0; i <= passwordLength; i++) {
            const randomNumber = Math.floor(Math.random() * _chars.length);
            password += _chars.substring(randomNumber, randomNumber + 1);
        }
    }

    return password;
}
