import { mimeTypes } from 'finbox-ui-kit/consts';
import { TUIIconName } from 'finbox-ui-kit/components/icon/icon';
import { TUIColor } from 'finbox-ui-kit/types/common';

export function mimeTypeToIconName(mimeType: string): TUIIconName {
    const type = mimeTypes[mimeType];
    switch (type) {
        case 'xls':
        case 'xlsx':
            return 'file-excel';
        case 'doc':
        case 'docx':
            return 'file-word';
        case 'pdf':
            return 'file-pdf';
        case 'jpg':
        case 'png':
        case 'gif':
            return 'image';
        case 'zip':
            return 'file-zip';
        default:
            return 'file'
    }
}

export function mimeTypeToIconColor(mimeType: string): TUIColor | null {
    const type = mimeTypes[mimeType];
    switch (type) {
        case 'xls':
        case 'xlsx':
            return 'green';
        case 'doc':
        case 'docx':
            return 'blue';
        case 'pdf':
            return 'red';
        case 'jpg':
        case 'png':
        case 'gif':
            return 'teal';
        case 'zip':
            return 'orange';
        default:
            return null;
    }
}