export enum ECreditFormType {
    MORTGAGE = 'mortgage',
    LOAN = 'loan',
    CREDIT = 'credit',
}


export const MCreditPurpose = [
    'Потребительские нужды',
    'Ремонт недвижимости (и иной собственности)',
    'Погашение кредитов (рефинансирование)',
    'Рефинансирование ипотечных кредитов',
    'Погашение долгов (не кредиты)',
    'На развитие бизнеса',
    'Пополнение оборотных средств',
    'Покупка оборудования',
    'Приобретение недвижимости',
    'Приобретение автомобиля',
    'Образование',
    'Лечение',
    'Торжество',
    'Шопинг',
    'Помощь близким',
    'Путешествие',
    'Иное',
];

export const MEstateType = [
    'Квартира',
    'Апартаменты',
    'Индивидуальный жилой дом',
    'Жилой блок и земельный участок',
    'Комната(ы) / доля в праве',
    'Таунхаус',
    'Коммерческая недвижимость',
    'БЖФ',
];

export const MEstatePhase = [
    'Готовый',
    'Строящийся',
];

export const MLoanSecurity = [
    'Залог имеющейся в собственности недвижимости',
    'Залог приобретаемой недвижимости',
    'Залог имущественных прав по договору о приобретении недвижимости',
];

export const MYesNoOptions = [
    {
        text: 'Да',
        value: true,
    },
    {
        text: 'Нет',
        value: false,
    },
];

export const MInitialPaymentInclude = [
    'Средства МСК',
    'Иная социальная выплата',
];

export const MFamilyStatus = [
    'Женат/замужем',
    'Холост/не замужем',
    'Разведен(-а)',
    'Вдовец/вдова',
    'Гражданский брак',
];

export const MProofOfIncome = [
    'Без подтверждения',
    'Выпиской по счету физического лица',
    'Справкой по форме кредитора',
    'Выпиской из ПФР',
    'Справкой 2 НДФЛ',
    'Декларацией 3 НДФЛ',
    'Выпиской по счету юридического лица',
    'Декларацией УСН',
    'Декларацией по налогу на прибыль',
    'Договором по госконтракту (тендер)',
];

export const MProofOfJob = [
    'Без подтверждения',
    'Трудовой книжкой',
    'Договором ГПХ',
    'Трудовым договором',
];

export const MEducation = [
    'Неполное среднее',
    'Среднее',
    'Среднее специальное',
    'Незаконченное высшее',
    'Высшее',
    'Несколько высших',
    'Ученая степень',
];

export const MEmploymentType = [
    'Наёмный работник',
    'Самозанятый',
    'Индивидуальный предприниматель',
    'Неофициальный бизнес',
    'Владелец / Совладелец организации',
    'Пенсионер',
    'Нетрудоустроенный',
];

export const MCompanyActivityType = [
    'Коммерческая организация',
    'Государственное/муниципальное предприятие',
];

export const MCompanyType = new Map([
    [ 'INDIVIDUAL', 'ИП' ],
    [ 'LEGAL', 'ООО и др.' ],
]);

export const MPledgeObjectDocumentType = new Map([
    [ 'contract_of_sale', 'Договор купли-продажи' ],
    [ 'donation', 'Дарение' ],
    [ 'certificate_of_the_right_to_inherit', 'Свидетельство о праве на наследование' ],
    [ 'contract_of_privatization', 'Договор приватизации' ],
    [ 'court_decision', 'Решение суда' ],
    [ 'barter_agreement', 'Договор мены' ],
]);

export const MExpensesTypes = [
    'Кредитная нагрузка',
    'Содержание семьи',
    'Коммунальные платежи',
    'Обязательства по кредитам/займам',
    'Аренда',
    'Алименты',
];

export const MSelfEmployedType = [
    'Транспортировка грузов',
    'Такси',
    'Курьерские услуги',
    'Перевозка пассажиров',
    'Эвакуация и буксировка автомобилей',
    'Автомойка',
    'Автомобильный сервис: ТО, диагностика и ремонт',
    '',
    'Ведение хозяйства',
    'Услуги гувернантки',
    'Доставка товаров к двери',
    'Услуги няни и сиделки',
    'Приготовление еды',
    'Оказание социальной помощи',
    'Охрана',
    'Клининговые услуги',
    'Химчистка',
    '',
    'Организация различных мероприятий: праздников',
    'Организация торжеств, экскурсий, концертов',
    'Услуги ведущего, шоумена, тамады, аниматора',
    'Артисты, певцы, музыканты',
    '',
    'Фотосъемка',
    'Видеосъемка',
    'Обработка фотографий',
    'Монтаж',
    '',
    'Отделочные работы',
    'Ремонт помещений',
    'Реконструкции и реставрации',
    'Ландшафтный и интерьерный дизайн',
    'Малярные работы',
    'Ремонт быттехники',
    'Услуги сантехника, электрика',
    'Техобслуживание',
    'Услуги плотника, столяра',
    'Металлообработка',
    'Проектирование и прочее',
    '',
    'Копирайтинг',
    'Переводы',
    'SMM-продвижение',
    'Настройка таргетированной рекламы',
    'Веб-дизайн',
    'Блоггинг',
    'реклама',
    'PR, интернет-маркетинг',
    'Проведение маркетинговых исследований и опросов, сбор мнений',
    '',
    'Услуги компьютерного мастера',
    'Программирование',
    'Техподдержка',
    'Верстка',
    'Дизайн',
    'Администрирование',
    'Анализ и обработка данных',
    '',
    'Выпечка (производство / продажа)',
    'Одежда (производство / продажа)',
    'Игрушки (производство / продажа)',
    'Украшения (производство / продажа)',
    'Мебель (производство / продажа)',
    '',
    'Аренда квартир',
    'Аренда автомобилей',
    'Аренда, прокат велосипедов, самокатов',
    'Услуга временного проживания',
    '',
    'Прием/сдача лома',
    'Различные сельскохозяйственные услуги',
    'Животноводческая деятельность',
    'Охота/рыбалка',
    'Работы по благоустройству территорий',
    '',
    'Маникюр и педикюр на дому',
    'Услуги косметолога',
    'Эпиляция',
    'Консультации по стилю',
    'Парикмахерские услуги',
    'Нанесение татуировок, пирсинг',
    '',
    'Массаж',
    'Консультации диетолога, нутрициолога',
    'Услуги логопеда',
    'Психология',
    'Тренировки по фитнесу',
    '',
    'Репетиторство',
    'Преподавание',
    'Проведение обучающих программ',
    '',
    'Приготовление блюд, напитков и прочих кулинарных изделий',
    'Кондитерское дело',
    'Обслуживание',
    '',
    'Создание макетов и печать бланков, брошюр, листовок',
    'Создание макетов и печать блокнотов, буклетов календарей, визиток, флаеров',
    'Послепечатная обработка',
    'Издательство',
    '',
    'Моделирование одежды',
    'Дизайн одежды',
    'Пошив одежды',
    'Кройка и шитье одежды',
    '',
    'Составление бухгалтерской отчетности',
    'Консультирование по жилищным, трудовым, семейным, гражданским, иным вопросам',
    'Страховые услуги',
];


export const ObjectType = {
    '002001001000': 'Земельный участок',
    '002001002000': 'Здание',
    '002001003000': 'Помещение',
    '002001004000': 'Сооружение',
    '002001005000': 'Объект незавершенного строительства',
    '002001006000': 'Предприятие как имущественный комплекс',
    '002001008000': 'Единый недвижимый комплекс',
    '002001009000': 'Машино-место',
    '002001010000': 'Иной объект недвижимости',
};

export const RealtyType = new Map<string, string>([
    [ '204002000000', 'Жилой дом' ],
    [ '002001001000', 'Земельный участок' ],
    [ '205001000000', 'Квартира' ],
    [ '205002000000', 'Комната' ],
    [ '002001002000', 'Здание' ],
    [ '002001003000', 'Помещение' ],
    [ '002001004000', 'Сооружение' ],
    [ '002001005000', 'Объект незавершенного строительства' ],
    [ '002001006000', 'Предприятие как имущественный комплекс' ],
    [ '002001008000', 'Единый недвижимый комплекс' ],
    [ '002001009000', 'Машино-место' ],
    [ '002001010000', 'Иной объект недвижимости' ],
    [ '204001000000', 'Нежилое здание' ],
    [ '204003000000', 'Многоквартирный дом' ],
    [ '206001000000', 'Нежилое помещение' ],
    [ '206002000000', 'Жилое помещение' ],
]);
