/* eslint-disable max-len */

export const joiRuLocale = {
    'alternatives.all': '{{#label}} не соответствует всем требуемым типам',
    'alternatives.any': '{{#label}} не соответствует ни одному из разрешенных типов',
    'alternatives.match': '{{#label}} не соответствует ни одному из разрешенных типов',
    'alternatives.one': '{{#label}} соответствует более чем одному разрешенному типу',
    'alternatives.types': '{{#label}} должен быть одним из {{#types}}',

    'any.custom': '{{#label}} не удалось выполнить пользовательскую проверку, потому что {{#error.message}}',
    'any.default': '{{#label}} выдал ошибку при запуске метода по умолчанию',
    'any.failover': '{{#label}} выдал ошибку при запуске метода аварийного переключения',
    'any.invalid': '{{#label}} содержит недопустимое значение',
    'any.only': 'должно быть {{#valids}}',
    'any.ref': '{{#label}} {{#arg}} ссылается на "{{#ref}}", который {{#reason}}',
    'any.required': 'обязательное поле',
    'any.unknown': '{{#label}} не разрешен',

    'array.base': 'некорректно заполнено поле',
    'array.excludes': '{{#label}} содержит исключенное значение',
    'array.hasKnown': '{{#label}} не содержит хотя бы одного обязательного соответствия для типа "{#patternLabel}"',
    'array.hasUnknown': '{{#label}} не содержит хотя бы одного обязательного совпадения',
    'array.includes': '{{#label}} не соответствует ни одному из разрешенных типов',
    'array.includesRequiredBoth': '{{#label}} не содержит {{#knownMisses}} и {{#unknownMisses}} других необходимых значений',
    'array.includesRequiredKnowns': '{{#label}} не содержит {{#knownMisses}}',
    'array.includesRequiredUnknowns': 'необходимо выбрать минимум {{#unknownMisses}}',
    'array.length': '{{#label}} должен содержать {{#limit}} элементов',
    'array.max': 'должно быть меньше или равно {{#limit}} элементов',
    'array.min': 'должно быть не менее {{#limit}} элементов',
    'array.orderedLength': '{{#label}} должен содержать не более {{#limit}} элементов',
    'array.sort': '{{#label}} должен быть отсортирован в порядке {#order} по {{#by}}',
    'array.sort.mismatching': '{{#label}} невозможно отсортировать из-за несовпадения типов',
    'array.sort.unsupported': '{{#label}} невозможно отсортировать из-за неподдерживаемого типа {#type}',
    'array.sparse': '{{#label}} не должен быть элементом разреженного массива',
    'array.unique': '{{#label}} содержит повторяющееся значение',

    'binary.base': '{{#label}} должен быть буфером или строкой',
    'binary.length': '{{#label}} должно быть {{#limit}} байт',
    'binary.max': '{{#label}} должен быть меньше или равен {{#limit}} байт',
    'binary.min': '{{#label}} должен быть не менее {{#limit}} байт',

    'boolean.base': 'некорректно заполнено поле',

    'date.base': '{{#label}} должна быть допустимой датой',
    'date.format': 'некорректная дата',
    'date.greater': '{{#label}} должно быть больше, чем "{{#limit}}"',
    'date.less': '{{#label}} должно быть меньше "{{#limit}}"',
    'date.max': '{{#label}} должно быть меньше или равно "{{#limit}}"',
    'date.min': '{{#label}} должен быть больше или равен "{{#limit}}"',

    // Сообщения, используемые в date.format

    'date.format.iso': 'дата ISO 8601',
    'date.format.javascript': 'метка времени или количество миллисекунд',
    'date.format.unix': 'метка времени или количество секунд',

    'function.arity': '{{#label}} должна иметь арность {{#n}}',
    'function.class': '{{#label}} должен быть классом',
    'function.maxArity': '{{#label}} должна иметь арность, меньшую или равную {{#n}}',
    'function.minArity': '{{#label}} должна иметь арность, большую или равную {{#n}}',

    'object.and': '{{#label}} содержит {{#presentWithLabels}} без обязательных одноранговых элементов {{#missingWithLabels}}',
    'object.assert': '{{#label}} недействителен, поскольку {if(#subject.key, `"` + #subject.key + `" не удалось ` + (#message || "пройти проверку утверждения"), #message || "утверждение не удалось")}',
    'object.base': 'некорректно заполнено поле',
    'object.instance': '{{#label}} должен быть экземпляром "{{#type}}"',
    'object.length': '{{#label}} должен иметь ключ {{#limit}} {if(#limit == 1, "", "s")}',
    'object.max': '{{#label}} должен иметь ключ меньше или равный {{#limit}} {if(#limit == 1, "", "s")}',
    'object.min': '{{#label}} должен иметь как минимум {{#limit}} ключ{if(#limit == 1, "", "s")}',
    'object.missing': '{{#label}} должен содержать хотя бы один из {{#peersWithLabels}}',
    'object.nand': '"{{#mainWithLabel}}" не должен существовать одновременно с {{#peersWithLabels}}',
    'object.oxor': '{{#label}} содержит конфликт между необязательными эксклюзивными узлами {{#peersWithLabels}}',
    'object.template.match': 'ключи {{#label}} не соответствуют требованиям шаблона',
    'object.refType': '{{#label}} должен быть ссылкой Joi',
    'object.regex': '{{#label}} должен быть объектом RegExp',
    'object.rename.multiple': '{{#label}} не может переименовать "{{#from}}", поскольку многократное переименование запрещено.включен, а другой ключ уже переименован в "{{#to}}"',
    'object.rename.override': '{{#label}} не может переименовать "{{#from}}", поскольку переопределение отключено и существует цель "{{#to}}"',
    'object.schema': '{{#label}} должна быть схемой Joi типа {{#type}}',
    'object.unknown': '{{#label}} не разрешен',
    'object.with': '"{{#mainWithLabel}}" отсутствует необходимый одноранговый узел "{{#peerWithLabel}}"',
    'object.without': '"{{#mainWithLabel}}" конфликтует с запрещенным узлом "{{#peerWithLabel}}"',
    'object.xor': '{{#label}} содержит конфликт между эксклюзивными узлами {{#peersWithLabels}}',

    'number.base': 'некорректно заполнено поле',
    'number.greater': '{{#label}} должно быть больше, чем {{#limit}}',
    'number.infinity': '{{#label}} не может быть бесконечностью',
    'number.integer': '{{#label}} должно быть целым числом',
    'number.less': 'должно быть меньше {{#limit}}',
    'number.max': 'должно быть меньше или равно {{#limit}}',
    'number.min': '{{#label}} должен быть больше или равен {{#limit}}',
    'number.multiple': '{{#label}} должно быть кратно {{#multiple}}',
    'number.negative': '{{#label}} должно быть отрицательным числом',
    'number.port': '{{#label}} должен быть допустимым портом',
    'number.positive': '{{#label}} должно быть положительным числом',
    'number.precision': '{{#label}} должно содержать не более {{#limit}} десятичных знаков',
    'number.unsafe': '{{#label}} должен быть безопасным номером',

    'string.alphanum': '{{#label}} должен содержать только буквенно-цифровые символы',
    'string.base': 'некорректно заполнено поле',
    'string.base64': '{{#label}} должна быть допустимой строкой base64',
    'string.creditCard': '{{#label}} должна быть кредитной картой',
    'string.dataUri': '{{#label}} должна быть допустимой строкой dataUri',
    'string.domain': '{{#label}} должно содержать допустимое доменное имя',
    'string.email': 'некорректный адрес электронной почты',
    'string.empty': 'необходимо заполнить поле',
    'string.guid': '{{#label}} должен быть действительным GUID',
    'string.hex': '{{#label}} должен содержать только шестнадцатеричные символы',
    'string.hexAlign': '{{#label}} шестнадцатеричное декодированное представление должно быть выровнено по байтам',
    'string.hostname': '{{#label}} должно быть допустимым именем хоста',
    'string.ip': '{{#label}} должен быть действительным IP-адресом с {{#cidr}} CIDR',
    'string.ipVersion': '{{#label}} должен быть действительным IP-адресом одной из следующих версий {{#version}} с {{#cidr}} CIDR',
    'string.isoDate': '{{#label}} должен быть в формате ISO',
    'string.isoDuration': '{{#label}} должна иметь действительную продолжительность ISO 8601',
    'string.length': 'длина должна составлять {{#limit}} символов',
    'string.lowercase': '{{#label}} должен содержать только символы нижнего регистра',
    'string.max': 'длина должна быть меньше или равна длине {{#limit}} символов',
    'string.min': 'длина должна быть не менее {{#limit}} символов',
    'string.normalize': '{{#label}} должен быть нормализован в Юникоде в форме {{#form}}',
    'string.token': '{{#label}} должен содержать только буквенно-цифровые символы и символы подчеркивания',
    'string.template.base': '{{#label}} со значением "{[.]}" не соответствует требуемому шаблону: {{#regex}}',
    'string.template.name': '{{#label}} со значением "{[.]}" не соответствует шаблону {{#name}}',
    'string.template.invert.base': '{{#label}} со значением "{[.]}" соответствует инвертированному шаблону: {{#regex}}',
    'string.template.invert.name': '{{#label}} со значением "{[.]}" соответствует инвертированному шаблону {{#name}}',
    'string.trim': '{{#label}} не должно иметь начальных или конечных пробелов',
    'string.uri': '{{#label}} должен быть действительным uri',
    'string.uriCustomScheme': '{{#label}} должен быть допустимым uri со схемой, соответствующей шаблону {{#scheme}}',
    'string.uriRelativeOnly': '{{#label}} должен быть допустимым относительным URI',
    'string.uppercase': '{{#label}} должен содержать только символы верхнего регистра',

    'symbol.base': '{{#label}} должен быть символом',
    'symbol.map': '{{#label}} должен быть одним из {{#map}}',
};