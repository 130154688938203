import React, { useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { FieldError, Merge } from 'react-hook-form';
import { UIButton, UICardsList } from 'finbox-ui-kit';
import { mimeTypeToIconColor, mimeTypeToIconName } from 'finbox-ui-kit/utils/mime-type-to-icon-name';
import styles from './file-picker.module.scss';


type UIFilePickerProps = {
    accept?: Accept;
    minSize?: number;
    maxSize?: number;
    maxFiles?: number;
    onChange: (files: File[]) => void;
    disabled?: boolean;
    loading?: boolean;
    buttonText?: string;
    error?: Merge<FieldError, FieldError[]>;
    fluid?: boolean;
    required?: boolean;
    columns?: number;
    compress?: boolean;
    showPickedFiles?: boolean;
}

export function UIFilePicker({
    accept,
    minSize,
    maxSize,
    maxFiles,
    onChange,
    disabled,
    loading,
    fluid,
    error,
    required,
    buttonText = 'Прикрепить файлы',
    columns = 1,
    showPickedFiles = true,
}: UIFilePickerProps) {
    const [ files, setFiles ] = useState<File[]>([]);

    const handlerPickFiles = useCallback(async (acceptedFiles: File[]) => {
        const _files = [ ...files, ...acceptedFiles ];
        setFiles(_files);
        onChange(_files);
    }, [ files, onChange ]);

    const handlerClickRemoveFile = useCallback((index: number) => () => {
        if (disabled) {
            return;
        }
        const _files = files.filter((f, i) => i !== index);
        setFiles(_files);
        onChange(_files);
    }, [ disabled, files, onChange ]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept,
        minSize,
        maxSize,
        maxFiles,
        onDrop: handlerPickFiles,
        disabled: loading || disabled || disabled || files.length === maxFiles,
    });

    return (
        <div className={ cn(styles.FUIFilePicker, {
            [styles.FUIFilePickerDisabled]: disabled,
            [styles.FUIFilePickerFuild]: fluid,
        }) }>
            { showPickedFiles && files.length > 0 && (
                <UICardsList columns={ columns } className='mb1'>
                    { files.map((file, index) => (
                        <UICardsList.Item
                            key={ index }
                            title={ file.name }
                            subTitle={ (
                                <>
                                    { (file.size / 1024 / 1024).toFixed(2) }Mb
                                </>
                            ) }
                            icon={ mimeTypeToIconName(file.type) }
                            iconType='thin'
                            iconColor={ mimeTypeToIconColor(file.type) }
                            onRemove={ handlerClickRemoveFile(index) }
                            error={ error && Array.isArray(error) && error[index]?.message }
                        />
                    )) }
                </UICardsList>
            ) }
            <div
                { ...getRootProps() }
                className={ cn(
                    styles.FUIFilePickerDropZone,
                    { [styles.FUIFilePickerDropZoneDragActive]: isDragActive },
                ) }
            >
                <input { ...getInputProps() } />
                <UIButton
                    as='label'
                    htmlFor='attachment'
                    icon='paperclip'
                    loading={ loading }
                    disabled={ disabled || files.length === maxFiles }
                >
                    { buttonText }
                    { required ? (<span className='color-red'>&nbsp;*</span>) : null }
                </UIButton>
                { !Array.isArray(error) && error?.message && (
                    <div className='mt-5 fz11 color-red'>{ error?.message }</div>
                ) }
                <div className='mt-5 fz11 color-grayDarken'>Вы можете добавить файл перетащив его на кнопку</div>
            </div>
        </div>
    );
}