import React, { Ref, useEffect, useRef } from "react";
import cn from 'classnames';
import { UIPopup } from 'finbox-ui-kit';
import { useToggle } from 'finbox-ui-kit/utils/hooks';
import { TUIPopupPosition } from 'finbox-ui-kit/components/popup/popup';
import { TUIColor, TUISize } from 'finbox-ui-kit/types/common';
import { isDefined } from 'finbox-ui-kit/utils';
import styles from './tooltip.module.scss';

const colorsMap: Record<TUIColor, string> = {
    white: styles.FUITooltipWhite,
    red: styles.FUITooltipRed,
    orange: styles.FUITooltipOrange,
    yellow: styles.FUITooltipYellow,
    olive: styles.FUITooltipOlive,
    green: styles.FUITooltipGreen,
    teal: styles.FUITooltipTeal,
    blue: styles.FUITooltipBlue,
    violet: styles.FUITooltipViolet,
    purple: styles.FUITooltipPurple,
    pink: styles.FUITooltipPink,
    brown: styles.FUITooltipBrown,
    grey: styles.FUITooltipGrey,
    black: styles.FUITooltipBlack,
};

export function UITooltip({
    content,
    trigger,
    position = 'top center',
    inverted,
    noPadding,
    open: controlledOpen,
    onClose,
    size = 'small',
    color = 'grey',
    on = 'hover',
    offset = 5,
}: TooltipProps) {
    const triggerRef = useRef<HTMLElement>();
    const tooltipRef = useRef<HTMLDivElement>();
    const { on: open, toggle } = useToggle(controlledOpen);

    useEffect(() => {
        const element = triggerRef.current;
        function handlerEnter() {
            toggle(true);
        }
        function handlerLeave() {
            toggle(false);
        }

        function handleClickOutside(event: MouseEvent) {
            if (
                triggerRef.current && !(triggerRef.current as any).contains(event.target)
                && tooltipRef.current && !(tooltipRef.current as any).contains(event.target)
            ) {
                if (!isDefined(controlledOpen)) {
                    toggle(false);
                }
                if (onClose) {
                    onClose();
                }
            }
        }
        if (!isDefined(controlledOpen)) {
            if (on === 'hover') {
                element?.addEventListener('mouseenter', handlerEnter);
                element?.addEventListener('mouseleave', handlerLeave);
            } else {
                element?.addEventListener('click', handlerEnter);
            }
        }
        document.addEventListener("click", handleClickOutside);

        return () => {
            if (!isDefined(controlledOpen)) {
                if (on === 'hover') {
                    element?.removeEventListener('mouseenter', handlerEnter);
                    element?.removeEventListener('mouseleave', handlerLeave);
                } else {
                    element?.removeEventListener('click', handlerEnter);
                }
            }
            document.removeEventListener("click", handleClickOutside);
        };
    }, [ controlledOpen, on, onClose, toggle ]);

    useEffect(() => {
        if(isDefined(controlledOpen) && triggerRef) {
            toggle(controlledOpen);
        }
    }, [ controlledOpen, toggle ]);

    return (
        <>
            { trigger(triggerRef) }
            <UIPopup
                className={styles.FUITooltipPopup}
                targetRef={ triggerRef }
                strategy='fixed'
                position={ position }
                minWidth={ 0 }
                open={ open }
                offset={ offset }
                style={{ zIndex: 9999 }}
                portal='overlay-modal-portal'
            >
                <div
                    ref={ tooltipRef }
                    className={ cn(
                        styles.FUITooltip,
                        colorsMap[color],
                        {
                            [styles.FUITooltipInverted]: inverted,
                            [styles.FUITooltipNoPadding]: noPadding,
                            [`-${ size }`]: size,
                        }) }
                >
                    { content }
                </div>
            </UIPopup>
        </>
    );
}

type TooltipProps = {
    content: React.ReactNode
    trigger: (ref: Ref<any> | undefined) => React.ReactElement
    position?: TUIPopupPosition
    inverted?: boolean
    noPadding?: boolean
    offset?: number
    on?: 'click' | 'hover'
    color?: TUIColor
    size?: TUISize
    open?: boolean
    onClose?: () => void
}
