import React from 'react';
import { useRecoilValue } from 'recoil';
import { Outlet } from 'react-router';
import { AuthUser } from '@/state/auth.state';
import { Footer } from '@/common/footer';
import TopMenu from '@/components/top-menu';


const AppLayout = () => {
    const user = useRecoilValue(AuthUser);

    if (!user) return null;

    return (
        <main>
            <TopMenu isAdmin={ user?.admin } user={ user }/>
            <div className='main-block'>
                <Outlet />
            </div>
            <Footer />
        </main>
    );
};

export default AppLayout;
