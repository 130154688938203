import React, { useCallback } from 'react';

import { Controller, useFormContext, FieldError } from 'react-hook-form';
import { UIBlock } from 'finbox-ui-kit';
import { TUIEventHandler } from 'finbox-ui-kit/components/input/input';
import { MBorrowerType } from '@/consts';
import { FinancesForm } from '@/components/leads/components/credit-from/components/finances-form';
import { PassportForm } from '@/components/leads/components/credit-from/components/passport-form';
import { FamilyForm } from '@/components/leads/components/credit-from/components/family-form';
import { WorkAndEducationForm } from '@/components/leads/components/credit-from/components/word-and-edication-form';
import { TCoBorrowersForm } from '@/components/leads/components/credit-from/types';
import { InputInitials } from '@/common/ui/input-initials';
import { makeInitialsError_LEGACY } from '@/components/leads/components/credit-from/validation';
import { Grid } from '@/common/ui/grid';
import { Input } from '@/common/ui/input';
import { Dropdown } from '@/common/ui/dropdown';
import { Checkbox } from '@/common/ui/checkbox';


type TBorrowerFormProps = {
    index: number
}

export function BorrowerForm({ index }: TBorrowerFormProps) {
    const {
        control,
        formState: { errors },
        setValue,
        clearErrors,
        watch,
    } = useFormContext<TCoBorrowersForm>();

    const initialsChanged = watch(`borrowers.${ index }.initialsChanged`);

    const handlerChangeInitials: TUIEventHandler<any> = useCallback((e, { name, value }) => {
        setValue(name as any, value);
        clearErrors(name as any);
    }, [ setValue, clearErrors ]);

    return (
        <UIBlock.Group>
            <UIBlock title='Основные данные'>
                <Grid stackable>
                    <Grid.Row columns={ 3 }>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `borrowers.${ index }.type` }
                                render={ ({ field }) => (
                                    <Dropdown
                                        { ...field }
                                        label='Тип'
                                        error={ (errors.borrowers?.[index]?.type as FieldError)?.message }
                                        options={ Array.from(MBorrowerType).map(([ value, text ]) => ({
                                            text: text,
                                            value: value,
                                        })) }
                                        required
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row columns={ 1 }>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `borrowers.${ index }.initials` }
                                render={ ({ field }) => (
                                    <InputInitials
                                        { ...field }
                                        className='mb1'
                                        label='ФИО'
                                        onChange={ (_, props) => handlerChangeInitials(null, props) }
                                        error={ makeInitialsError_LEGACY(`borrowers.${ index }.initials`, errors) }
                                        value={ field.value || {} as any }
                                        required
                                        clearable
                                    />
                                ) }
                            />
                            <Controller
                                control={ control }
                                name={ `borrowers.${ index }.initialsChanged` }
                                render={ ({ field }) => (
                                    <Checkbox
                                        label='ФИО менялись'
                                        checked={ field.value }
                                        onChange={ (e, { checked }) => {
                                            field.onChange(checked);
                                        } }
                                    />
                                ) }
                            />
                        </Grid.Col>
                        { initialsChanged && (
                            <Grid.Col fluid>
                                <Controller
                                    control={ control }
                                    name={ `borrowers.${ index }.oldInitials` }
                                    render={ ({ field }) => (
                                        <InputInitials
                                            label='Прежние ФИО'
                                            { ...field }
                                            onChange={ (_, props) => handlerChangeInitials(null, props) }
                                            error={ makeInitialsError_LEGACY(`borrowers.${ index }.oldInitials`, errors) }
                                            value={ field.value || {} as any }
                                            required
                                            clearable
                                        />
                                    ) }
                                />
                            </Grid.Col>
                        ) }
                    </Grid.Row>
                    <Grid.Row columns={ 3 }>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `borrowers.${ index }.phone` }
                                render={ ({ field }) => (
                                    <Input
                                        { ...field }
                                        type='tel'
                                        label='Мобильный телефон'
                                        error={ (errors.borrowers?.[index]?.phone as FieldError)?.message }
                                        mask='+7 (999) 999-99-99'
                                        placeholder='+7 (000) 000-00-00'
                                        required
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                    </Grid.Row>
                </Grid>
            </UIBlock>

            <UIBlock title='Паспортные данные'>
                <PassportForm
                    path={ `borrowers.${ index }.passport` }
                />
            </UIBlock>
            <UIBlock title='Семейное положение'>
                <FamilyForm path={ `borrowers.${ index }.family` }/>
            </UIBlock>

            <UIBlock title='Сведения о трудоустройстве'>
                <WorkAndEducationForm path={ `borrowers.${ index }.workAndEducation` }/>
            </UIBlock>

            <UIBlock title='Финансы'>
                <FinancesForm path={ `borrowers.${ index }.finances` }/>
            </UIBlock>
        </UIBlock.Group>
    );
}
