import { round } from 'lodash';

type IDeclFunction = (val: number, titles: [string, string, string]) => string;

interface IDecl extends IDeclFunction {
    years?: (val: number) => string;
    months?: (val: number) => string;
    days?: (val: number) => string;
}

const decl: IDeclFunction = (val: number, titles: [string, string, string]): string => {
    const cases = [ 2, 0, 1, 1, 1, 2 ];
    return titles[(val % 100 > 4 && val % 100 < 20)
        ? 2
        : cases[(val % 10 < 5) ? val % 10 : 5]];
};

const declYears = (val: number) =>
    `${round(val, 2)} ${decl(Math.ceil(val), [ 'год', 'года', 'лет' ])}`;

const declMonth = (val: number) =>
    `${round(val, 2)} ${decl(round(val, 2), [ 'мес', 'мес', 'мес' ])}`;

const declDay = (val: number) =>
    `${val} ${decl(val, [ 'день', 'дня', 'дней' ])}`;

const declCompose:IDecl = decl;

declCompose.years = declYears;
declCompose.months = declMonth;
declCompose.days = declDay;

export {
    declCompose as default,
    decl,
    declYears,
    declMonth,
    declDay,
}
