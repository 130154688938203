import React from 'react';

export function extractString(obj: any) {
    if (!obj) {
        return '';
    }
    if (typeof obj === 'string') {
        return obj;
    }
    if (Array.isArray(obj)) {
        return obj.map((e) => extractString(e)).join(' ');
    }
    if (React.isValidElement(obj)) {
        return extractString((obj.props as any).children);
    }
    return obj.toString();
}