import deepmerge from 'deepmerge';
import { FieldErrorsImpl, Merge, FieldError } from 'react-hook-form';

import { FORMAT } from 'finbox-ui-kit/consts';
import { Validator } from 'finbox-ui-kit/libs/validator';
import { TInitials } from 'finbox-ui-kit/types';
import { Scheme, SchemeScheme } from '@/utils';
import { ECreditFormType } from '@/components/leads/components/credit-from/consts';
import { TUser } from '@/types/manager';
import { TStepCodes } from '@/components/leads/components/client-profile-page';

// const CREDIT_SCHEME: SchemeScheme = {
//
// };

const FINANCES_SCHEME: SchemeScheme = {
    averageMonthlyIncome: {
        type: Scheme.SCHEME_RULE_TYPES.INT,
        required: true,
    },
    wage: {
        type: Scheme.SCHEME_RULE_TYPES.INT,
        required: true,
    },
    primeTimeJobWage: {
        type: Scheme.SCHEME_RULE_TYPES.INT,
        required: false,
    },
    otherIncome: {
        type: Scheme.SCHEME_RULE_TYPES.INT,
        required: false,
    },
    proofOfJob: {
        type: Scheme.SCHEME_RULE_TYPES.STRING,
        required: true,
    },
    proofOfIncome: {
        type: Scheme.SCHEME_RULE_TYPES.STRING,
        required: true,
    },
    expenses: {
        type: Scheme.SCHEME_RULE_TYPES.ARRAY,
        required: false,
        items: {
            type: Scheme.SCHEME_RULE_TYPES.OBJECT,
            scheme: {
                type: {
                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                    required: true,
                },
                amount: {
                    type: Scheme.SCHEME_RULE_TYPES.INT,
                    required: true,
                },
                additionalAmount: {
                    type: Scheme.SCHEME_RULE_TYPES.INT,
                    required: false,
                },
            },
        },
    },
};

const PERSONAL_SCHEME: SchemeScheme = {
    personal: {
        type: Scheme.SCHEME_RULE_TYPES.OBJECT,
        scheme: {
            oldInitials: {
                type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                required: (data) => data.initialsChanged,
                scheme: {
                    surname: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                    },
                    name: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                    },
                    patronymic: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: false,
                    },
                },
            },
            passport: {
                type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                scheme: {
                    birthday: {
                        type: Scheme.SCHEME_RULE_TYPES.DATE,
                        format: FORMAT.DATE,
                        required: true,
                        errors: {
                            format: 'Некорректный формат даты',
                        },
                    },
                    serialAndNumber: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        regex: /^[0-9]{4} [0-9]{6}$/,
                        required: true,
                    },
                    dateOfIssue: {
                        type: Scheme.SCHEME_RULE_TYPES.DATE,
                        required: true,
                        format: FORMAT.DATE,
                        errors: {
                            format: 'Некорректный формат даты',
                        },
                    },
                    departmentCode: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        regex: /^[0-9]{3}-[0-9]{3}$/,
                        required: true,
                    },
                    department: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: true,
                    },
                    birthplace: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: true,
                    },
                    registrationAddress: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: true,
                    },
                    registrationDate: {
                        type: Scheme.SCHEME_RULE_TYPES.DATE,
                        required: true,
                        format: FORMAT.DATE,
                        errors: {
                            format: 'Некорректный формат даты',
                        },
                    },
                },
            },
            address: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
                required: true,
            },
            addressBasis: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
                required: true,
            },
            snils: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
                regex: /^[0-9]{3}-[0-9]{3}-[0-9]{3} [0-9]{2}$/,
                required: false,
            },
        },
    },
    workAndEducation: {
        type: Scheme.SCHEME_RULE_TYPES.OBJECT,
        scheme: {
            education: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
                required: true,
            },
        },
    },
    family: {
        type: Scheme.SCHEME_RULE_TYPES.OBJECT,
        scheme: {
            status: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
                required: true,
            },
            amountOfMinorChildren: {
                type: Scheme.SCHEME_RULE_TYPES.INT,
            },
            children: {
                type: Scheme.SCHEME_RULE_TYPES.ARRAY,
                required:false,
                items: {
                    type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                    scheme: {
                        initials: {
                            type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                            scheme: {
                                surname: {
                                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                                    required: true,
                                },
                                name: {
                                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                                    required: true,
                                },
                                patronymic: {
                                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                                    required: false,
                                },
                            },
                        },
                        birthday: {
                            type: Scheme.SCHEME_RULE_TYPES.DATE,
                            format: FORMAT.DATE,
                            required: true,
                            errors: {
                                format: 'Некорректный формат даты',
                            },
                        },
                    },
                },
            },
            cohabitation: {
                type: Scheme.SCHEME_RULE_TYPES.BOOLEAN,
                required: (data, { additionalData }) => additionalData.fields.family.status === 'Женат/замужем',
            },
            contract: {
                type: Scheme.SCHEME_RULE_TYPES.BOOLEAN,
                required: (data, { additionalData }) => additionalData.fields.family.status === 'Женат/замужем',
            },
            pairData: {
                type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                required: false,
                scheme: {
                    initials: {
                        type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                        scheme: {
                            surname: {
                                type: Scheme.SCHEME_RULE_TYPES.STRING,
                                required: false,
                            },
                            name: {
                                type: Scheme.SCHEME_RULE_TYPES.STRING,
                                required: false,
                            },
                            patronymic: {
                                type: Scheme.SCHEME_RULE_TYPES.STRING,
                                required: false,
                            },
                        },
                    },
                    birthday: {
                        type: Scheme.SCHEME_RULE_TYPES.DATE,
                        format: FORMAT.DATE,
                        required: false,
                        errors: {
                            format: 'Некорректный формат даты',
                        },
                    },
                    phone: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        regex: /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/,
                        validator: Validator.phone,
                        required: false,
                    },
                },
            },
        },
    },
    contacts: {
        type: Scheme.SCHEME_RULE_TYPES.ARRAY,
        required: false,
        items: {
            type: Scheme.SCHEME_RULE_TYPES.OBJECT,
            required: true,
            scheme: {
                initials: {
                    type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                    required: true,
                    scheme: {
                        surname: {
                            type: Scheme.SCHEME_RULE_TYPES.STRING,
                            required: true,
                        },
                        name: {
                            type: Scheme.SCHEME_RULE_TYPES.STRING,
                            required: true,
                        },
                        patronymic: {
                            type: Scheme.SCHEME_RULE_TYPES.STRING,
                            required: false,
                        },
                    },
                },
                phone: {
                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                    required: true,
                    validator: Validator.phone,
                },
            },
        },
    },
};

const CLIENT_SCHEME: SchemeScheme = {
    client: {
        type: Scheme.SCHEME_RULE_TYPES.OBJECT,
        scheme: {
            products: {
                type: Scheme.SCHEME_RULE_TYPES.ARRAY,
                required: true,
                items: {
                    type: Scheme.SCHEME_RULE_TYPES.INT,
                    required: true,
                },
                minLength: 1,
            },
            initials: {
                type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                required: true,
                scheme: {
                    surname: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: true,
                    },
                    name: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: true,
                    },
                    patronymic: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: false,
                    },
                },
            },
            phone: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
                required: true,
                validator: Validator.phone,
            },
            contactPhone: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
                validator: Validator.phone,
                required: false,
            },
            email: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
                validator: Validator.email,
                required: false,
            },
            sum: {
                type: Scheme.SCHEME_RULE_TYPES.INT,
                min: 100000,
                required: true,
            },
            period: {
                type: Scheme.SCHEME_RULE_TYPES.INT,
                min: 1,
                required: true,
            },
            manager: {
                type: Scheme.SCHEME_RULE_TYPES.INT,
                required: (data, { additionalData }) => additionalData.user.admin,
            },
            comment: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
            },
        },
    },
    credit: {
        type: Scheme.SCHEME_RULE_TYPES.OBJECT,
        scheme: {
            purpose: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
            },
            estateType: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
                required: (data) => data['purpose'] === 'Приобретение недвижимости',
            },
            estatePhase: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
                required: (data) => data['purpose'] === 'Приобретение недвижимости',
            },
            loanSecurity: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
            },
            subsidies: {
                type: Scheme.SCHEME_RULE_TYPES.BOOLEAN,
                required: (data, { additionalData }) => additionalData.formType === ECreditFormType.MORTGAGE,
            },
            initialPaymentSum: {
                type: Scheme.SCHEME_RULE_TYPES.INT,
                required: (data, { additionalData }) => additionalData.formType === ECreditFormType.MORTGAGE,
                min: 1,
            },
            initialPaymentInclude: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
                required: false,
            },
            insurance: {
                type: Scheme.SCHEME_RULE_TYPES.BOOLEAN,
                required: false,
            },
        },
    },
};

const PLEDGE_OBJECT_SCHEME: SchemeScheme = {
    pledgeObjects: {
        type: Scheme.SCHEME_RULE_TYPES.ARRAY,
        minLength: 1,
        required: true,
        items: {
            type: Scheme.SCHEME_RULE_TYPES.OBJECT,
            scheme: {
                address: {
                    type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                    required: true,
                    scheme: {},
                },
                cadNumber: {
                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                },
                type: {
                    type: Scheme.SCHEME_RULE_TYPES.INT,
                    required: true,
                },
                status: {
                    type: Scheme.SCHEME_RULE_TYPES.BOOLEAN,
                },
                regDate: {
                    type: Scheme.SCHEME_RULE_TYPES.DATE,
                    format: FORMAT.DATE,
                },
                infoUpdateDate: {
                    type: Scheme.SCHEME_RULE_TYPES.DATE,
                    format: FORMAT.DATE,
                },
                objType: {
                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                },
                levelFloor: {
                    type: Scheme.SCHEME_RULE_TYPES.INT,
                },
                area: {
                    type: Scheme.SCHEME_RULE_TYPES.FLOAT,
                },
                cadCost: {
                    type: Scheme.SCHEME_RULE_TYPES.INT,
                },
                marketPrice: {
                    type: Scheme.SCHEME_RULE_TYPES.INT,
                },
                cadCostDeterminationDate: {
                    type: Scheme.SCHEME_RULE_TYPES.DATE,
                    format: FORMAT.DATE,
                },
                cadCostRegistrationDate: {
                    type: Scheme.SCHEME_RULE_TYPES.DATE,
                    format: FORMAT.DATE,
                },
                purpose: {
                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                },
                rights: {
                    type: Scheme.SCHEME_RULE_TYPES.ARRAY,
                    items: {
                        type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                        scheme: {
                            rightNumber: {
                                type: Scheme.SCHEME_RULE_TYPES.STRING,
                            },
                            rightTypeDesc: {
                                type: Scheme.SCHEME_RULE_TYPES.STRING,
                            },
                            rightRegDate: {
                                type: Scheme.SCHEME_RULE_TYPES.DATE,
                                format: FORMAT.DATE,
                            },
                        },
                    },
                },
                encumbrances: {
                    type: Scheme.SCHEME_RULE_TYPES.ARRAY,
                    items: {
                        type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                        scheme: {
                            encumbranceNumber: {
                                type: Scheme.SCHEME_RULE_TYPES.STRING,
                                required: false,
                            },
                            typeDesc: {
                                type: Scheme.SCHEME_RULE_TYPES.STRING,
                            },
                            startDate: {
                                type: Scheme.SCHEME_RULE_TYPES.DATE,
                                format: FORMAT.DATE,
                                required: false,
                            },
                        },
                    },
                },
                documents: {
                    type: Scheme.SCHEME_RULE_TYPES.ARRAY,
                    items: {
                        type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                        scheme: {
                            type: {
                                type: Scheme.SCHEME_RULE_TYPES.STRING,
                                required: false,
                            },
                        },
                    },
                },
            },
        },
    },
};

const notComplexFormList = [ 'Самозанятый', 'Пенсионер', 'Нетрудоустроенный', 'Неофициальный бизнес' ];

const WORK_AND_EDUCATION: SchemeScheme = {
    workAndEducation: {
        type: Scheme.SCHEME_RULE_TYPES.OBJECT,
        scheme: {
            employmentType: {
                type: Scheme.SCHEME_RULE_TYPES.STRING,
                required: true,
            },
            company: {
                type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
                scheme: {
                    activityType: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
                    },
                    name: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
                    },
                    inn: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
                        validator: Validator.inn,
                    },
                    ogrn: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
                    },
                    address: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
                    },
                    scope: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
                    },
                    phone: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
                        validator: Validator.phone,
                    },
                    employeesCount: {
                        type: Scheme.SCHEME_RULE_TYPES.INT,
                        required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
                    },
                },
            },
            employee: {
                type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
                scheme: {
                    position: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
                    },
                    startDate: {
                        type: Scheme.SCHEME_RULE_TYPES.DATE,
                        format: 'MM.yyyy',
                        required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
                    },
                    seniority: {
                        type: Scheme.SCHEME_RULE_TYPES.STRING,
                        required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
                    },
                },
            },
            primeTimeJob: {
                type: Scheme.SCHEME_RULE_TYPES.BOOLEAN,
                required: (data, { additionalData }) => !notComplexFormList.includes(additionalData.fields.workAndEducation.employmentType),
            },
        },
    },
};

const THIRD_PARTY_PERSONS: SchemeScheme = {
    borrowers: {
        type: Scheme.SCHEME_RULE_TYPES.ARRAY,
        items: {
            type: Scheme.SCHEME_RULE_TYPES.OBJECT,
            scheme: {
                phone: {
                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                    regex: /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/,
                    required: (data, { additionalData }) => additionalData.fields.family.status === 'Женат/замужем',
                    validator: Validator.phone,
                },
                type: {
                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                    required: true,
                },
                initials: {
                    type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                    required: true,
                    scheme: {
                        surname: {
                            type: Scheme.SCHEME_RULE_TYPES.STRING,
                            required: true,
                        },
                        name: {
                            type: Scheme.SCHEME_RULE_TYPES.STRING,
                            required: true,
                        },
                        patronymic: {
                            type: Scheme.SCHEME_RULE_TYPES.STRING,
                            required: false,
                        },
                    },
                },
                birthday: {
                    type: Scheme.SCHEME_RULE_TYPES.DATE,
                    format: FORMAT.DATE,
                    required: true,
                    errors: {
                        format: 'Некорректный формат даты',
                    },
                },
                passport: {
                    type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                    scheme: {
                        serialAndNumber: {
                            type: Scheme.SCHEME_RULE_TYPES.STRING,
                            regex: /^[0-9]{4} [0-9]{6}$/,
                            required: true,
                        },
                        dateOfIssue: {
                            type: Scheme.SCHEME_RULE_TYPES.DATE,
                            required: true,
                            format: FORMAT.DATE,
                            errors: {
                                format: 'Некорректный формат даты',
                            },
                        },
                        departmentCode: {
                            type: Scheme.SCHEME_RULE_TYPES.STRING,
                            regex: /^[0-9]{3}-[0-9]{3}$/,
                            required: true,
                        },
                        department: {
                            type: Scheme.SCHEME_RULE_TYPES.STRING,
                            required: true,
                        },
                        registrationAddress: {
                            type: Scheme.SCHEME_RULE_TYPES.STRING,
                            required: true,
                        },
                        registrationDate: {
                            type: Scheme.SCHEME_RULE_TYPES.DATE,
                            required: true,
                            format: FORMAT.DATE,
                            errors: {
                                format: 'Некорректный формат даты',
                            },
                        },
                    },
                },
                address: {
                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                    required: true,
                },
                snils: {
                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                    regex: /^[0-9]{3}-[0-9]{3}-[0-9]{3} [0-9]{2}$/,
                    required: false,
                },
                education: {
                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                    required: true,
                },
                familyStatus: {
                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                    required: true,
                },
                cohabitation: {
                    type: Scheme.SCHEME_RULE_TYPES.BOOLEAN,
                    required: (data) => data.familyStatus === 'Женат/замужем',
                },
                contract: {
                    type: Scheme.SCHEME_RULE_TYPES.BOOLEAN,
                    required: (data) => data.familyStatus === 'Женат/замужем',
                },
                pairData: {
                    type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                    required: (data) => data.familyStatus === 'Женат/замужем',
                    scheme: {
                        initials: {
                            type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                            required: (data, { additionalData }) => additionalData.fields.family.status === 'Женат/замужем',
                            scheme: {
                                surname: {
                                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                                    required: (data, { additionalData }) => additionalData.fields.family.status === 'Женат/замужем',
                                },
                                name: {
                                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                                    required: (data, { additionalData }) => additionalData.fields.family.status === 'Женат/замужем',
                                },
                                patronymic: {
                                    type: Scheme.SCHEME_RULE_TYPES.STRING,
                                    required: false,
                                },
                            },
                        },
                        birthday: {
                            type: Scheme.SCHEME_RULE_TYPES.DATE,
                            format: FORMAT.DATE,
                            required: (data, { additionalData }) => additionalData.fields.family.status === 'Женат/замужем',
                            errors: {
                                format: 'Некорректный формат даты',
                            },
                        },
                        phone: {
                            type: Scheme.SCHEME_RULE_TYPES.STRING,
                            regex: /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/,
                            required: (data, { additionalData }) => additionalData.fields.family.status === 'Женат/замужем',
                            validator: Validator.phone,
                        },
                    },
                },
                finances: {
                    type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                    scheme: FINANCES_SCHEME,
                    required: true,
                },
            },
        },
    },
};


const FULL_FORM_SCHEME: SchemeScheme = deepmerge.all<SchemeScheme>([
    CLIENT_SCHEME,
    PERSONAL_SCHEME,
    PLEDGE_OBJECT_SCHEME,
    WORK_AND_EDUCATION,
]);

const ORDER_SCHEME: SchemeScheme = {
    ...CLIENT_SCHEME,
    ...PLEDGE_OBJECT_SCHEME,
};


export const validateStep = (prefix: TStepCodes, fields, formType: ECreditFormType, user?: TUser) => {
    let scheme;

    switch (prefix) {
        case 'finances':
            // scheme = FINANCES_SCHEME;
            scheme = {
                finances: {
                    type: Scheme.SCHEME_RULE_TYPES.OBJECT,
                    scheme: FINANCES_SCHEME,
                },
            };
            break;
        case 'personal':
            scheme = PERSONAL_SCHEME;
            break;
        case 'client':
            scheme = CLIENT_SCHEME;
            break;
        case 'pledgeObjects':
            scheme = PLEDGE_OBJECT_SCHEME;
            break;
        case 'workAndEducation':
            scheme = WORK_AND_EDUCATION;
            break;
        case 'third-party-persons':
            scheme = THIRD_PARTY_PERSONS;
            break;
        default:
            throw new Error('Incorrect prefix');
    }

    return Scheme.validate(
        scheme,
        fields,
        {
            additionalData: {
                fields,
                formType,
                user,
            },
        },
    );
}


export const validateFullForm = (fields, formType: ECreditFormType, user) => Scheme.validate(
    FULL_FORM_SCHEME,
    fields,
    {
        additionalData: {
            fields,
            formType,
            user,
        },
    },
);

export const validateFullOrder = (fields, formType: ECreditFormType, user) => Scheme.validate(
    ORDER_SCHEME,
    fields,
    {
        additionalData: {
            fields,
            formType,
            user,
        },
    },
);

export function makeInitialsError_LEGACY(fieldName: string, errors: string | Record<string, any>): null | Merge<FieldError, FieldErrorsImpl<TInitials>> {
    if (errors[fieldName]) {
        return {
            message: errors[fieldName],
        };
    }
    if (errors[`${fieldName}.name`] || errors[`${fieldName}.surname`] || errors[`${fieldName}.patronymic`]) {
        return {
            name: { type: 'any', message: errors[`${fieldName}.name`] },
            surname: { type: 'any', message: errors[`${fieldName}.surname`] },
            patronymic: { type: 'any', message: errors[`${fieldName}.patronymic`] },
        };
    }
    return null;
}