import yup from '@/utils/yup-config';
import { TPrimeTimeJobCompanyForm } from '@/components/leads/components/credit-from/types';
import { addressScheme } from '@/components/leads/components/credit-from/schemas/address.scheme';

export const primeTimeJobCompanyScheme = yup.object<TPrimeTimeJobCompanyForm>({
    name: yup.string().required(),
    inn: yup.string().required(),
    ogrn: yup.string(),
    address: yup.mixed()
        .when('mixedAddress', {
            is: (value) => typeof value === 'string',
            then: () => yup.string().required(),
            otherwise: () => addressScheme.required(),
        }),
    phone: yup.string().required(),
    jobType: yup.string().required(),
    startDate: yup.string().required(),
});