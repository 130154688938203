import React from 'react';
import cn from 'classnames';
import styles from './radio.module.scss';


type UIRadioProps = {
    name: string
    checked: boolean
    label?: React.ReactNode
    value: any
    onChange: (e: React.ChangeEvent<HTMLInputElement>, data: { name: string, checked: boolean, value: any }) => void
    disabled?: boolean
    className?: string
};

export const UIRadio = React.forwardRef<HTMLDivElement, UIRadioProps>(function UIRadio(
    { name, label, value, checked, disabled, onChange, className },
    ref,
) {
    const handlerChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        onChange(e, {
            name,
            value,
            checked: e.target.checked,
        });
    };
    return (
        <div ref={ ref } className={ cn(styles.FUIRadio, className, {
            [styles.FUIRadioDisabled]: disabled,
        }) }>
            <label>
                <input
                    type='radio'
                    name={ name }
                    checked={ checked }
                    onChange={ handlerChange }
                    disabled={ disabled }
                />
                <div className={ styles.FUIRadioLabelText }>
                    { label }
                </div>
            </label>
        </div>
    );
});