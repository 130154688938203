import yup from '@/utils/yup-config';
import { initialsScheme } from '@/components/leads/components/credit-from/schemas/initials.scheme';
import { TFamilyForm } from '@/components/leads/components/credit-from/types';

export const familyScheme = yup.object<TFamilyForm>({
    status: yup.string().required(),
    cohabitation: yup.boolean().when('status', {
        is: 'Женат/замужем',
        then: (schema) => schema.required(),
    }),
    contract: yup.boolean().when('status', {
        is: 'Женат/замужем',
        then: (schema) => schema.required(),
    }),
    pairData: yup.object().when('status', {
        is: 'Женат/замужем',
        then: () => yup.object({
            initials: initialsScheme.required(),
            birthday: yup.string().required(),
            phone: yup.string().required(),
        }).required(),
    }),
});