import { TInputComplex } from '@/common/ui/input/input.types';
import { Input } from './input'
import { InputControlled } from './input-controlled';

(Input as TInputComplex).Controlled = InputControlled;
const InputComplex: TInputComplex = Input as TInputComplex;

export {
    InputComplex as Input,
    InputControlled,
};