import React from 'react';
import cn from 'classnames';
import { TUILabelComponent, TUILabelGroupProps, UILabelProps } from './label';
import styles from './label.module.scss';


const UILabel: TUILabelComponent = React.forwardRef<HTMLSpanElement, UILabelProps>(function UILabel({
    className,
    children,
    content,
    hover,
    color = 'grey',
}, ref) {
    return (
        <span ref={ ref } className={ cn(styles.FUILabel, className, {
            [`-${ color }`]: color,
            [styles.FUILabelHover]: hover,
        }) }>
            { children || content }
        </span>
    );
});

export function UILabelGroup({ className, children, onClick }: TUILabelGroupProps) {
    return (
        <div className={ cn(styles.FUILabelGroup, className) } onClick={ onClick }>{ children }</div>
    );
}

UILabel.Group = UILabelGroup;

export { UILabel };