import React, { useEffect } from 'react';
import { Controller, useFormContext, FieldError } from 'react-hook-form';
import get from 'lodash/get';
import sum from 'lodash/sum';
import { formatPrice } from '@/utils';
import { MProofOfIncome, MProofOfJob } from '@/components/leads/components/credit-from/consts';
import { TFinancesForm } from '@/components/leads/components/credit-from/types';
import { Input } from '@/common/ui/input';
import { Grid } from '@/common/ui/grid';
import { Dropdown } from '@/common/ui/dropdown';

type FinancesFormType = any & {
    [x: string]: TFinancesForm
}

type FinancesFormProps = {
    path: string
}
export function FinancesForm({ path }: FinancesFormProps) {
    const {
        control,
        formState: { errors },
        setValue,
        watch,
        clearErrors,
    } = useFormContext<FinancesFormType>();

    const otherIncome = watch(`${path}.otherIncome`);
    const primeTimeJobWage = watch(`${path}.primeTimeJobWage`);
    const wage = watch(`${path}.wage`);

    useEffect(() => {
        if ([
            otherIncome,
            primeTimeJobWage,
            wage,
        ].some((i) => i)) {
            setValue(
                `${path}.averageMonthlyIncome`,
                sum([
                    otherIncome || 0,
                    primeTimeJobWage || 0,
                    wage || 0,
                ]),
            );
        }
        clearErrors(`${path}.averageMonthlyIncome`)
    }, [ clearErrors, otherIncome, path, primeTimeJobWage, setValue, wage ]);

    return (
        <Grid stackable>
            <Grid.Row columns={ 3 }>
                <Controller
                    control={ control }
                    name={`${path}.wage`}
                    render={ ({ field }) => (
                        <Input
                            { ...field }
                            type='number'
                            label='Заработная плата на основном месте'
                            error={ (get(errors, `${path}.wage`) as FieldError)?.message}
                            postfix='руб.'
                            renderValue={ (value) => formatPrice(value, false) }
                            required
                        />
                    ) }
                />
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={`${path}.primeTimeJobWage`}
                        render={ ({ field }) => (
                            <Input
                                { ...field }
                                type='number'
                                label='Заработная плата по совместительству'
                                error={ (get(errors, `${path}.primeTimeJobWage`) as FieldError)?.message}
                                postfix='руб.'
                                renderValue={ (value) => formatPrice(value, false) }
                            />
                        ) }
                    />
                </Grid.Col>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={`${path}.otherIncome`}
                        render={ ({ field }) => (
                            <Input
                                { ...field }
                                type='number'
                                label='Иные доходы'
                                error={ (get(errors, `${path}.otherIncome`) as FieldError)?.message}
                                postfix='руб.'
                                renderValue={ (value) => formatPrice(value, false) }
                            />
                        ) }
                    />
                </Grid.Col>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={`${path}.averageMonthlyIncome`}
                        render={ ({ field }) => (
                            <Input
                                { ...field }
                                type='number'
                                label='Среднемесячный доход за 12 месяцев'
                                error={ (get(errors, `${path}.averageMonthlyIncome`) as FieldError)?.message}
                                postfix='руб.'
                                renderValue={ (value) => formatPrice(value, false) }
                                required
                            />
                        ) }
                    />
                </Grid.Col>

                <Grid.Col>
                    <Controller
                        control={ control }
                        name={`${path}.proofOfIncome`}
                        render={ ({ field }) => (
                            <Dropdown
                                { ...field }
                                label='Форма подтверждения дохода'
                                options={MProofOfIncome.map((item) => ({
                                    text: item,
                                    value: item,
                                }))}
                                error={ (get(errors, `${path}.proofOfIncome`) as FieldError)?.message}
                                required
                                filtering
                            />
                        ) }
                    />
                </Grid.Col>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={`${path}.proofOfJob`}
                        render={ ({ field }) => (
                            <Dropdown
                                { ...field }
                                label='Вид подтверждения занятости'
                                options={MProofOfJob.map((item) => ({
                                    text: item,
                                    value: item,
                                }))}
                                error={ (get(errors, `${path}.proofOfJob`) as FieldError)?.message}
                                required
                                filtering
                            />
                        ) }
                    />
                </Grid.Col>
            </Grid.Row>
        </Grid>
    );
}
