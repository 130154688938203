export const formatPhoneNumber = (rawValue: string): string => {
    if (rawValue === null || rawValue === undefined) {
        return null;
    }
    const match = String(rawValue).match(/[0-9]/g);
    let value: string = (match) ? match.join('') : '';

    if (value.length === 10) {
        value = '7' + value;
    } else if (value.length === 11 && value[0] === '8') {
        value = '7' + value.substr(1, 12);
    }

    return value.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($2) $3-$4-$5');
};