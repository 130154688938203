import React, { ChangeEvent, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FieldErrorsImpl, Merge, FieldError } from 'react-hook-form';
import { UIMessage } from 'finbox-ui-kit';
import { TInitials } from 'finbox-ui-kit/types';
import { useDebounce } from 'finbox-ui-kit/utils/hooks';
import { formatInitials } from 'finbox-ui-kit/utils';
import { TInputChangeData, TInputProps } from '@/common/ui/input/input.types';
import { Input } from '@/common/ui/input';
import { Grid } from '@/common/ui/grid';
import { InputSuggestions, InputSuggestionsOptions } from '@/common/ui/input-suggestions';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { API_URLS } from '@/consts';


type InputInitialsProps = Omit<TInputProps, 'value' | 'onChange' | 'error'> & {
    value: TInitials | null;
    onChange(value: TInitials, data: { name: string; value: TInitials | null }): void;
    error?: Merge<FieldError, FieldErrorsImpl<TInitials>>;
};

export const InputInitials = React.forwardRef<HTMLInputElement, InputInitialsProps>(function InputInitials(
    { loading, error, className, ...inputProps },
    forwardRef,
): React.ReactElement {
    const suggestionsInputRef = useRef<HTMLInputElement | null>(null);
    const nameInputRef = useRef<HTMLInputElement | null>(null);
    const { debounce } = useDebounce({ timeout: 300 });
    const [ suggestionInputValue, setSuggestionInputValue ] = useState('');
    const [ suggestions, setSuggestions ] = useState([]);
    const [ partial, setPartial ] = useState(false);
    const [ changed, setChanged ] = useState(false);
    const [ warningShowed, setWarningShowed ] = useState(false);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
        forwardRef,
        () => partial ? nameInputRef.current : suggestionsInputRef.current,
        [ partial ],
    );

    useEffect(() => {
        if (error && !(error.message)) {
            setPartial(true);
        }
    }, [ error ]);

    useEffect(() => {
        if (inputProps.value) {
            setSuggestionInputValue(formatInitials(inputProps.value));
        }
    }, [ inputProps.value ]);

    const { fetch, loading: loadingSuggestions } = useApiClient({
        url: API_URLS.DICTIONARIES.DADATA.INITIALS,
        successMessage: null,
    });

    const { fetch: fetchClean, loading: loadingClean } = useApiClient({
        url: API_URLS.DICTIONARIES.DADATA.INITIALS_CLEAN,
        successMessage: null,
    });

    function handlerChangeSuggestionsInput(value: string, { option }: InputSuggestionsOptions) {
        setSuggestionInputValue(value);
        setChanged(true);
        if (!value) {
            inputProps.onChange(null, { name: inputProps.name, value: null });
            return;
        }
        if (option) {
            const _val = {
                name: option.data.data.name,
                surname: option.data.data.surname,
                patronymic: option.data.data.patronymic,
            };
            inputProps.onChange(_val, { name: inputProps.name, value: _val });
        } else {
            debounce(async () => {
                const { suggestions } = await fetch({
                    query: value,
                });
                setSuggestions(suggestions.map((item) => ({
                    text: item.value,
                    value: item.value,
                    data: item,
                })));
            });
        }
    }

    async function handlerBlurSuggestionsInput() {
        if (changed && suggestionInputValue) {
            const response = await fetchClean({
                query: suggestionInputValue,
            });
            const [ clean ] = response.data;
            const _val = {
                name: clean.name,
                surname: clean.surname,
                patronymic: clean.patronymic,
            };
            if (clean.qc !== 0) {
                setPartial(true);
                setWarningShowed(true);
            }
            inputProps.onChange(_val, { name: inputProps.name, value: _val });
        }
    }

    function handlerChangeField(e: ChangeEvent<HTMLInputElement>, { name: fieldName }: TInputChangeData<string>) {
        const name = fieldName.split('.').pop();
        const _val = {
            ...inputProps.value,
            [name]: e.target.value,
        };
        inputProps.onChange(_val, { name: inputProps.name, value: _val });
    }

    async function handlerBlurField() {
        if (inputProps.value) {
            const _val = {
                name: inputProps.value.name?.trim(),
                surname: inputProps.value.surname?.trim(),
                patronymic: inputProps.value.patronymic?.trim(),
            };
            inputProps.onChange(_val, { name: inputProps.name, value: _val });
        }
    }

    return (
        <div className={ className }>
            { !partial && (
                <InputSuggestions
                    ref={ suggestionsInputRef }
                    name={ inputProps.name }
                    label={ inputProps.label }
                    value={ suggestionInputValue }
                    onChange={ handlerChangeSuggestionsInput }
                    onBlur={ handlerBlurSuggestionsInput }
                    suggestions={ suggestions }
                    loading={ loading || loadingSuggestions || loadingClean }
                    autoComplete={ inputProps.autoComplete }
                    filtering={ false }
                    error={ error?.message }
                    clearable={ inputProps.clearable }
                    required={ inputProps.required }
                    selectOnNavigation
                />
            ) }
            { partial && (
                <Grid stackable>
                    <Grid.Row columns={ 3 }>
                        <Grid.Col>
                            <Input
                                ref={ nameInputRef }
                                name={ `${ inputProps.name }.surname` }
                                label='Фамилия'
                                value={ inputProps.value.surname }
                                onChange={ handlerChangeField }
                                onBlur={ handlerBlurField }
                                error={ error?.surname?.message }
                                clearable={ inputProps.clearable }
                                required={ inputProps.required }
                                disabled={ inputProps.disabled }
                                autoComplete={ inputProps.autoComplete }
                                loading={ loading }
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Input
                                name={ `${ inputProps.name }.name` }
                                label='Имя'
                                value={ inputProps.value.name }
                                onChange={ handlerChangeField }
                                onBlur={ handlerBlurField }
                                error={ error?.name?.message }
                                clearable={ inputProps.clearable }
                                required={ inputProps.required }
                                disabled={ inputProps.disabled }
                                autoComplete={ inputProps.autoComplete }
                                loading={ loading }
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Input
                                name={ `${ inputProps.name }.patronymic` }
                                label='Отчество'
                                value={ inputProps.value.patronymic }
                                onChange={ handlerChangeField }
                                onBlur={ handlerBlurField }
                                error={ error?.patronymic?.message }
                                clearable={ inputProps.clearable }
                                required={ inputProps.required }
                                disabled={ inputProps.disabled }
                                autoComplete={ inputProps.autoComplete }
                                loading={ loading }
                            />
                        </Grid.Col>
                    </Grid.Row>
                </Grid>
            ) }
            { warningShowed && (
                <UIMessage className='mt_5' color='orange'>Пожалуйста, проверьте правильность заполнения ФИО</UIMessage>
            ) }
        </div>
    );
});