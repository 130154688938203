import React from 'react';

import { TPageTabsProps } from './types';
import { useMediaQuery } from '../../utils/hooks';

const PageTabsDesktop = React.lazy(() => import('./desktop'));
const PageTabsMobile = React.lazy(() => import('./mobile'));


export const UIPageTabs: React.FC<TPageTabsProps> = (props) => {
    const isMobile = useMediaQuery('md');
    if (isMobile) {
        return (<PageTabsMobile { ...props }/>);
    }

    return (<PageTabsDesktop { ...props }/>);
}
