import React, { useEffect, useMemo, useState } from 'react';

interface ITimerProps {
    isActive: boolean;
    startTime?: number;
    isFinished?: boolean;
    isHoursShowed?: boolean;
    onTick?: (time: number) => void;
    doneText?: string;
}

const Timer = ({ isActive, isFinished, isHoursShowed, onTick, startTime, doneText }: ITimerProps) => {
    const [ time, setTime ] = useState(startTime || 0);

    useEffect(() => {
        let interval: NodeJS.Timer = null;
        if (isActive && !isFinished) {
            interval = setInterval(() => {
                setTime((time) => time + 1);
                if (onTick) {
                    onTick(time + 1);
                }
            }, 1000);
        } else if (!isActive && time !== 0) {
            setTime(0);
            clearInterval(interval);
        } else if (isFinished) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [ isActive, isFinished, time, onTick ]);

    const timeLeft = [
        Math.floor((time / 60) % 60),
        Math.floor(time % 60),
    ];

    if (isHoursShowed) {
        timeLeft.unshift(Math.floor((time / (60 * 60)) % 24));
    }

    const color = useMemo(() => {
        if (15 * 60 <= time && time < 30 * 60) {
            return 'yellow';
        } else if (30 * 60 <= time && time < 40 * 60) {
            return 'orange';
        } else if (40 * 60 <= time) {
            return 'red';
        }
        return 'green';
    }, [ time ]);

    return (
        <div className={`inlineBlock color-${color}`}>
            { timeLeft.map((i) => String(i).padStart(2, '0')).join(':') }
            { isFinished && doneText}
        </div>
    );
};

export {
    Timer,
};
