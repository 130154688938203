import React from 'react';
import { Link } from 'react-router-dom';
import { VERSION } from '@/consts';
import styles from './footer.module.scss';

export function Footer() {
    return (
        <footer className={styles.mainFooter}>
            <nav className={styles.mainFooterMenu}>
                <li>
                    <Link to='/help/'>Помощь</Link>
                </li>
                <li>
                    <a href='https://wiki.ffo24.ru/' target='_blank'>Wiki</a>
                </li>
            </nav>
            <div className={styles.mainFooterVersion}>
                { VERSION }
            </div>
        </footer>
    );
}