import React, { useEffect } from 'react';
import cn from 'classnames';
import { TUIIconBrandProps, TUIIconDefaultProps, IconProps } from './icon';
import { useIconsManager } from './useIconsManager';
import { TUIColor, TUISize } from '../../types/common';
import styles from './icon.module.scss';

const colorsMap: Record<TUIColor, string> = {
    white: styles.FUIIconWhite,
    red: styles.FUIIconRed,
    orange: styles.FUIIconOrange,
    yellow: styles.FUIIconYellow,
    olive: styles.FUIIconOlive,
    green: styles.FUIIconGreen,
    teal: styles.FUIIconTeal,
    blue: styles.FUIIconBlue,
    violet: styles.FUIIconViolet,
    purple: styles.FUIIconPurple,
    pink: styles.FUIIconPink,
    brown: styles.FUIIconBrown,
    grey: styles.FUIIconGrey,
    black: styles.FUIIconBlack,
};

const sizeMap: Record<Exclude<TUISize, 'normal'>, string> = {
    tiny: styles.FUIIconTiny,
    small: styles.FUIIconSmall,
    big: styles.FUIIconBig,
    large: styles.FUIIconLarge,
    huge: styles.FUIIconHuge,
};

export const UIIcon = React.forwardRef<HTMLElement, IconProps>(function UIIcon({
    type = 'regular',
    name,
    size,
    color,
    className,
    style,
    rotate,
} , ref) {
    const { loadIcon } = useIconsManager();

    useEffect(() => {
        if (!name) {
            throw new Error('Icon name is required');
        }
        loadIcon({ name, type } as (TUIIconDefaultProps | TUIIconBrandProps));
    }, [ loadIcon, name, type ]);

    return (
        <i
            ref={ref}
            className={ cn(
                styles.FUIIcon,
                color && colorsMap[color],
                size && sizeMap[size],
                className,
                {
                    [styles.FUIIconRotate]: rotate,
                },
            ) }
            style={ style }
        >
            <svg>
                <use
                    href={ `#icons-${ type }-${ name }` }
                    style={ {
                        fill: 'currentColor',
                    } }
                />
            </svg>
        </i>
    );
})