import React, { ChangeEvent, useImperativeHandle, useRef } from 'react';
import { UIPopup } from 'finbox-ui-kit';
import { useModal } from 'finbox-ui-kit/utils/hooks';
import { Input } from '@/common/ui/input';
import { TInputChangeData, TInputProps } from '@/common/ui/input/input.types';
import { OptionsList, TOption } from '@/common/ui/options-list';
import { Nullable } from '@/types/_helpers';
import styles from './input-suggestions.module.scss';

export type InputSuggestionsOptions<TOptionData = any> = {
    name: string;
    value: Nullable<any>;
    option?: Nullable<TOption<TOptionData>>;
}

type InputSuggestionsProps = Omit<TInputProps, 'onChange'> & {
    suggestions: TOption[];
    onChange(value: Nullable<any>, options: InputSuggestionsOptions): void;
    filtering?: boolean;
    rtl?: boolean;
    selectOnNavigation?: boolean;
};

export const InputSuggestions = React.forwardRef<HTMLInputElement, InputSuggestionsProps>(function InputSuggestions(
    {
        suggestions,
        onChange,
        rtl,
        selectOnNavigation,
        filtering = true,
        ...inputProps
    },
    forwardRef,
): React.ReactElement {
    const ref = useRef<HTMLInputElement | null>(null);
    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(forwardRef, () => ref.current, []);
    const { show, hide, props } = useModal({
        hideScroll: false,
    });

    function handlerSelectSuggestion(option: TOption) {
        onChange(
            option.value,
            {
                name: inputProps.name,
                value: option.value,
                option,
            },
        );
    }

    function handlerChangeInput(_: ChangeEvent<HTMLInputElement>, data: TInputChangeData<any>) {
        if (data.value) {
            show();
        }
        onChange(
            data.value,
            {
                name: inputProps.name,
                value: data.value,
                option: null,
            },
        );
    }

    return (
        <div className={ styles.inputSuggestions }>
            <Input
                { ...inputProps }
                ref={ ref }
                onChange={ handlerChangeInput }
                onClick={ () => {
                    show();
                    inputProps.onClick && inputProps.onClick();
                } }
                onFocus={ (e, props) => {
                    show();
                    inputProps.onFocus && inputProps.onFocus(e, props);
                } }
                onBlur={ (e, props) => {
                    hide();
                    inputProps.onBlur && inputProps.onBlur(e, props);
                } }
            />
            <UIPopup
                targetRef={ ref }
                open={ props.open }
                onClickOutside={ () => hide() }
                onClose={ () => hide() }
                minWidth='100%'
                maxWidth={ 600 }
                offset={ 2 }
            >
                <OptionsList
                    active={ props.open }
                    options={ suggestions }
                    onSelect={ handlerSelectSuggestion }
                    onEnter={() => hide()}
                    onOptionClick={() => hide()}
                    filter={ filtering && inputProps.value as string }
                    rtl={rtl}
                    selectOnNavigation={selectOnNavigation}
                    fluid
                />
            </UIPopup>
        </div>
    );
});