import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { API_URLS } from '@/consts';
import { HttpClientCancelException } from '@/libs/api-client/cancel-exception';
import {
    TDictionaries,
    TDictionaryTypeMap,
    TUseDictionaryOptions,
} from './use-dictionary.d';


const cache: {
    [x in TDictionaries]?: any
} = {};

const loadingState: {
    [x in TDictionaries]?: Promise<any>
} = {};


export function useDictionary<
    TDictName extends TDictionaries = TDictionaries,
    TDictValue extends TDictionaryTypeMap<TDictName> = TDictionaryTypeMap<TDictName>,
>(
    name: TDictName,
    defaultValue?: TDictionaryTypeMap<TDictName>,
    options?: TUseDictionaryOptions,
): [ TDictValue, { loading: boolean, isCache: boolean } ] {
    const { fetch } = useApiClient({
        url: API_URLS.DICTIONARIES[name],
        showErrorToast: false,
    });
    const [ dictionary, setDictionary ] = useState<TDictValue>(defaultValue as TDictValue);
    useEffect(() => {
        if (cache[name] && !options?.disableCache) {
            setDictionary(cache[name]);
        } else {
            loadingState[name] ??= fetch();
            loadingState[name]
                .then((response) => {
                    let dict: TDictValue;
                    if (!response) {
                        return;
                    }
                    if ('data' in response) {
                        dict = response.data;
                    } else if (Array.isArray(response)) {
                        dict = response as TDictValue;
                    } else {
                        throw new Error('Unrecognized dictionary response');
                    }
                    cache[name] = dict;
                    setDictionary(dict);
                })
                .catch((err) => {
                    if (err instanceof HttpClientCancelException) {
                        return;
                    }
                    toast.error(err.message);
                    throw err;
                })
                .finally(() => {
                    loadingState[name] = null;
                });
        }
    }, [ fetch, name, options?.disableCache ]);

    return [ dictionary, { loading: !!loadingState[name], isCache: !!cache[name] } ];
}

export function invalidateDictionaryCache(name: TDictionaries) {
    delete cache[name];
}