import React from 'react';
import cn from 'classnames';

import './block.style.scss';
import { IUIBlock, TUIBlockButtonProps, TUIBlockGroupProps, TUIBlockProps } from 'finbox-ui-kit/components/block/block';
import { UIIcon } from 'finbox-ui-kit/components/icon';


const UIBlock: React.FC<TUIBlockProps> & IUIBlock = ({
    title,
    description,
    children,
    className,
    fixedHeader,
    fixedHeaderTop = 53,
    buttons,
    id,
    footer,
    error,
    noPadding,
    secondary,
    style,
}) => (
    <div
        id={ id }
        className={ cn('ui-block', className, {
            '-fixed-header': fixedHeader,
            '-error': error,
            '-no-padding': noPadding,
            '-secondary': secondary,
        }) }
        style={style}
    >
        <div className='ui-block-header' style={fixedHeader && { top: fixedHeaderTop }}>
            { title }
            { description && (
                <div className='ui-block-header-description'>
                    { description }
                </div>
            )}
            { (buttons && buttons.length > 0) && (
                <div className='ui-block-buttons'>
                    { buttons.map((button, key) => {
                        if (React.isValidElement(button)) {
                            return button;
                        }
                        return (
                            <button
                                type='button'
                                key={ key }
                                onClick={ (button as TUIBlockButtonProps).onClick }
                                disabled={ (button as TUIBlockButtonProps).disabled }
                            >
                                <UIIcon
                                    type='light'
                                    name={(button as TUIBlockButtonProps).icon}
                                    color={(button as TUIBlockButtonProps).color}
                                />
                            </button>
                        )
                    }) }
                </div>
            ) }
            {error && (
                <div className='error-text'>{error}</div>
            )}
        </div>
        <div className='ui-block-content'>
            { children }
        </div>
        { footer && (
            <div className='ui-block-footer'>
                { footer }
            </div>
        ) }
    </div>
);

const UIBlockGroup: React.FC<TUIBlockGroupProps> = ({ className, children }) => (
    <div className={cn('ui-block-group', className)}>
        { children }
    </div>
);


UIBlock.Group = UIBlockGroup;


export {
    UIBlock,
    UIBlock as default,
}
