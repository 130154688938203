import React, { InputHTMLAttributes } from 'react';
import InputMask from '@kiselev/react-input-mask';
import { formatPhoneNumber } from 'finbox-ui-kit/utils';
import { Validator } from 'finbox-ui-kit/libs/validator';
import { phonePasteHandler } from '@/utils/phone-paste-handler';


type InputMaskedInputProps = InputHTMLAttributes<HTMLInputElement> & {
    mask: string
}
export const InputMaskedInput = React.forwardRef<HTMLInputElement, InputMaskedInputProps>(function InputMaskedInput({
    mask,
    ...inputProps
}, forwardRef) {

    return (
        <InputMask
            {...inputProps}
            ref={ forwardRef as any }
            mask={ mask }
            maskPlaceholder={ null }
            inputMode={inputProps.inputMode}
            beforeMaskedStateChange={(state) => {
                if (inputProps.type === 'tel' && state.currentState?.value && Validator.phone(state.currentState.value) === true) {
                    return {
                        ...state.nextState,
                        value: formatPhoneNumber(state.currentState.value) as string,
                    }
                }
                return state.nextState;
            }}
            onChange={ inputProps.onChange }
            {...inputProps.type === 'tel' && phonePasteHandler(inputProps)}
        />
    );
})