import React from 'react';
import cn from 'classnames';

import { TDatesPresetsProps } from 'finbox-ui-kit/components/dates-presets/dates-presets';
import { PresetsButton } from 'finbox-ui-kit/components/dates-presets/presets-button';
import './dates-presets.style.scss';


export const UIDatesPresets: React.FC<TDatesPresetsProps> = ({ presets, onClick, inline, picked, disabled }) => (
    <div className={ cn('FUI-dates-presets', { '-inline': inline }) }>
        {presets.map(([ text, time ], index) => (
            <PresetsButton
                key={index}
                isPicked={picked === time}
                text={text}
                value={time}
                onClick={onClick}
                disabled={disabled}
            />
        ))}
    </div>
)

export * from './const';
