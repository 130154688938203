import { atom } from 'recoil';
import { TAppConfig } from '@/types/config';

export const ConfigState = atom<TAppConfig | null>({
    key: 'configState',
    default: null,
});

export const ConfigLoadingState = atom<boolean>({
    key: 'configLoadingState',
    default: false,
});
