import { useRef } from 'react';
import { uid } from '@/utils';

type useInputProps = {
    id: string | undefined
    name: string
}
export function useInput({ id, name }: useInputProps) {
    const ref = useRef({
        id: id || `${name}-${uid().substring(0, 6)}`,
    });

    return {
        id: ref.current.id,
    }
}