import React from 'react';
import cn from 'classnames';
import { TUIIconName } from 'finbox-ui-kit/components/icon/icon';
import { TUIColor, TUISize } from 'finbox-ui-kit/types/common';
import { UIIcon, UIIconButton } from '@/common/finbox-ui-kit';
import styles from './message.module.scss';

type UIMessageProps = {
    content?: React.ReactNode
    children?: React.ReactNode
    className?: string
    icon?: TUIIconName
    color?: TUIColor
    size?: TUISize
    inverted?: boolean
    onClickRemove?: () => void
    style?: React.CSSProperties
};

export function UIMessage({
    content,
    children,
    className,
    icon,
    inverted,
    onClickRemove,
    size,
    style,
    color = 'grey',
}: UIMessageProps) {
    return (
        <div className={ cn(styles.FUIMessage, className, {
            [styles.FUIMessageInverted]: inverted,
            [`-${ color }`]: color,
            [`-${ size }`]: size,
        }) } style={ style }>
            { icon && (
                <UIIcon name={ icon } type='light' className={ styles.FUIMessageIcon }/>
            ) }
            { onClickRemove && (
                <UIIconButton
                    icon='xmark'
                    iconType='light'
                    className={ styles.FUIMessageRemoveIcon }
                    onClick={ onClickRemove }
                />
            ) }
            <div className={ styles.FUIMessageContent }>
                { children || content }
            </div>
        </div>
    );
}