import React from 'react';
import './box.style.scss';
import cn from 'classnames';
import { IUIBox, TUIBoxGroupProps, TUIBoxProps } from 'finbox-ui-kit/components/box/box';


const UIBox: React.FC<TUIBoxProps> & IUIBox = ({
    children,
    className,
    noPadding,
    noBorder,
    fluid,
}) => (
    <div className={cn('ui-box', className, { '-no-padding': noPadding, '-no-border': noBorder, '-fluid': fluid })}>
        { children }
    </div>
);

const UIBlockGroup: React.FC<TUIBoxGroupProps> = ({ className, children }) => (
    <div className={cn('ui-box-group', className)}>
        { children }
    </div>
);

UIBox.Group = UIBlockGroup;

export {
    UIBox,
    UIBox as default,
}