import { Control, Controller, FieldValues, FieldError, Path } from 'react-hook-form';
import React from 'react';
import { Textarea } from './textarea';
import { TextareaProps } from './textarea.types';

type TextareaControlledProps<TFieldValues extends FieldValues = FieldValues> =
    Omit<TextareaProps, 'value' | 'onChange' | 'name' | 'error'>
    & {
    control: Control<TFieldValues>;
    error?: FieldError;
    name: Path<TFieldValues>
}

export function TextareaControlled<TFieldValues extends FieldValues = FieldValues>({ control, ...textareaProps }: TextareaControlledProps<TFieldValues>) {
    return (
        <Controller
            control={ control }
            name={ textareaProps.name }
            render={ ({ field }) => (
                <Textarea
                    { ...textareaProps }
                    { ...field }
                    error={ textareaProps.error?.message }
                />
            ) }
        />
    );
}