import { DateTime } from 'luxon';
import { API_BASE_URL } from '@/consts';
import { IDatesPresetItem } from '@/common/dates-preset';

export const API_ORDER_SELECTION = `/orders/selection/`;
export const API_CLIENT_ORDERS = `/orders/client/:clientId/`;
export const API_VALIDATE_CLIENT_FOR_ORDER = `${API_BASE_URL}/clients/:id/validate`;


export const PERIOD_PRESETS: IDatesPresetItem[] = [
    {
        code: 'previous_month',
        name: 'Предыдущий месяц',
        range: [
            DateTime.now().minus({ month: 1 }).startOf('month'),
            DateTime.now().minus({ month: 1 }).endOf('month'),
        ],
    },
    {
        code: '3_months',
        name: '3 месяца',
        range: [
            DateTime.now().minus({ month: 3 }).startOf('month'),
            DateTime.now().minus({ month: 1 }).endOf('month'),
        ],
    },
    {
        code: 'half_year',
        name: 'Пол года',
        range: [
            DateTime.now().minus({ month: 6 }).startOf('month'),
            DateTime.now().minus({ month: 1 }).endOf('month'),
        ],
    },
    {
        code: 'year',
        name: 'Год',
        range: [
            DateTime.now().minus({ month: 13 }).startOf('month'),
            DateTime.now().minus({ month: 1 }).endOf('month'),
        ],
    },
    {
        code: 'all',
        name: 'Весь период',
        range: [
            DateTime.now().minus({ years: 10 }).startOf('month'),
            DateTime.now().endOf('month'),
        ],
    },
];

export enum EErrorsCategories {
    CLIENT = 'client',
    PLEDGE_OBJECTS = 'pledgeObjects',
    PERSONAL = 'personal',
    OTHER = 'other',
}

export const categoriesNames = new Map<EErrorsCategories, string>([
    [ EErrorsCategories.CLIENT, 'Основные данные' ],
    [ EErrorsCategories.PLEDGE_OBJECTS, 'Объекты залога' ],
    [ EErrorsCategories.PERSONAL, 'Данные клиента' ],
    [ EErrorsCategories.OTHER, 'Другие ошибки' ],
]);

export const categoriesMap = {
    'client': 'client',
    'pledgeObjects': 'pledgeObjects',
    'client.products': 'client',
    'personal': EErrorsCategories.PERSONAL,
}

export const fieldsIntl: Record<string, string> = {
    'client.initials.name': 'Имя',
    'client.initials.surname': 'Фамилия',
    'client.products': 'Кредитные продукты',
    'pledgeObjects': 'Объекты залога',
    'pledgeObjects.{d}.address': 'Объект залога {1}',
    'personal': 'Паспортные данные',
    'personal.passport.birthday': 'Дата рождения',
    'personal.passport.birthplace': 'Место рождения',
    'personal.passport.serialAndNumber': 'Паспорт: серия и номер',
    'personal.passport.department': 'Паспорт: кем выдан',
    'personal.passport.dateOfIssue': 'Паспорт: дата выдачи',
    'personal.passport.departmentCode': 'Паспорт: код подразделения',
    'personal.passport.registrationAddress': 'Паспорт: адрес регистрации',
}

export const errorsIntl: Record<string, string> = {
    'client.initials.name[type]': 'Некорректно заполнена',
    'client.initials.surname[type]': 'Некорректно заполнена',
    'client.products[minItems]': 'Необходимо выбрать минимум 1 продукт',
    'pledgeObjects[minItems]': 'Должен быть минимум 1 объект',
    'pledgeObjects.{d}.address[type]': 'Необходимо заполнить адрес ',
    'personal.birthday[type]': 'Некорректно заполнено',
    'personal.passport.serialAndNumber[type]': 'Некорректно заполнено',
    'personal.passport.department[type]': 'Некорректно заполнено',
    'personal.passport.dateOfIssue[type]': 'Некорректно заполнено',
    'personal.passport.departmentCode[type]': 'Некорректно заполнено',
    'personal.passport.registrationAddress[type]': 'Некорректно заполнено',
}
