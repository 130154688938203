import yup from '@/utils/yup-config';
import { EBorrowerType } from '@/consts';
import { initialsScheme } from '@/components/leads/components/credit-from/schemas/initials.scheme';
import { passportScheme } from '@/components/leads/components/credit-from/schemas/passport.scheme';
import { familyScheme } from '@/components/leads/components/credit-from/schemas/family.scheme';
import { workAndEducationScheme } from '@/components/leads/components/credit-from/schemas/work-and-education.scheme';
import { financesScheme } from '@/components/leads/components/credit-from/schemas/finances.scheme';

export const borrowerScheme = yup.object({
    type: yup.string().oneOf(Object.values(EBorrowerType)).required(),
    initials: initialsScheme.required(),
    oldInitials: yup.object().when('initialsChanged', {
        is: true,
        then: () => initialsScheme,
        otherwise: (scheme) => scheme.nullable(),
    }),
    initialsChanged: yup.boolean(),
    passport: passportScheme.required(),
    family: familyScheme.required(),
    workAndEducation: workAndEducationScheme.required(),
    finances: financesScheme.required(),
});
