export const CreditFormTranslation = {
    'client.initials.name': 'Имя клиента',
    'client.initials.surname': 'Фамилия клиента',
    'client.initials.patronymic': 'Отчество клиента',
    'client.period': 'Срок кредита',
    'client.products': 'Продукт',
    'client.urgency': 'Срочность',
    'credit.purpose': 'Цель кредита',
    'credit.loanSecurity': 'Обеспечение по кредиту',
    'credit.initialPaymentSum': 'Сумма первоначального взноса',
    'credit.insurance': 'Страхование',
    'family.amountOfMinorChildren': 'Кол-во несовершеннолетних детей',
    'family.status': 'Семейное положение',
    'family.contract': 'Наличие брачного контракта',
    'family.cohabitation': 'Фактическое проживание с супругом (-ой)',
    'family.pairData.initials': 'ФИО супруга(-и)',
    'family.pairData.initials.name': 'Имя супруга(-и)',
    'family.pairData.initials.surname': 'Фамилия супруга(-и)',
    'family.pairData.birthday': 'Дата рождения супруга(-и)',
    'family.pairData.phone': 'Телефон супруга(-и)',

    'personal.address': 'Адрес проживания',
    'personal.passport.birthday': 'Дата рождения',
    'personal.passport.birthplace': 'Место рождения',
    'workAndEducation.company': 'Название компании',
    'workAndEducation.education': 'Образование',
    'workAndEducation.employee': 'Данные о трудоустройстве',
    'workAndEducation.employmentType': 'Тип занятости',
    'workAndEducation.primeTimeJob': 'Наличие работы по совместительству',
    // 'pledgeObjects': 'Объект залога',

    'personal.passport.dateOfIssue': 'Дата выдачи паспорта',
    'personal.passport.department': 'Кем выдан паспорт',
    'personal.passport.departmentCode': 'Код подразделения',
    'personal.passport.registrationAddress': 'Адрес регистрации',
    'personal.passport.registrationDate': 'Дата регистрации',
    'personal.passport.serialAndNumber': 'Серия и номер паспорта',
    'workAndEducation.company.activityType': 'Вид деятельности компании',
    'workAndEducation.company.address': 'Фактический адрес компании',
    'workAndEducation.company.inn': 'ИНН компании',
    'workAndEducation.company.name': 'Название компании',
    'workAndEducation.company.ogrn': 'ОГРН компании',
    'workAndEducation.company.phone': 'Номер телефона компании',
    'workAndEducation.company.scope': 'Сфера деятельности компании',
    'workAndEducation.employee.position': 'Наименование должности',
    'workAndEducation.employee.seniority': 'Общий трудовой стаж',
    'workAndEducation.employee.startDate': 'Трудовой стаж на последнем месте работы с',

    pledgeObjects: [
        {
            address: '[Объект залога 1] Адрес объекта',
            type: '[Объект залога 1] Тип объекта',
            createdAt: '[Объект залога 1] Дата создания',
            updatedAt: '[Объект залога 1] Дата обновления',
        },
        {
            address: '[Объект залога 2] Адрес объекта',
            type: '[Объект залога 2] Тип объекта',
            createdAt: '[Объект залога 2] Дата создания',
            updatedAt: '[Объект залога 2] Дата обновления',
        },
        {
            address: '[Объект залога 3] Адрес объекта',
            type: '[Объект залога 3] Тип объекта',
            createdAt: '[Объект залога 3] Дата создания',
            updatedAt: '[Объект залога 3] Дата обновления',
        },
    ],
    'personal.addressBasis': 'Основание проживания',
    'workAndEducation.company.employeesCount': 'Количество сотрудников организации',
    'workAndEducation.primeTimeJobType': 'Вид работы по совместительству',
    'pinned': 'Закрепление',
};
