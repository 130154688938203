// Initialize an agent at application startup.
import FingerprintJS from '@fingerprintjs/fingerprintjs';


const fingerprint = FingerprintJS.load();

const getFingerprint = async () => {
    const { visitorId } = await (await fingerprint).get();
    return visitorId;
}

export { fingerprint, getFingerprint };
