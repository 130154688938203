import * as yup from 'yup'
import { DateTime } from 'luxon';
import { Validator } from 'finbox-ui-kit/libs/validator';

yup.setLocale({
    mixed: {
        default: 'Некорректно заполнено поле',
        required: 'Обязательное поле',
        notType: 'Некорректно заполнено поле',
        notOneOf: 'Выберите один из вариантов',
        oneOf: 'Выберите один из вариантов',
        notNull: 'Обязательное поле',
    },
    string: {
        email: 'Некорректный формат',
        min: 'Поле должно быть более ${min} символов',
        max: 'Поле должно быть менее ${max} символов',
    },
    number: {
        positive: 'Значение должно быть больше 0',
        negative: 'Значение должно быть меньше 0',
        lessThan: 'Значение должно быть меньше ${max}',
        moreThan: 'Значение должно быть больше ${max}',
        max: 'Значение должно быть меньше ${max}',
        min: 'Значение должно быть больше ${min}',
    },
    date: {
        max: 'Дата не может быть позднее ${max}',
        min: 'Дата не может быть ранее ${min}',
    },
    array: {
        max: 'Должно быть менее ${max}',
        min: 'Должно быть более ${min}',
    },
});

yup.addMethod<yup.DateSchema>(yup.date, 'format', function(format) {
    return this.transform((value: DateTime, originalValue) => {
        value = DateTime.fromFormat(originalValue, format);
        return value.isValid ? value.toJSDate() : new Date('');
    });
});

yup.addMethod<yup.StringSchema>(yup.string, "phone", function() {
    return this.test('test-phone-number', (value, { path, createError }) => {
        const error = Validator.phone(value);
        return (
            error === true ||
            createError({ path, message: String(error) })
        );
    });
});

yup.addMethod<yup.StringSchema>(yup.string, "password", function passwordValidator() {
    return yup.string().min(8).max(50).test('password', (value, { path, createError }) => {
        if (!/[A-Z]/.test(value) || !/[a-z]/.test(value)) {
            return createError({ path, message: 'Пароль должен содержать большие и маленькие буквы латинского алфавита' });
        }
        if (!/[0-9]/.test(value)) {
            return createError({ path, message: 'Пароль должен содержать минимум одну цифру' });
        }
        return true;
    });
});


yup.addMethod<yup.StringSchema>(yup.string, "repassword", function(options?: { passwordFieldName: string }) {
    return this.when(options?.passwordFieldName || 'password', {
        is: (value: string) => value,
        then: () => yup.string().min(8).max(50).required().test('re-password', (value, { path, parent, createError }) => {
            const passwordFieldName = options?.passwordFieldName || 'password';
            if (!parent[passwordFieldName] || parent[passwordFieldName] === value) {
                return true;
            }
            return createError({ path, message: 'Пароли не совпадают' });
        }),
    });
});


yup.addMethod<yup.StringSchema>(yup.string, "inn", function() {
    return this.test('test-inn', (value, { path, createError }) => {
        const error = Validator.inn(value);
        return (
            error === true ||
            createError({ path, message: 'Некорректный формат' })
        );
    });
});

yup.addMethod<yup.StringSchema>(yup.string, "snils", function() {
    return this.test('test-snils', (value, { path, createError }) => {
        if (!value) {
            return true;
        }
        const error = Validator.snils(value);
        return (
            error === true ||
            createError({ path, message: 'Некорректный формат' })
        );
    });
});

export default yup;
