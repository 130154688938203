import React from 'react';
import cn from 'classnames';

import { Progress } from '@/common/progress';
import { UIIcon, UILoadingOverlay } from 'finbox-ui-kit';
import { IUICardsListComponent, TUICardsListItemProps, TUICardsListProps } from 'finbox-ui-kit/components/cards-list/types';
import { extractString } from 'finbox-ui-kit/utils';
import styles from './cards-list.module.scss';


export const UICardsList: React.FC<TUICardsListProps> & IUICardsListComponent = ({
    children,
    columns,
    noGap,
    className,
}) => (
    <div className={ cn(styles.FUICardsList, className, {
        [`-columns-${ columns }`]: columns,
        '-no-gap': noGap,
    }) }>
        { children }
    </div>
);

export const UICardsListItem: React.FC<TUICardsListItemProps> = (
    {
        title,
        subTitle,
        upperTitle,
        corner,
        onClick,
        onRemove,
        size,
        noEllipsis,
        color,
        icon,
        iconType,
        iconColor,
        error,
        progress,
        loading,
    },
) => (
    <div
        className={ cn(styles.FUICardsListItem, {
            '-clickable': onClick,
            '-no-ellipsis': noEllipsis,
            [`-${ size }`]: size,
            [`-${ error ? 'red' : color }`]: error || color,
        }) }
    >
        <div className={ styles.FUICardsListItemWrapper } onClick={ onClick }>
            { icon && (
                <div className={ styles.FUICardsListItemIcon }>
                    <UIIcon
                        size='large'
                        type={ iconType }
                        name={ icon }
                        color={ iconColor }
                    />
                </div>
            ) }
            <div style={{ flex: 1, maxWidth: '100%' }}>
                { corner && (
                    <div className={ styles.FUICardsListItemCorner }>
                        { corner }
                    </div>
                ) }
                { upperTitle && (
                    // TODO: Добавить стили для .FUI-cards-list-item-upperTitle
                    <div className={ styles.FUICardsListItemUpperTitle }>
                        { upperTitle }
                    </div>
                ) }
                <div className={ styles.FUICardsListItemTitle } title={ extractString(title) }>
                    { title }
                </div>
                { subTitle && (
                    <div className={ styles.FUICardsListItemSubTitle }>
                        { subTitle }
                    </div>
                ) }
                { error && (
                    <div className='fz11 color-red'>{ error }</div>
                ) }
            </div>
        </div>
        { progress && (
            <div className={ styles.FUICardsListItemProgress }>
                <Progress percent={ progress } color='green'/>
            </div>
        ) }
        { loading && (
            <UILoadingOverlay active/>
        ) }
        {
            onRemove && (
                <span onClick={ onRemove }>
                    <UIIcon
                        className={ styles.FUICardsListItemRemove }
                        name='trash'
                        size='small'
                    />
                </span>
            ) }
    </div>
);

UICardsList.Item = UICardsListItem;

