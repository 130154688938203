import React, { useRef } from 'react';
import cn from 'classnames';

import { TTextareaProps } from './textarea';
import { UIInput } from '../input';
import './textarea.style.scss';
// eslint-disable-next-line import/order
import { refGetter } from 'finbox-ui-kit/utils';

export const UITextarea: React.FC<TTextareaProps> = React.forwardRef<HTMLInputElement, TTextareaProps>(function UITextarea({
    rows = 5,
    placeholder,
    className,
    ...props
}, ref) {
    const localRef = useRef();

    return (
        <UIInput
            { ...props }
            type='text'
            className={ cn('FUI-textarea', className) }
            inputElement={ (inputProps) => (
                <>
                    {placeholder && (
                        <div className='FUI-textarea-placeholder'>
                            { placeholder }
                        </div>
                    )}
                    <textarea ref={refGetter(localRef, ref)} { ...inputProps } className='FUI-textarea-textarea' rows={ rows }>{ inputProps.value }</textarea>
                </>
            ) }
            disablePlaceholder
        />
    );
});
