/**
 * Парсинг JSON
 * @param value
 * @returns {null|any}
 */
const jsonParser = <T>(value: string): T | null => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
};

export {
    jsonParser as default,
    jsonParser,
};
