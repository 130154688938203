const randomKey = (Math.random() * 10000).toString();

const LOCAL_STORAGE_KEY = 'crm_tab_id';

if (localStorage.getItem(LOCAL_STORAGE_KEY) === null) {
    localStorage.setItem(LOCAL_STORAGE_KEY, randomKey);
}

/**
 * При закрытии вкладки удаляем ключ
 */
window.addEventListener("unload", () => {
    if (localStorage.getItem(LOCAL_STORAGE_KEY) === randomKey) {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
});

/**
 * По событию проверяем есть ли ключ вкладки, если нет, то ставим его
 */
window.addEventListener("storage", (evt) => {
    if (evt.key === LOCAL_STORAGE_KEY && evt.newValue === null) {
        if (localStorage.getItem(LOCAL_STORAGE_KEY) === null) {
            localStorage.setItem(LOCAL_STORAGE_KEY, randomKey);
        }
    }
});

export const checkForUnique = () => localStorage.getItem(LOCAL_STORAGE_KEY) === randomKey;
