import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';


import { TUIDropdownProps } from './dropdown';
import { UIOptions } from '../options';
import { TUIOptionsSelectHandler } from '../options/options';
import { MakeUISyntheticChangeEvent, isDefined, refGetter } from '../../utils';
import { UIInput } from '../input';
import { TUIEventHandler } from '../input/input';

import './dropdown.style.scss';


export const UIDropdownInput: React.FC<TUIDropdownProps> = React.forwardRef<HTMLInputElement, TUIDropdownProps>(function UIDropdownInput(
    {
        options,
        filtering = false,
        filteringInclude = false,
        selectOnNavigation = false,
        applyFilterFn,
        disabled,
        clearable = true,
        readOnly,
        onChangeFilter,
        ...props
    },
    ref) {
    const localRef = useRef();
    const [ opened, setOpened ] = useState(false);
    const [ filter, setFilter ] = useState<string>('');
    const [ localValue, setLocalValue ] = useState<ReactNode>('');

    const option = useMemo(
        () => (options).find((i) => i.value === props.value),
        [ options, props.value ],
    );

    useEffect(() => {
        setFilter('');
        setLocalValue(option?.text || '');
    }, [ option, props.value ]);

    const handlerChange: TUIEventHandler<string> = (e, data) => {
        setFilter(data.value);
        setLocalValue(data.value || '');
        if (data.value === '') {
            // @ts-ignore
            const _ref = ref?.current || localRef.current;
            props.onChange(
                MakeUISyntheticChangeEvent(_ref, null),
                {
                    data: undefined,
                    name: props.name,
                    oldValue: props.value,
                    value: null,
                },
            );
        }
        if (onChangeFilter) {
            onChangeFilter(data.value);
        }
    };

    const handlerSelect = useCallback<TUIOptionsSelectHandler>((item) => {
        setLocalValue(item.text || '');
        setOpened(false);
        setFilter('');
        // @ts-ignore
        const _ref = ref?.current || localRef.current;
        _ref?.blur();
        props.onChange(
            MakeUISyntheticChangeEvent(_ref, item.value),
            {
                data: item.data,
                name: props.name,
                oldValue: props.value,
                value: item.value,
            },
        );
    }, [ props, ref ]);

    const handlerClick = useCallback((e: any) => {
        if (!readOnly) {
            setOpened(true);
            if (filtering) {
                if (!opened) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                let _ref;
                // @ts-ignore
                if (ref?.current) {
                    // @ts-ignore
                    _ref = ref.current;
                } else if (localRef.current) {
                    _ref = localRef.current;
                }
                const length = _ref.value.length;
                _ref.focus();
                _ref.setSelectionRange(length, length);
            }
        }
        if (props.onClick) {
            props.onClick(e);
        }
    }, [ filtering, opened, props, readOnly, ref ]);

    const handlerFocus = useCallback((e: any, data: any) => {
        if (!readOnly) {
            setOpened(true);
        }
        if (props.onFocus) {
            props.onFocus(e, data);
        }
    }, [ props, readOnly ]);

    const handlerBlur = useCallback((e: any, data: any) => {
        setOpened(false);
        setFilter('');
        if (props.onBlur) {
            props.onBlur(e, data);
        }
    }, [ props ]);

    return (
        <UIInput
            { ...props }
            type='text'
            ref={ refGetter(localRef, ref) }
            className={ cn('FUI-dropdown', props.className, {
                '-opened': opened,
                '-filtering': filtering,
            }) }
            onChange={ handlerChange }
            onFocus={ handlerFocus }
            onBlur={ handlerBlur }
            onMouseDown={ handlerClick }
            disabled={ disabled }
            suggestionsAddon={ (
                <>
                    <UIOptions
                        open={ opened }
                        filter={ filter }
                        options={ options }
                        onSelect={ handlerSelect }
                        filtering={ filtering }
                        filteringInclude={ filteringInclude }
                        applyFilterFn={ applyFilterFn }
                        selectOnNavigation={ selectOnNavigation }
                        current={ props.value }
                        fluid
                    />
                    { !filtering && (
                        <div className='FUI-dropdown-value'>
                            { localValue }
                        </div>
                    ) }
                </>
            ) }
            autoComplete='new-password'
            value={ typeof localValue === 'string' ? localValue : String(props.value) }
            rightIcon={ !readOnly && (!isDefined(props.value) || props.value === '' || !clearable) || opened ? 'chevron-down' : 'xmark' }
            readOnly={ readOnly || !filtering }
            clearable={ clearable && isDefined(props.value) && props.value !== '' }
        />
    );
});
