import base64js from 'base64-js';

export function blobToBase64(blob: Blob) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
            const dataUrl = reader.result;
            const base64 = (dataUrl as string).split(',')[1];
            resolve(base64);
        };
        reader.readAsDataURL(blob);
    });
}

export function Base64Encode(str: string) {
    const bytes = new TextEncoder().encode(str);
    return base64js.fromByteArray(bytes);
}

export function Base64Decode(str: string) {
    const bytes = base64js.toByteArray(str);
    return new TextDecoder().decode(bytes);
}
