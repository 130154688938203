import { API_BASE_URL } from '@/consts';

export const API_CLIENT_DOCUMENTS = `${API_BASE_URL}/clients/:id/documents/` as const;
export const API_CLIENT_DOCUMENT_ITEM = `${API_BASE_URL}/clients/:id/documents/:documentId` as const;
export const API_CLIENT_DOCUMENT_UPLOAD = `${API_BASE_URL}/clients/:id/documents/upload` as const;
export const API_CLIENT_DOCUMENT_SUPPLEMENTARY_AGREEMENT = `${API_BASE_URL}/clients/:id/documents/:documentId/supplementary-agreement` as const;
export const API_CLIENT_DOCUMENT_ACT = `${API_BASE_URL}/clients/:id/documents/:documentId/act` as const;
export const API_CLIENT_CHECK_FOR_BLOCKING_ORDERS = `${API_BASE_URL}/clients/:id/check-for-blocking-orders` as const;
export const API_CLIENT_ORDERS = `/orders/client/:id/` as const;
export const API_CLIENT_ORDER = `/orders/client/:clientId/:orderId/` as const;
export const API_CLIENT_ORDERS_STATUS_BATCH = `${API_CLIENT_ORDERS}status/batch` as const;
export const API_CLIENT_COUNTERS = `${API_BASE_URL}/clients/:id/counters` as const;
