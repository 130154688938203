import React from 'react';
import cn from 'classnames';

type TDatesPresetButtonProps = {
    text: string,
    value: any,
    onClick: (value: number) => void,
    isPicked: boolean,
    disabled: boolean,
}
export const PresetsButton: React.FC<TDatesPresetButtonProps> = ({ text, value, onClick, isPicked, disabled }) => {
    const handlerClick = () => {
        if (!disabled) {
            onClick(value);
        }
    };
    return (
        <button type='button' className={ cn('FUI-dates-presets-btn', { '-picked': isPicked }) } onClick={ handlerClick } disabled={ disabled }>
            { text }
        </button>
    );
};
