import yup from '@/utils/yup-config';
import { addressScheme } from '@/components/leads/components/credit-from/schemas/address.scheme';

export const companyScheme = yup.object({
    name: yup.string().required(),
    phone: yup.string().phone().required(),
    address: yup.mixed()
        .when('mixedAddress', {
            is: (value) => typeof value === 'string',
            then: () => yup.string().required(),
            otherwise: () => addressScheme.required(),
        }),
    inn: yup.string().inn().required(),
    isEqualAddresses: yup.string().required(),
    factAddress: yup.mixed()
        .when('mixedAddress', {
            is: (value) => typeof value === 'string',
            then: () => yup.string().required(),
            otherwise: () => addressScheme.required(),
        }),
    ogrn: yup.string(),
    activityType: yup.string().required(),
    scope: yup.string().required(),
    employeesCount: yup.string().required(),
});