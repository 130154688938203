import React, { useRef } from 'react';
import { UIButton, UIPopup } from 'finbox-ui-kit';
import { useToggle } from 'finbox-ui-kit/utils/hooks';
import { MBorrowerType } from '@/consts';
import { OptionsList, TOption, TSelectHandler } from '@/common/options-list';


export const AddBorrowerButton = ({ onPick }) => {
    const ref = useRef<HTMLButtonElement>();
    const { on, toggle } = useToggle();
    const handlerSelect: TSelectHandler = ({ value }) => {
        onPick(value);
        toggle(false);
    }
    const options: TOption[] = Array.from(MBorrowerType).map(([ value, text ]) => ({
        text,
        value,
    }));
    return (
        <UIPopup.Wrapper>
            <UIButton
                ref={ref}
                icon='plus'
                content='Добавить'
                onClick={() => toggle() }
            />
            <UIPopup targetRef={ref} open={on} onClickOutside={() => toggle(false)} style={{ zIndex: 9 }}>
                <OptionsList options={options} onSelect={handlerSelect}/>
            </UIPopup>
        </UIPopup.Wrapper>
    );
};