import React, { useEffect, useState } from 'react';
import { cloneDeep, set } from 'lodash';
import { useRecoilValue } from 'recoil';
import { UIOverlayModal } from 'finbox-ui-kit';
import { ClientStep } from '@/components/leads/components/credit-from/contains/client-step';
import { CLIENT_INITIAL_FIELDS } from '@/components/leads/components/credit-from';
import { AuthUser } from '@/state/auth.state';
import { ECreditFormType } from '@/components/leads/components/credit-from/consts';
import { TClient } from '@/types/lead';

type TClientCreateProps = {
    open: boolean
    onClose: () => void
    onSubmit: (client: Partial<TClient>) => void;
    clientFields
}
export const ClientCreate = ({ open, onClose, onSubmit, clientFields }: TClientCreateProps) => {
    const user = useRecoilValue(AuthUser);
    const [ fields, setFields ] = useState(cloneDeep(CLIENT_INITIAL_FIELDS));

    useEffect(() => {
        if (open) {
            setFields(cloneDeep({
                ...CLIENT_INITIAL_FIELDS,
                client: {
                    ...CLIENT_INITIAL_FIELDS.client,
                    ...clientFields,
                },
            }));
        }
    }, [ clientFields, open ]);

    const handlerChange = React.useCallback((e, { name, value, checked }) => {
        const updatedFields = set({
            ...fields,
        }, name, checked !== undefined ? checked : value);

        setFields(updatedFields);
    }, [ fields ]);

    const formType = React.useMemo<ECreditFormType>(() => {
        if (!fields?.client?.products) {
            return null;
        }
        if (fields.client.products.includes(58)) {
            return ECreditFormType.MORTGAGE;
        } else if (fields.client.products.includes(1)) {
            return ECreditFormType.CREDIT;
        }
        return ECreditFormType.LOAN;
    }, [ fields ]);

    const handlerSubmit = () => {
        onSubmit(fields as any);
    }

    return (
        <UIOverlayModal open={open} onClose={ onClose } size='big'>
            <UIOverlayModal.Header>Создание клиента</UIOverlayModal.Header>
            <UIOverlayModal.Body>
                <ClientStep
                    fields={fields}
                    formType={formType}
                    handlerChange={handlerChange}
                    user={user}
                    nextStep={handlerSubmit}
                    prevStep={onClose}
                    hideContinue
                />
            </UIOverlayModal.Body>
        </UIOverlayModal>
    )
}