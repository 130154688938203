import { useCallback, useState } from 'react';
import { transformUrlParams } from '@/utils';
import { API_URLS } from '@/consts';
import { useApiClient } from '@/libs/api-client/use-api-client';

interface IValidationError {
    field: string;
    message: string;
    type: string;
}

export const useValidateClientForDocument = ({
    clientId,
}) => {
    const [ isValid, setIsValid ] = useState(false);
    const [ errors, setErrors ] = useState<IValidationError[]>([]);
    const { fetch, loading: isLoading } = useApiClient({
        url: transformUrlParams(API_URLS.CLIENTS.VALIDATE_FOR_DOCUMENT, { id: clientId }),
    });

    const validate = useCallback(async () => {
        const { valid, errors } = await fetch();
        setIsValid( valid );
        setErrors( errors );
        return valid;
    }, [ fetch ]);

    const reset = useCallback(() => {
        setErrors([]);
    }, []);

    return {
        isLoading,
        isValid,
        errors,
        validate,
        reset,
    };
};
