import { ForwardedRef, MutableRefObject } from 'react';

interface RefObject<T> {
    current: T | null;
}

type Ref<T> = { bivarianceHack(instance: T | null): void }["bivarianceHack"] | RefObject<T> | null;

export const refGetter = <T extends HTMLElement = HTMLInputElement>(localRef: MutableRefObject<T>, ref: Ref<T>) => (e: any) => {
    localRef.current = e;

    if (ref) {
        if ('current' in ref) {
            ref.current = e;
        } else if (typeof ref === 'function') {
            ref(e);
        }
    }
};

export const getRefTarget = <T extends HTMLElement = HTMLInputElement>(localRef: MutableRefObject<T>, ref: ForwardedRef<T>): T => {
    if (ref && (ref as MutableRefObject<T>).current) {
        return (ref as MutableRefObject<T>).current
    }
    return localRef.current;
}
