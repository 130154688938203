import { Link } from 'react-router-dom';
import React, { memo } from 'react';
import { TUIBreadcrumbsProps } from './breadcrumbs';

import { UIIcon } from '../icon';
import styles from './breadcrumbs.module.scss';

export const UIBreadcrumbs: React.FC<TUIBreadcrumbsProps> = memo(function UIBreadcrumbs({ items, indexLink }) {
    return (
        <ul className={styles.FUIBreadcrumbs}>
            <li>
                <Link to={indexLink || '/'}>
                    <UIIcon name='house' type='sharp-solid'/>
                </Link>
            </li>
            { items.map((item, index) => (
                <li key={ index }>
                    <Link to={ item.link }>
                        { item.name }
                    </Link>
                </li>
            )) }
        </ul>
    )
});