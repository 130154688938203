
export const flip = {
    isCanFlipLeft: (fromX: number, popupWidth: number) => {
        const left = fromX - popupWidth;
        return left > 0;
    },
    isCanFlipRight: (fromX: number, popupWidth: number) => {
        const right = fromX + popupWidth;
        return right < window.innerWidth;
    },
    isCanFlipTop: (fromY: number, popupHeight: number) => {
        const top = fromY - popupHeight;
        return top > 0;
    },
    isCanFlipBottom: (fromY: number, popupHeight: number) => {
        const bottom = fromY + popupHeight;
        return bottom < window.innerHeight;
    },
}
