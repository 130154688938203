import { TRealtyType } from '@/components/leads/components/credit-from/types';


export const treeToArray = (tree, chain = []) => tree.reduce((res, item) => {
    const { options, ...data } = item;
    return ([
        ...res,
        {
            ...data,
            chain: [
                ...chain,
                item.text,
            ],
        },
        ...((options) ? treeToArray(options, [ ...chain, item.text ]) : []),
    ]);
}, []);

export const arrayToTree = (types: TRealtyType[], parentId: number = null, level = 0) => types?.reduce((res, type) => {
    if (type.parentId !== parentId) {
        return res;
    }
    const options = arrayToTree(types, type.id, level + 1);
    return [
        ...res,
        {
            text: type.name,
            id: type.id,
            value: type.id,
            options: options.length ? options : null,
            hasChildren: !!options.length,
            parentId: type.parentId,
            level,
        },
    ];
}, []);

export const getNestedIds = (items, id) => {
    const item = items.find((i) => i.id === id);
    if (!item.hasChildren) {
        return [ id ];
    }
    return [
        id,
        ...items.filter((i) => i.parentId === id).reduce((res, i) => [ ...res, ...getNestedIds(items, i.id) ], []),
    ];
}
