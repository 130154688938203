import { useState, useEffect } from "react";


type TMediaQueries = {
    xs: string,
    sm: string,
    md: string,
    lg: string,
    xl: string,
    xxl: string,
    max: string,
}
const MediaQueries: TMediaQueries = {
    xs: '(max-width: 0)',
    sm: '(max-width: 544px)',
    md: '(max-width: 768px)',
    lg: '(max-width: 992px)',
    xl: '(max-width: 1200px)',
    xxl: '(max-width: 1366px)',
    max: '(max-width: 1920px)',
}

export const useMediaQuery = (query: keyof TMediaQueries | string) => {
    const [ matches, setMatches ] = useState(false);

    useEffect(() => {
        let mediaQuery;
        if (Object.keys(MediaQueries).includes(query)) {
            // @ts-ignore
            mediaQuery = MediaQueries[query];
        } else {
            mediaQuery = query
        }
        const media = window.matchMedia(mediaQuery);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, [ matches, query ]);

    return matches;
}

