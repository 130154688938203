import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { API_URLS } from '@/consts';
import { TAppConfig } from '@/types/config';
import { ConfigLoadingState, ConfigState } from '@/state/config.state';
import { useApiClient } from '@/libs/api-client/use-api-client';

export const useConfigController = () => {
    const [ config, setConfig ] = useRecoilState(ConfigState);
    const [ isLoadingConfig, setIsLoadingConfig ] = useRecoilState(ConfigLoadingState);

    const { fetch } = useApiClient<TAppConfig>({
        url: API_URLS.CONFIG,
    });

    const fetchConfig = useCallback(async () => {
        setIsLoadingConfig(true);
        try {
            setConfig(await fetch());
        } finally {
            setIsLoadingConfig(false);
        }
    }, [ fetch, setConfig, setIsLoadingConfig ]);

    const resetConfig = useCallback(() => {
        setIsLoadingConfig(false);
        setConfig(null);
    }, [ setConfig, setIsLoadingConfig ]);

    return {
        isLoadingConfig,
        isConfigLoaded: config !== null,
        config,
        fetchConfig,
        resetConfig,
    }
}
