import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { transformUrlParams } from '@/utils';
import { API_URLS } from '@/consts';
import { ILeadItem } from '@/types/lead';
import { ClientCreate } from '@/components/leads/components/client-create';
import { useApiClient } from '@/libs/api-client/use-api-client';

type TClientCreateProps = {
    phone?: ILeadItem['phone'],
    cid?: ILeadItem['cid'],
    source: ILeadItem['source'];
}

interface IClientContext {
    loading: boolean;
    client: ILeadItem;
    pinClient: (clientId: number) => Promise<void>;
    unpinClient: (clientId: number) => Promise<void>;
    fetchClient: (clientId: number) => Promise<ILeadItem>;
    deleteClient: (clientId: number, reasonCategory: number, reason: string) => Promise<ILeadItem>;
    isEdit: boolean;
    setIsEdit: Dispatch<SetStateAction<boolean>>;
    reopen: (clientId: number) => Promise<void>;
    repair: (ids: number[], managerId: number) => Promise<void>;
    updateProducts: (clientId: number, products: number[]) => Promise<void>;
    createClient: (props: TClientCreateProps) => void;
    copyClient: (clientId: number) => Promise<number>;
}

const ClientContext = React.createContext<IClientContext>({
    loading: false,
    isEdit: false,
    client: null,
    pinClient: () => null,
    unpinClient: () => null,
    fetchClient: () => null,
    deleteClient: () => null,
    setIsEdit: () => null,
    reopen: () => null,
    repair: () => null,
    updateProducts: () => null,
    createClient: () => null,
    copyClient: () => null,
});

export const useClientContext = () => React.useContext(ClientContext);


export const ClientContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const [ isEdit, setIsEdit ] = useState<boolean>(false);

    const [ client, setClient ] = useState<ILeadItem>(null);
    const { fetch, loading: isLoading } = useApiClient({
        url: API_URLS.CLIENTS.BASE,
    });

    const pinClient: IClientContext['pinClient'] = async (clientId: number) => {
        await fetch(null, {
            method: 'put',
            url: `${ API_URLS.CLIENTS.BASE }${ clientId }/pin`,
        });
    };

    const unpinClient: IClientContext['unpinClient'] = async (clientId: number) => {
        await fetch(null, {
            method: 'delete',
            url: `${ API_URLS.CLIENTS.BASE }${ clientId }/pin`,
        });
    };

    const fetchClient: IClientContext['fetchClient'] = useCallback(async (clientId: number) => {
        const client = await fetch(null, {
            url: `${ API_URLS.LEADS.BASE }${ clientId }/`,
        });
        setClient(client);
        return client;
    }, [ fetch ]);

    const deleteClient: IClientContext['deleteClient'] = useCallback(async (clientId: number, reasonCategory: number, reason: string) => {
        const response = await fetch({
            deleteReasonCategory: reasonCategory,
            deleteReason: reason,
        }, {
            method: 'delete',
            url: `${ API_URLS.LEADS.BASE }${ clientId }/`,
        });
        setClient(response.data);
        return response.data;
    }, [ fetch ]);

    const reopen: IClientContext['reopen'] = useCallback(async (clientId: number) => {
        await fetch(null, {
            method: 'put',
            url: transformUrlParams(API_URLS.LEADS.REOPEN_LEAD, { id: clientId }),
        });
        toast.success('Клиент перемещён в новые');
    }, [ fetch ]);

    const repair: IClientContext['repair'] = useCallback(async (ids: number[], managerId) => {
        await fetch({
            manager: managerId,
            leads: ids,
        }, {
            method: 'post',
            url: API_URLS.GARBAGE.REPAIR,
        });
        toast.success('Клиент восстановлен');
    }, [ fetch ]);

    const updateProducts: IClientContext['updateProducts'] = useCallback(async (clientId: number, products) => {
        await fetch({
            products,
        }, {
            method: 'put',
            url: `${ API_URLS.LEADS.BASE }${ clientId }/`,
        });
        toast.success('Клиент восстановлен');
    }, [ fetch ]);

    const [ creteClientFields, setCreateClientFields ] = useState<TClientCreateProps | null>(null);

    const createClient: IClientContext['createClient'] = useCallback((props) => {
        setCreateClientFields(props);
    }, []);


    const handlerCloseCreateClient = () => {
        setCreateClientFields(null);
    }

    const handlerSubmitCreateClient = useCallback(async (clientFields) => {
        const client = await fetch({
            ...clientFields,
            client: {
                ...clientFields.client,
                source: creteClientFields.source,
                cid: creteClientFields.cid,
            },
        }, {
            method: 'post',
            url: API_URLS.CLIENTS.BASE,
        });
        setCreateClientFields(null);
        navigate(`/leads/${ client.id }`);
    }, [ creteClientFields?.cid, creteClientFields?.source, fetch, navigate ]);

    const copyClient: IClientContext['copyClient'] = useCallback(async (clientId: number) => {
        const { newClientId } = await fetch(null, {
            method: 'post',
            url: transformUrlParams(API_URLS.CLIENTS.COPY, { id: clientId }),
        });
        toast.success('Создан новый клиент');
        return newClientId;
    }, [ fetch ]);

    return (
        <ClientContext.Provider value={ {
            loading: isLoading,
            client,
            pinClient,
            unpinClient,
            fetchClient,
            deleteClient,
            isEdit,
            setIsEdit,
            reopen,
            repair,
            updateProducts,
            createClient,
            copyClient,
        } }>
            { children }
            <ClientCreate
                open={ !!creteClientFields }
                onClose={ handlerCloseCreateClient }
                onSubmit={ handlerSubmitCreateClient }
                clientFields={ creteClientFields }
            />
        </ClientContext.Provider>
    )
}
