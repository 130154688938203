// @ts-nocheck
/* eslint-disable */
/* tslint:disable */

import { cloneDeep } from 'lodash';

function functionThatReturnsTrue() {
    return false;
}

function functionThatReturnsFalse() {
    return false;
}

const assign = Object.assign;

function createSyntheticEvent(Interface) {
    function SyntheticBaseEvent(reactName, reactEventType, targetInst, nativeEvent, nativeEventTarget) {
        this._reactName = reactName;
        this._targetInst = targetInst;
        this.type = reactEventType;
        this.nativeEvent = nativeEvent;
        this.target = nativeEventTarget;
        this.currentTarget = null;

        for (const _propName in Interface) {
            // eslint-disable-next-line no-prototype-builtins
            if (!Interface.hasOwnProperty(_propName)) {
                continue;
            }

            const normalize = Interface[_propName];

            if (normalize) {
                this[_propName] = normalize(nativeEvent);
            } else {
                this[_propName] = nativeEvent[_propName];
            }
        }

        const defaultPrevented = nativeEvent.defaultPrevented != null ? nativeEvent.defaultPrevented : nativeEvent.returnValue === false;

        if (defaultPrevented) {
            this.isDefaultPrevented = functionThatReturnsTrue;
        } else {
            this.isDefaultPrevented = functionThatReturnsFalse;
        }

        this.isPropagationStopped = functionThatReturnsFalse;
        return this;
    }

    assign(SyntheticBaseEvent.prototype, {
        preventDefault: function() {
            this.defaultPrevented = true;
            const event = this.nativeEvent;

            if (!event) {
                return;
            }

            if (event.preventDefault) {
                event.preventDefault(); // $FlowFixMe - flow is not aware of `unknown` in IE
            } else if (typeof event.returnValue !== 'unknown') {
                event.returnValue = false;
            }

            this.isDefaultPrevented = functionThatReturnsTrue;
        },
        stopPropagation: function() {
            const event = this.nativeEvent;

            if (!event) {
                return;
            }

            if (event.stopPropagation) {
                event.stopPropagation(); // $FlowFixMe - flow is not aware of `unknown` in IE
            } else if (typeof event.cancelBubble !== 'unknown') {
                event.cancelBubble = true;
            }

            this.isPropagationStopped = functionThatReturnsTrue;
        },

        persist: function() {// Modern event system doesn't use pooling.
        },
        isPersistent: functionThatReturnsTrue,
    });
    return SyntheticBaseEvent;
}

const EventInterface = {
    eventPhase: 0,
    bubbles: 0,
    cancelable: 0,
    timeStamp: function(event) {
        return event.timeStamp || Date.now();
    },
    defaultPrevented: 0,
    isTrusted: 0,
};
const SyntheticEvent = createSyntheticEvent(EventInterface);

export const MakeUISyntheticChangeEvent = (target, value) => {
    const _target = {
        ...target,
        value,
    };
    return new SyntheticEvent('onChange', 'change', _target, new Event('change'), _target);
};
