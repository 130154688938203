import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import { FORMAT } from 'finbox-ui-kit/consts';
import { declOfNum } from '@/utils/decl-num';


enum SCHEME_RULE_TYPES {
    STRING = 'string',
    INT = 'int',
    BOOLEAN = 'boolean',
    FLOAT = 'float',
    DATE = 'date',
    ARRAY = 'array',
    OBJECT = 'object',
}

type RequiredFieldCallback = (data: any, options: TOptions) => boolean;


type RuleInt = {
    type: SCHEME_RULE_TYPES.INT;
    required?: boolean | RequiredFieldCallback;
    min?: number;
    max?: number;
    errors?: { [key: string]: string }
}


type RuleFloat = {
    type: SCHEME_RULE_TYPES.FLOAT;
    required?: boolean | RequiredFieldCallback;
    min?: number;
    max?: number;
    errors?: { [key: string]: string }
}

type RuleString = {
    type: SCHEME_RULE_TYPES.STRING;
    required?: boolean | RequiredFieldCallback;
    minLength?: number;
    maxLength?: number;
    regex?: RegExp;
    errors?: { [key: string]: string };
    validator?: (value: string, required?: boolean) => boolean | string;
}

type RuleDate = {
    type: SCHEME_RULE_TYPES.DATE;
    format?: string;
    required?: boolean | RequiredFieldCallback;
    minDate?: DateTime;
    maxDate?: DateTime;
    errors?: { [key: string]: string }
}

type RuleArray = {
    type: SCHEME_RULE_TYPES.ARRAY;
    required?: boolean | RequiredFieldCallback;
    minLength?: number;
    maxLength?: number;
    errors?: { [key: string]: string }
    items?: TRule;
}

type RuleBoolean = {
    type: SCHEME_RULE_TYPES.BOOLEAN;
    required?: boolean | RequiredFieldCallback;
    errors?: { [key: string]: string }
}

type RuleObject = {
    type: SCHEME_RULE_TYPES.OBJECT;
    required?: boolean | RequiredFieldCallback;
    errors?: { [key: string]: string };
    scheme: SchemeScheme;
}

type TRule = RuleInt | RuleString | RuleDate | RuleArray | RuleFloat | RuleBoolean | RuleObject;

type SchemeScheme = { [key: string]: TRule };


type TResult<T> = { isValid: boolean; errors: T};
type TData = { [key: string]: any; };
type TOptions = {
    additionalData?: any;
};


class Scheme {
    _scheme = null;
    _defaultValidators = [];
    _validators = {};

    static SCHEME_RULE_TYPES = SCHEME_RULE_TYPES;

    constructor(scheme: SchemeScheme) {
        this._scheme = scheme;
        this._defaultValidators = [
            this._isRequired,
        ];
        this._validators = {
            [Scheme.SCHEME_RULE_TYPES.DATE]: this._validateDate,
            [Scheme.SCHEME_RULE_TYPES.STRING]: this._validateString,
            [Scheme.SCHEME_RULE_TYPES.INT]: this._validateInt,
            [Scheme.SCHEME_RULE_TYPES.FLOAT]: this._validateFloat,
            [Scheme.SCHEME_RULE_TYPES.ARRAY]: this._validateArray,
            [Scheme.SCHEME_RULE_TYPES.BOOLEAN]: this._validateBoolean,
        };
    }

    _isRequired = (key, rule, value, data, options) => {
        if (!rule.required) {
            return null;
        }

        const error = rule?.errors?.required || 'Обязательное поле';

        const isRequired = typeof rule.required === 'boolean' ? rule.required : rule.required(data, options);

        if (isRequired && rule.type === Scheme.SCHEME_RULE_TYPES.BOOLEAN) {
            return (typeof value !== 'boolean') ? error : null;
        }

        if (isRequired && rule.type === Scheme.SCHEME_RULE_TYPES.INT) {
            return (value === undefined || value === null || value === '') ? error : null;
        }
        if (isRequired && !value) {
            return error;
        }

        return null;
    }

    _validateDate = (key, rule, value) => {
        if (!value) {
            return null;
        }
        let _value = value;

        if (_value && !(_value instanceof DateTime)) {
            _value = DateTime.fromFormat(value, rule.format);
            if (!_value.isValid) {
                return rule?.errors?.format || `Дата должна быть в формате ${rule.format}`;
            }
        }
        switch (true) {
            case value && rule.minDate && _value < rule.minDate: {
                const error = rule?.errors?.minLength;
                return error || `Дата не может быть ранее ${rule.minDate.toFormat(FORMAT.DATE)}`;
            }
            case value && rule.maxDate && _value > rule.maxDate: {
                const error = rule?.errors?.minLength;
                return error || `Дата не может быть позднее ${rule.maxDate.toFormat(FORMAT.DATE)}`;
            }
            default:
                return null;
        }
    }

    _validateString = (key, rule: RuleString, value, data, options) => {
        if (rule.validator) {
            const isRequired = typeof rule.required === 'boolean' ? rule.required : rule.required(data, options);
            const res = rule.validator(value, isRequired);
            return res === true ? null : res;
        }
        switch (true) {
            case value && rule.minLength && value.length < rule.minLength: {
                const error = rule?.errors?.minLength;
                const decl = declOfNum(rule.minLength, [ 'символа', 'символов', 'символов' ]);
                return error || `Поле должно быть более ${rule.minLength} ${decl}`;
            }
            case value && rule.maxLength && value.length > rule.maxLength: {
                const error = rule?.errors?.maxLength;
                const decl = declOfNum(rule.maxLength, [ 'символа', 'символов', 'символов' ]);
                return error || `Поле должно быть менее ${rule.maxLength} ${decl}`;
            }
            case value && rule.regex && !rule.regex.test(value): {
                const error = rule?.errors?.regex;
                return error || 'Некорректный формат поля';
            }
            default:
                return null;
        }
    }

    _validateInt = (key, rule: RuleInt, value) => {

        if (value === undefined || value === null) {
            return null;
        }
        const _value = parseInt(value, 10);
        if (has(rule, 'min') && _value < rule.min) {
            const error = rule?.errors?.min;
            return error || `Поле должно быть более ${rule.min}`;
        }
        if (has(rule, 'max') && _value > rule.max) {
            const error = rule?.errors?.max;
            return error || `Поле должно быть менее ${rule.max}`;
        }
        return null;
    }

    _validateFloat = (key, rule: RuleInt, value) => {
        const _value = parseFloat(value) || 0;
        switch (true) {
            case has(rule, 'min') && _value < rule.min: {
                const error = rule?.errors?.min;
                return error || `Поле должно быть более ${rule.min}`;
            }
            case has(rule, 'max') && _value > rule.max: {
                const error = rule?.errors?.max;
                return error || `Поле должно быть менее ${rule.max}`;
            }
            default:
                return null;
        }
    }

    _validateArray = (key, rule: RuleArray, value) => {
        switch (true) {
            case value && 'minLength' in rule && value.length < rule.minLength: {
                const error = rule?.errors?.minLength;
                const decl = declOfNum(rule.minLength, [ 'элемент', 'элемента', 'элементов' ]);
                return error || `Необходимо выбрать минимум ${rule.minLength} ${decl}`;
            }
            case value && 'maxLength' in rule && value > rule.maxLength: {
                const error = rule?.errors?.maxLength;
                const decl = declOfNum(rule.maxLength, [ 'элемент', 'элемента', 'элементов' ]);
                return error || `Добжно быть не более ${rule.maxLength} ${decl}`;
            }
            default:
                return null;
        }
    }

    _validateBoolean = () => null

    getValidators(rule) {
        return [
            ...this._defaultValidators,
            this._validators[rule.type],
        ];
    }

    static validate<T = {[x: string]: string }>(scheme: SchemeScheme, data: TData, options?: TOptions): TResult<T> {
        return new Scheme(scheme).validate(data, options);
    }

    validate<T = {[x: string]: string }>(data: TData, options?: TOptions): TResult<T> {
        const errors: any = {};
        Object.keys(this._scheme).forEach((schemeKey) => {
            const rule = this._scheme[schemeKey];

            const value = schemeKey in data ? data[schemeKey] : null;

            if (rule.type === Scheme.SCHEME_RULE_TYPES.OBJECT) {
                const requiredError = this._isRequired(schemeKey, rule, value, data, options);
                if (requiredError) {
                    errors[schemeKey] = requiredError;
                    return;
                }
                if (!value) {
                    return;
                }
                const { isValid, errors: nestedErrors } = Scheme.validate(rule.scheme, value, options);
                if (!isValid) {
                    for (const [ path, error ] of Object.entries(nestedErrors)) {
                        errors[`${schemeKey}.${path}`] = error;
                    }
                }
                return;
            }

            if (rule.type === Scheme.SCHEME_RULE_TYPES.ARRAY && rule.items) {
                const requiredError = this._isRequired(schemeKey, rule, value, data, options);
                if (requiredError) {
                    errors[schemeKey] = requiredError;
                    return;
                }

                if (!value) {
                    return;
                }

                const error = this._validateArray(schemeKey, rule, value);
                if (error) {
                    errors[schemeKey] = error;
                    return;
                }
                const arrayScheme: SchemeScheme = Object.fromEntries(value.map((i, index) => [ index, rule.items ]));
                const { isValid, errors: nestedErrors } = Scheme.validate(arrayScheme, Object.fromEntries(Object.entries(value)), options);
                if (!isValid) {
                    for (const [ path, error ] of Object.entries(nestedErrors)) {
                        errors[`${schemeKey}.${path}`] = error;
                    }
                }

                return;
            }

            const validators = this.getValidators(rule);
            validators.forEach((validator) => {
                const error = validator(schemeKey, rule, value, data, options);
                if (error) {
                    errors[schemeKey] = error;
                }
            });
        });
        return {
            isValid: isEmpty(errors),
            errors,
        };
    }
}

export {
    Scheme,
    SchemeScheme,
};
