import zip from 'lodash/zip';
import sum from 'lodash/sum';

const EMAIL_REGEX_I = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
const DIGITS_REGEX_G = /[0-9]/g;

const MOBILE_CODES = [
    // Казахстан
    700,
    701,
    702,
    703,
    704,
    705,
    706,
    707,
    708,
    709,
    747,
    750,
    751,
    760,
    761,
    762,
    763,
    764,
    771,
    775,
    776,
    777,
    778,
];

export class Validator {
    static phone(value: string, isRequired = false): string | boolean {
        if (!value && isRequired) {
            return 'Пожалуйста, укажите номер';
        } else if (!value) {
            return true;
        }

        const phone = String(value).match(DIGITS_REGEX_G);

        let operatorIndex;

        if (phone && phone.length === 11) {
            if ([ 7, 8 ].indexOf(Number(phone[0])) === -1) {
                // Ошибка кода страны
                return 'Код страны должен начинаться с +7 или 7';
            }

            operatorIndex = 1;
        } else if (phone && phone.length === 10) {
            operatorIndex = 0;
        } else {
            return 'Пожалуйста, введите номер в формате +Х (ХХХ) ХХХ-ХХ-ХХ';
        }

        // Проверка корректности кода города/оператора
        if (
            !MOBILE_CODES.includes(Number(phone.join('').substr(operatorIndex, 3)))
            && [ 3, 4, 5, 6, 8, 9 ].indexOf(Number(phone[operatorIndex])) === -1
        ) {
            // Говорим что что-то польз. ввел не так
            return 'Код города/оператора должен начинаться с цифры 3, 4, 5, 6, 8, 9';
        }

        return true;
    }

    static email(value: string, isRequired?: boolean): string | boolean {
        if (value && value !== '') {
            if (!EMAIL_REGEX_I.test(value)) {
                return 'Пожалуйста, введите корректный адрес эл. почты';
            }
        } else if (isRequired) {
            return false;
        }

        return true;
    }

    static inn(val: string): boolean {
        if (!val) {
            return false;
        }
        if (![ 10, 12 ].includes(val.length)) {
            return false;
        }
        const innCsum = (inn: string) => {
            const k = [ 3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8 ];
            const pairs: any = zip(k.slice(Math.abs(11 - inn.length)), inn.split(''));
            return (sum(pairs.map((i: string[]) => parseInt(i[0], 10) * parseInt(i[1], 10))) % 11 % 10).toString();
        };
        if (val.length === 10) {
            return val.slice(-1) === innCsum(val.slice(0, -1));
        }
        return val.slice(-2) === innCsum(val.slice(0, -2)) + innCsum(val.slice(0, -1));
    }

    static snils(val: string): boolean {
        if (!val) {
            return false;
        }
        return /^[0-9]{3}-[0-9]{3}-[0-9]{3} [0-9]{2}$/.test(val);
    }

    private static _bankAccount(account: string): boolean {
        let checksum = 0;

        const coefficients = [ 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1 ];

        for (let i = 0; i <= 22; i++) {
            checksum += coefficients[i] * (Number(account[i]) % 10);
        }
        return checksum % 10 === 0;
    }

    static bankAccount(account: string, bik: string): boolean {
        if (!account ||!bik) {
            return false;
        }
        return this._bankAccount(bik.substring(6, 9) + account);
    }

    static bankCorAccount(account: string, bik: string): boolean {
        if (!account ||!bik) {
            return false;
        }
        return this._bankAccount('0'+ bik.substring(4, 6) + account);
    }
}
