import React, { useRef, useEffect, RefObject, ReactNode } from "react";
import { refGetter } from 'finbox-ui-kit/utils';


function useOutsideClickHandler(ref: RefObject<any>, callback: () => void) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ callback, ref ]);
}


type Props = {
    className?: string
    onOutsideClick: () => void
    children: ReactNode
    style?: React.CSSProperties
}
export const OutsideClickHandler = React.forwardRef<HTMLElement, Props>(function OutsideClickWrapper({
    className,
    children,
    onOutsideClick,
    style,
}, ref) {
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideClickHandler(wrapperRef, onOutsideClick);

    return (<div ref={ refGetter(wrapperRef, ref) } className={ className } style={ style }>{ children }</div>);
});

export default useOutsideClickHandler;
