import React from 'react';
import { UIButton, UIGrid } from 'finbox-ui-kit';
import { InputInitials } from '@/common/ui/input-initials';
import { makeInitialsError_LEGACY } from '@/components/leads/components/credit-from/validation';
import { Input } from '@/common/ui/input';


export const ChildrenForm = ({ index, fields, errors, onChange, onRemove }) => (
    <UIGrid stackable>
        <UIGrid.Row columns={15}>
            <UIGrid.Col colspan={10}>
                <InputInitials
                    label='ФИО'
                    name={`family.children.${ index }.initials`}
                    error={ makeInitialsError_LEGACY(`family.children.${ index }.initials`, errors) }
                    value={fields.initials || {}}
                    onChange={onChange}
                    required
                />
            </UIGrid.Col>
            <UIGrid.Col colspan={4}>
                <Input
                    type='text'
                    label='Дата рождения'
                    name={`family.children.${ index }.birthday`}
                    error={errors[`family.children.${ index }.birthday`]}
                    mask='99.99.9999'
                    value={fields.birthday}
                    onChange={onChange}
                    placeholder='дд.мм.гггг'
                    autoComplete='off'
                    required
                />
            </UIGrid.Col>
            <UIGrid.Col colspan={1}>
                <UIButton
                    className='mt-5'
                    onClick={onRemove}
                    icon='xmark'
                    color='red'
                />
            </UIGrid.Col>
        </UIGrid.Row>
    </UIGrid>
);
