import React from 'react';

export const useQueryString = () => {
    const [ params, setParams ] = React.useState<Record<string, any>>({});

    React.useEffect(() => {

        const params = new URLSearchParams(window.location.search);
        // @ts-ignore
        setParams(Object.fromEntries(params.entries()))
    }, []);

    return params;
}