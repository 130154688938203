import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';

import {
    IUIOverlayModalComponent,
    TUIOverlayModalBody, TUIOverlayModalFooter,
    TUIOverlayModalHeader, TUIOverlayModalHeaderButtonProps,
    TUIOverlayModalPropsWithChildren,
} from './overlay-modal';

import './style.scss';
import { UIIcon } from '../icon';


const UIOverlayModal: React.FC<TUIOverlayModalPropsWithChildren> & IUIOverlayModalComponent = ({
    open,
    onClose,
    children,
    topOffset = false,
    closeButton = true,
    isUpperWindow,
    index = 0,
    size,
}) => {
    const [ showed, setShowed ] = useState(false);
    const [ visible, setVisible ] = useState(false);

    useEffect(() => {
        if (open) {
            setShowed(true);
            setTimeout(() => setVisible(true), 50);
        } else {
            setVisible(false);
            setTimeout(() => setShowed(false), 400);
        }
    }, [ open ]);

    const handlerClose = useCallback(() => {
        onClose();
    }, [ onClose ]);

    if (!showed) {
        return null;
    }

    return ReactDOM.createPortal((
        <div
            className={cn('ui-overlay-modal', {
                '-open': visible,
                '-top-offset': topOffset,
                '-transparent-overlay': isUpperWindow,
                [`-${ size }`]: size,
            })}
            style={{ zIndex: 9900 + index }}
        >
            <div className='ui-overlay-modal-overlay' onClick={ handlerClose }/>
            <div className='ui-overlay-modal-content'>
                {closeButton && (
                    <div className='ui-overlay-modal-close' onClick={ handlerClose }>
                        <UIIcon
                            name='xmark'
                            size='big'
                        />
                    </div>
                )}
                <div className='ui-overlay-modal-content-overflow'>
                    { children }
                </div>
            </div>
        </div>
    ), document.getElementById('overlay-modal-portal'));
};

const UIOverlayModalHeader: TUIOverlayModalHeader = ({ children, buttons, fixed }) => (
    <div className={cn('ui-overlay-modal-header', { '-fixed': fixed })}>
        <div className='ui-overlay-modal-header-title'>
            { children }
        </div>
        {buttons && (
            <div className='ui-overlay-modal-header-buttons'>
                {buttons.map((btn, index) => {
                    if (React.isValidElement(btn)) {
                        return btn;
                    }
                    return (
                        <button
                            key={index}
                            type='button'
                            role='button'
                            onClick={(btn as TUIOverlayModalHeaderButtonProps).onClick}
                            disabled={(btn as TUIOverlayModalHeaderButtonProps).disabled}
                            title={(btn as TUIOverlayModalHeaderButtonProps).title}
                        >
                            <UIIcon
                                name={(btn as TUIOverlayModalHeaderButtonProps).icon}
                                size='small'
                                color='white'
                            />
                        </button>
                    )
                })}
            </div>
        )}
    </div>
);

const UIOverlayModalBody: TUIOverlayModalBody = ({ children, className }) => (
    <div className={ cn('ui-overlay-modal-body', className) }>
        { children }
    </div>
);

const UIOverlayModalFooter: TUIOverlayModalFooter = ({ children, fixed }) => (
    <div className={cn('ui-overlay-modal-footer', { '-fixed': fixed })}>
        { children }
    </div>
);

UIOverlayModal.Header = UIOverlayModalHeader;
UIOverlayModal.Body = UIOverlayModalBody;
UIOverlayModal.Footer = UIOverlayModalFooter;

export {
    UIOverlayModal,
    UIOverlayModal as default,
};
