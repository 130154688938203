import { useEffect, useRef } from 'react';

export function useUpdate(callback: any, dependencies: any[]) {
    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        callback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}