import OriginJoi, {
    Schema,
    Root,
    StringSchema,
    ArraySchema,
    Extension,
    ExtensionFactory,
    // Reference,
    AnySchema,
} from 'joi';
import { DateTime } from 'luxon';
import { joiResolver } from '@hookform/resolvers/joi';
import { Validator } from 'finbox-ui-kit/libs/validator';
import { isDefined } from 'finbox-ui-kit/utils';
import { joiRuLocale } from '@/libs/joi/joi-ru-locale';


interface RootFix extends Root {
    extend(...extensions: Array<Extension | ExtensionFactory>): Root;
}


interface LuxonSchema<TSchema = DateTime> extends AnySchema<TSchema> {
    // greater(date: 'now' | DateTime | Reference): this;
    // less(date: 'now' | DateTime | Reference): this;
    // min(date: 'now' | DateTime | Reference): this;
    // max(date: 'now' | DateTime | Reference): this;
}


interface ExtendedJoi extends Root {
    phone(): StringSchema;
    luxon(): LuxonSchema;
    inn(): StringSchema;
    bankAccount(): StringSchema;
    bankCorAccount(): StringSchema;
    range(): ArraySchema;
}


export const Joi: ExtendedJoi = (OriginJoi as RootFix)
    .extend((joi) => ({
        type: 'phone',
        base: joi.string(),

        coerce(value) {
            return { value };
        },
        validate(value, helpers) {
            const isValid = Validator.phone(value);
            if (isValid !== true) {
                const err = helpers.error('phone.format');
                err.message = isValid as string;
                return { value, errors: err };
            }
            return { value };
        },
    }))
    .extend((joi) => ({
        type: 'luxon',
        base: joi.any(),
        messages: {
            'luxon.format': `некорректная дата`,
            'luxon.min': `дата должна быть больше {{#date}}`,
        },
        coerce(value: DateTime) {
            return { value };
        },
        validate(value: DateTime, helpers) {
            if (!value) {
                return { errors: helpers.error('any.required') };
            }
            if (!value.isValid) {
                return { errors: helpers.error('luxon.format') };
            }
            return { value };
        },
    })).extend((joi) => ({
        type: 'inn',
        base: joi.string(),
        messages: {
            'inn.format': 'некорректный формат',
        },
        coerce(value: string) {
            return { value: value?.trim() };
        },
        validate(value, helpers) {
            const isValid = Validator.inn(value);
            if (isValid !== true) {
                return { value, errors: helpers.error('inn.format') };
            }
            return { value };
        },
    })).extend((joi) => ({
        type: 'bankAccount',
        base: joi.string(),
        messages: {
            'bankAccount.format': 'некорректно указан номер счета',
        },
        coerce(value: string) {
            return { value: value?.trim() };
        },
        validate(value, helpers) {
            if (!helpers.state.ancestors[0]?.bik) {
                return { value };
            }
            const isValid = Validator.bankAccount(value, helpers.state.ancestors[0]?.bik);
            if (isValid !== true) {
                return { value, errors: helpers.error('bankAccount.format') };
            }
            return { value };
        },
    })).extend((joi) => ({
        type: 'bankCorAccount',
        base: joi.string(),
        messages: {
            'bankAccount.format': 'некорректно указан номер кор. счета',
        },
        coerce(value: string) {
            return { value: value?.trim() };
        },
        validate(value, helpers) {
            if (!helpers.state.ancestors[0]?.bik) {
                return { value };
            }
            const isValid = Validator.bankCorAccount(value, helpers.state.ancestors[0]?.bik);
            if (isValid !== true) {
                return { value, errors: helpers.error('bankAccount.format') };
            }
            return { value };
        },
    })).extend((joi) => ({
        type: 'range',
        base: joi.array(),
        messages: {
            'range.minLessThenMax': 'первое значение должно быть меньше или равно второму',
            'range.maxRequired': 'необходимо указать максимальное значение',
            'range.minRequired': 'необходимо указать минимальное значение',
        },
        validate(value: any[], helpers) {
            if (isDefined(value[0]) && isDefined(value[1])) {
                if (value[0] > value[1]) {
                    return { value, errors: helpers.error('range.minLessThenMax') };
                }
            }
            if (isDefined(value[0]) && !isDefined(value[1])) {
                return { value, errors: helpers.error('range.maxRequired') };
            }
            if (!isDefined(value[0]) && isDefined(value[1])) {
                return { value, errors: helpers.error('range.minRequired') };
            }
            return { value };
        },
    }));

export const joiSchema = (schema: (joi: ExtendedJoi) => Schema) => joiResolver(schema(Joi), {
    messages: joiRuLocale,
    abortEarly: false,
});