import React, { useCallback, useEffect, useState } from 'react';

import { UIBlock } from 'finbox-ui-kit';
import { formatPrice } from '@/utils';
import { API_URLS, EActiveType } from '@/consts';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { Dropdown } from '@/common/ui/dropdown';
import { Grid } from '@/common/ui/grid';
import { Input } from '@/common/ui/input';
import { TOption } from '@/common/ui/options-list';

export const CarActiveFrom = ({ fields, onRemove, onChange }) => {
    const [ brandSuggestions, setBrandSuggestions ] = useState([]);
    const [ modelSuggestions, setModelSuggestions ] = useState([]);
    const [ generationSuggestions, setGenerationSuggestions ] = useState([]);
    const [ bodySuggestions, setBodySuggestions ] = useState([]);
    const [ modificationSuggestions, setModificationSuggestions ] = useState([]);

    const { fetch: fetchModels } = useApiClient({
        url: API_URLS.DICTIONARIES.CARS,
    });

    useEffect(() => {
        fetchModels().then((response) => {
            setBrandSuggestions(response.data.map((i) => ({
                text: i.brand,
                value: i.brand,
                data: i,
            })));
        });
        if (fields.brand) {
            fetchModels({
                brand: fields.brand,
            }).then((request) => {
                setModelSuggestions(request.data.map((i) => ({
                    text: i.model,
                    value: i.model,
                })));
            });
        }

        if (fields.model) {
            fetchModels({
                brand: fields.brand,
                model: fields.model,
            }).then((request) => {
                setGenerationSuggestions(request.data.map((i) => ({
                    text: `${ i.generation || '' } (${ i.yearFrom }-${ i.yearTo })`,
                    value: i.generation || 'none',
                })));
            });
        }

        if (fields.generation) {
            fetchModels({
                brand: fields.brand,
                model: fields.model,
                generation: fields.generation,
            }).then((request) => {
                setBodySuggestions(request.data.map((i) => ({
                    text: i.body,
                    value: i.body,
                })));
            });
        }

        if (fields.body) {
            fetchModels({
                brand: fields.brand,
                model: fields.model,
                generation: fields.generation,
                body: fields.body,
            }).then((request) => {
                setModificationSuggestions(request.data.map((i) => ({
                    text: i.modification.replace('AT', 'AT (автомат)').replace('MT', 'MT (механика)'),
                    value: i.modification,
                })));
            });
        }
    }, [ fetchModels, fields ]);

    const handlerBrandSearchChange = React.useCallback((option: TOption) =>
        !option.value
        || option.data.data.brand.toLowerCase().startsWith(option.value.toLowerCase())
        || option.data.data.brandCyrillic.toLowerCase().startsWith(option.value.toLowerCase()),
    [],
    );


    const handlerLocalChange = useCallback(async (e, { name, value, checked }) => {
        if (name === 'actives.car.brand') {
            onChange(e, { name: 'actives.car.model', value: null });
            onChange(e, { name: 'actives.car.generation', value: null });
            onChange(e, { name: 'actives.car.modification', value: null });
            onChange(e, { name: 'actives.car.body', value: null });

            if (value) {
                const request = await fetchModels({
                    brand: value,
                });
                setModelSuggestions(request.data.map((i) => ({
                    text: i.model,
                    value: i.model,
                })));
            }
        }

        if (name === 'actives.car.model') {
            onChange(e, { name: 'actives.car.generation', value: null });
            onChange(e, { name: 'actives.car.body', value: null });
            const validModel = modelSuggestions.some((i) => i.value === value);
            if (validModel) {
                const request = await fetchModels({
                    brand: fields.brand,
                    model: value,
                });
                setGenerationSuggestions(request.data.map((i) => ({
                    text: `${ i.generation || '' } (${ i.yearFrom }-${ i.yearTo })`,
                    value: i.generation || 'none',
                })));
            }
        }

        if (name === 'actives.car.generation') {
            onChange(e, { name: 'actives.car.body', value: null });
            onChange(e, { name: 'actives.car.modification', value: null });
            const validModel = generationSuggestions.some((i) => i.value === value);
            if (validModel) {
                const request = await fetchModels({
                    brand: fields.brand,
                    model: fields.model,
                    generation: value,
                });
                setBodySuggestions(request.data.map((i) => ({
                    text: i.body,
                    value: i.body,
                })));
            }
        }

        if (name === 'actives.car.body') {
            onChange(e, { name: 'actives.car.modification', value: null });

            const request = await fetchModels({
                brand: fields.brand,
                model: fields.model,
                generation: fields.generation,
                body: value,
            });
            setModificationSuggestions(request.data.map((i) => ({
                text: i.modification.replace('AT', 'AT (автомат)').replace('MT', 'MT (механика)'),
                value: i.modification,
            })));
        }

        onChange(e, {
            name,
            value: checked === undefined ? value : checked,
        });
    }, [ fetchModels, fields, generationSuggestions, onChange, modelSuggestions ]);

    return (
        <UIBlock
            title='Автомобиль'
            buttons={ [ { icon: 'xmark', onClick: onRemove } ] }
            className={ `active-${ EActiveType.CAR }` }
        >
            <Grid className='--no-margin-inputs'>
                <Grid.Row columns={ 3 }>
                    <Grid.Col>
                        <Dropdown
                            label='Марка'
                            name='actives.car.brand'
                            value={ fields.brand }
                            options={ brandSuggestions }
                            onChange={ handlerLocalChange }
                            filtering
                            filterFn={ handlerBrandSearchChange }
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Dropdown
                            label='Модель'
                            name='actives.car.model'
                            value={ fields.model }
                            options={ modelSuggestions }
                            onChange={ handlerLocalChange }
                            disabled={ !fields.brand }
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Dropdown
                            label='Поколение'
                            name='actives.car.generation'
                            value={ fields.generation }
                            options={ generationSuggestions }
                            onChange={ handlerLocalChange }
                            disabled={ !fields.model }
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Dropdown
                            label='Кузов'
                            name='actives.car.body'
                            value={ fields.body }
                            options={ bodySuggestions }
                            onChange={ handlerLocalChange }
                            disabled={ fields.generation === null }
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Dropdown
                            label='Модификация'
                            name='actives.car.modification'
                            value={ fields.modification }
                            options={ modificationSuggestions }
                            onChange={ handlerLocalChange }
                            disabled={ !fields.body }
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Input
                            type='text'
                            label='Год выпуска'
                            name='actives.car.year'
                            mask='9999'
                            value={ fields.year || '' }
                            onChange={ handlerLocalChange }
                            placeholder='гггг'
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Input
                            type='number'
                            label='Пробег'
                            name='actives.car.mileage'
                            value={ fields.mileage || '' }
                            onChange={ handlerLocalChange }
                            postfix='км'
                            renderValue={ (value) => formatPrice(value, false) }
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Input
                            type='number'
                            label='Доход от сдачи в аренду руб/мес.'
                            name='actives.car.rentPrice'
                            value={ fields.rentPrice }
                            onChange={ handlerLocalChange }
                            postfix='руб.'
                            renderValue={ (value) => formatPrice(value, false) }
                        />
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </UIBlock>
    );
};
