import axios from "axios";
import { API_BASE_URL_V2, VERSION } from '@/consts';
import { getFingerprint } from '@/utils/fingerprint';
import { AuthFailedException } from '@/libs/api-client/auth-failed.exception';
import { HttpClientException } from '@/libs/api-client/exception';
import { NeedUpgradeException } from '@/libs/api-client/need-upgrade.exception';
import { getJwtToken, memoizedRefreshToken } from "../jwt-token";

let fingerprint: string;
let authFailedFired = false;

const axiosPrivate = axios.create({
    baseURL: API_BASE_URL_V2,
    headers: {
        'Content-Type': 'application/json',
        'X-version': VERSION,
    },
});

axiosPrivate.interceptors.request.use(
    async (config) => {
        const token = getJwtToken();
        if (token) {
            fingerprint ??= await getFingerprint();
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${ token }`,
                'X-sec': fingerprint,
            } as any;
        }

        return config;
    },
    (error) => Promise.reject(error),
);

axiosPrivate.interceptors.response.use(
    (response) => {
        authFailedFired = false;
        return response;
    },
    async (error) => {
        if (error?.response?.status === 418) {
            return Promise.reject(new NeedUpgradeException())
        }
        const config = error?.config;
        if (error?.response?.status === 401) {
            if (!config?.sent) {
                config.sent = true;
                const accessToken = await memoizedRefreshToken();
                if (accessToken) {
                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${ accessToken }`,
                        'X-sec': fingerprint,
                    };
                }
                return axiosPrivate(config);
            }
            if (!config?.retry) {
                config.retry = true;

                await new Promise((resolve) => {
                    setTimeout(resolve, 2000);
                });

                const accessToken = await memoizedRefreshToken();
                if (accessToken) {
                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${ accessToken }`,
                        'X-sec': fingerprint,
                    };
                }
                return axiosPrivate(config);
            }

            if (!authFailedFired) {
                authFailedFired = true;
                window.dispatchEvent(new CustomEvent('auth_failed'));
                return Promise.reject(new AuthFailedException());
            }

            return null;
        }
        if (error.response?.data) {
            const data = error.response.data;
            if (!data || typeof data !== 'object') {
                return Promise.reject(new HttpClientException(
                    'Ошибка запроса',
                    -10000,
                ));
            }
            if ('errorCode' in data) {
                return Promise.reject(new HttpClientException(
                    data.errorMessage + (data.data && typeof data.data === 'string' ? `: ${ data.data }` : ''),
                    data.errorCode,
                ));
            }
            if ('error' in data) {
                return Promise.reject(new HttpClientException(
                    data.error.message,
                    data.error.code,
                ));
            }
        }
        return Promise.reject(error);
    },
);

export { axiosPrivate };