import React, { useCallback, useEffect, useState } from 'react';
import { replaceUrlPatterns } from '@/utils';
import { ICreditorOrder } from '@/types/creaditors';
import { API_CLIENT_ORDERS } from '@/components/leads/_consts';
import { useApiClient } from '@/libs/api-client/use-api-client';

interface IClientOrdersContext {
    loading: boolean;
    orders: ICreditorOrder[];
    fetchOrders: (clientId: number) => Promise<any>;
}

const ClientOrdersContext = React.createContext<IClientOrdersContext>({
    loading: false,
    orders: [],
    fetchOrders: () => null,
});

export const useClientOrdersContext = () => React.useContext(ClientOrdersContext);


export const ClientOrdersContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [ orders, setOrders ] = useState([]);
    const { data, loading: isLoading, fetch } = useApiClient<{ data: ICreditorOrder[], scope: string }>({
        url: API_CLIENT_ORDERS,
    });

    useEffect(() => {
        if (data) {
            setOrders(data.data);
        }
    }, [ data ]);

    const fetchOrders = useCallback(async (clientId: number) => {
        const { data } = await fetch(null, {
            url: replaceUrlPatterns(API_CLIENT_ORDERS, { id: clientId }),
        });
        if (data) {
            setOrders(data);
        }
    }, [ fetch ]);

    return (
        <ClientOrdersContext.Provider value={{
            loading: isLoading,
            orders,
            fetchOrders,
        }}>
            { children }
        </ClientOrdersContext.Provider>
    )
}
