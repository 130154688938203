import { Controller, FieldValues } from 'react-hook-form'
import React, { ReactElement } from 'react';
import { InputControlledProps } from './input.types';
import { Input } from './input';


export function InputControlled<TFieldValues extends FieldValues = FieldValues>({
    control,
    ...props
}: InputControlledProps<TFieldValues>): ReactElement {
    return (
        <Controller
            control={ control }
            name={ props.name }
            render={ ({ field }) => (
                <Input
                    { ...field }
                    { ...props }
                />
            ) }
        />
    )
}