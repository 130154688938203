import React from 'react';
import { UIBlock } from 'finbox-ui-kit';
import { UIIcon } from 'finbox-ui-kit/components/icon';
import { categoriesNames, EErrorsCategories } from '../_consts';
import { _errorsParser, parseJoiErrors } from '../helpers';
import './program-selection-errors.style.scss';

type TProgramSelectionErrorsProps = {
    errors: any[]
    onClickEdit: (tab: string) => void
    text?: string
    joiErrors?: boolean
}
export const ProgramSelectionErrors: React.FC<TProgramSelectionErrorsProps> = ({
    errors = [],
    onClickEdit,
    text = 'Для отправки заявки необходимо исправить следующие ошибки',
    joiErrors,
}) => {
    const errorsParser = joiErrors ? parseJoiErrors : _errorsParser;
    const parsedErrors = errorsParser(errors);

    const handlerClickEdit = (category: EErrorsCategories) => () => {
        onClickEdit(category);
    };

    const editCategoriesMap = new Map<EErrorsCategories, any>([
        [ EErrorsCategories.CLIENT, handlerClickEdit(EErrorsCategories.CLIENT) ],
        [ EErrorsCategories.PLEDGE_OBJECTS, handlerClickEdit(EErrorsCategories.PLEDGE_OBJECTS) ],
        [ EErrorsCategories.OTHER, handlerClickEdit(EErrorsCategories.OTHER) ],
        [ EErrorsCategories.PERSONAL, handlerClickEdit(EErrorsCategories.PERSONAL) ],
    ]);

    return (
        <div className='program-selection-errors'>
            <div className='program-selection-errors-warning'>
                <UIIcon
                    className='mr1'
                    name='triangle-exclamation'
                    size='huge'
                    color='red'
                    type='solid'
                />
                <b>{ text }</b>
            </div>
            <UIBlock.Group>
                {
                    Array.from(categoriesNames)
                        .filter(([ category ]) => parsedErrors[category])
                        .map(([ category, name ]) => (
                            <UIBlock
                                key={ category }
                                title={ name }
                                buttons={ [
                                    {
                                        icon: 'pencil',
                                        onClick: () => editCategoriesMap.get(category)(),
                                        // disabled: loading,
                                    },
                                ] }
                            >
                                <ul className='program-selection-errors-list'>
                                    { parsedErrors[category].map((error: any, index: any) => (
                                        <li key={ index }>
                                            { error.name !== '' && (
                                                <b>{ error.name || error.field }: </b>
                                            ) }
                                            { error.message }
                                        </li>
                                    )) }
                                </ul>
                            </UIBlock>
                        ))
                }
            </UIBlock.Group>
        </div>
    );
};