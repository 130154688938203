import { TDatesPresetItem } from 'finbox-ui-kit/components/dates-presets/dates-presets';

export const DEFAULT_DATES_PRESETS_TIME: TDatesPresetItem[] = [
    [ '+15 м', 15 ],
    [ '+30 м', 30 ],
    [ '+45 м', 45 ],
    [ '+1 ч', 60 ],
    [ '+2 ч', 120 ],
    [ '+4 ч', 240 ],
    [ '+6 ч', 360 ],
    [ '+8 ч', 480 ],
];

export const DEFAULT_DATES_PRESETS_HOURS: TDatesPresetItem[] = [
    [ '9:00', 9 ],
    [ '10:00', 10 ],
    [ '11:00', 11 ],
    [ '12:00', 12 ],
    [ '13:30', 13 ],
    [ '15:00', 15 ],
    [ '17:00', 17 ],
    [ '18:00', 18 ],
];

export const DEFAULT_DATES_PRESETS_DAYS: TDatesPresetItem[] = [
    [ '+1 д', 1 ],
    [ '+2 д', 2 ],
    [ '+3 д', 3 ],
    [ '+4 д', 4 ],
    [ '+6 д', 6 ],
    [ '+8 д', 8 ],
    [ '+11 д', 11 ],
    [ '+15 д', 15 ],
];

