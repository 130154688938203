import React, { MouseEventHandler } from 'react';
import cn from 'classnames';
import styles from './list.module.scss';

type ListProps = {
    children: React.ReactNode;
    className?: string;
};

export function List({ children, className }: ListProps) {
    return (
        <div className={cn(styles.list, className)}>
            { children }
        </div>
    );
}

type ListItemProps = {
    children: React.ReactNode;
    className?: string;
    active?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
};
List.Item = function ListItem({ children, className, onClick, active }: ListItemProps) {
    return (
        <div className={cn(styles.listItem, className, {
            [styles.listItemActive]: active,
        })} onClick={onClick}>
            { children }
        </div>
    );
}